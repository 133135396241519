import {ECompanyTypes, TCompanyActionTypes, ICompanyState} from 'models';

const initialState: ICompanyState = {
    data: undefined,
};

const companyReducer = (
    state = initialState,
    action: TCompanyActionTypes,
): ICompanyState => {
    switch (action.type) {
        case ECompanyTypes.SET_COMPANY: {
            return {
                ...state,
                data: action.payload,
            };
        }
        case ECompanyTypes.SET_COMPANY_ADDRESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    id: state.data?.id as number,
                    logo: state.data?.logo as string | null,
                    name: state.data?.name as string,
                    updated_at: state.data?.updated_at as Date | null,
                    address: {...action.payload},
                },
            };
        }

        default:
            return state;
    }
};

export default companyReducer;
