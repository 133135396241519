import { EPaymentsTypes, IPaymentsState, TPaymentsActionTypes, ISubscription } from 'models';

const initialState: IPaymentsState = {
    data: {
        subscription: undefined
    },
};

/**
 * @param {IPaymentsState} [state=initialState]
 * @param {TPaymentsActionTypes} action
 * @return {*}  {IPaymentsState}
 */
const paymentsReducer = (
    state: IPaymentsState = initialState,
    action: TPaymentsActionTypes,
): IPaymentsState => {
    switch (action.type) {
        case EPaymentsTypes.SET_SUBSCRIPTION_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    subscription: action.payload
                },
            };
        }

        case EPaymentsTypes.SET_DEFAULT_PAYMENT_METHOD: {
            return {
                ...state,
                data: {
                    ...state.data,
                    subscription: {
                        ...state.data.subscription,
                        default_payment_method: action.payload
                    } as ISubscription
                },
            };
        }

        default:
            return state;
    }
};

export default paymentsReducer;
