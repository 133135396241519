import {
    ISetCallInitialValuesAction,
    ISetReceivedResolutionAction,
    ISetSendedResolutionAction,
    IResetCallValues,
    ISetCallMicrophoneEnabledAction,
    ISetCallCameraEnabledAction,
    ISetCallIsAboutToEndAction,
    ISetCallBeforeSessionStartsAction,
    ISetOpenHelpPopoverAction,
    ISetCallVideoBackgroundAction,
    ISetPublicRoomsAction,
    ISetWSPublicRoomsAction,
    ISetCallNoiseSuppressionAction,
    ISetCallVideoDeviceId,
    ISetCallAudioDeviceId
} from '.';

export enum ECallTypes {
    SET_JOIN_ROOM_INITIAL_VALUES = 'SET/JOIN/ROOM/INITIAL/VALUES/ACTION',
    SET_CALL_RECEIVED_RESOLUTION = 'SET/CALL/RECEIVED/RESOLUTION/ACTION',
    SET_CALL_SENDED_RESOLUTION = 'SET/CALL/SENDED/RESOLUTION/ACTION',
    RESET_CALL_VALUES = 'RESET/CALL/VALUES/ACTION',
    SET_CALL_MICROPHONE_ENABLED = 'SET/CALL/MICROPHONE/ENABLED/ACTION',
    SET_CALL_CAMERA_ENABLED = 'SET/CALL/CAMERA/ENABLED/ACTION',
    SET_CALL_IS_ABOUT_TO_END_ACTION = 'SET/CALL/IS/ABOUT/TO/END/ACTION',
    SET_BEFORE_SESSION_STARTS_ACTION = 'SET/BEFORE/SESSION/STARTS/ACTION',
    SET_OPEN_HELP_POPOVER_ACTION = 'SET/OPEN/HELP/POPOVER/ACTION',
    SET_CALL_VIDEO_BACKGROUND_ACTION = 'SET/CALL/VIDEO/BACKGROUND/ACTION',
    SET_PUBLIC_ROOMS = 'SET/PUBLIC/ROOMS',
    WS_SET_PUBLIC_ROOM = 'WS/SET/PUBLIC/ROOM',
    SET_CALL_NOISE_SUPPRESSION_ACTION = 'SET/CALL/NOISE/SUPPRESSION/ACTION',
    SET_CALL_VIDEO_DEVICE_ID_ACTION = 'SET/CALL/VIDEO/DEVICE_ID',
    SET_CALL_AUDIO_DEVICE_ID_ACTION = 'SET/CALL/AUDIO/DEVICE_ID'
}

export interface IPublicRooms {
    id: number;
    name: string;
    participants: {
        sid: string;
        identity: string;
        name: string;
        metadata: {
            user_id: number;
            profile_id: number;
            last_name: string;
            first_name: string;
            avatar_url: string;
        };
        joined_at: number;
        is_publisher: boolean;
    }[];
}

export interface ICall {
    token?: string;
    video?: {
        cameraEnabled: boolean;
        deviceId: MediaDeviceInfo['deviceId'];
        sendResolution: 'Auto' | '720' | '360';
        receivedResolution: 'Auto' | '720' | '360' | 'audio_only';
        background?: {
            type: 'virtual-background' | 'background-blur';
            value: string | number;
        };
    };
    audio?: {
        audioEnabled: boolean;
        deviceId: MediaDeviceInfo['deviceId'];
        noiseSuppression: boolean;
    };
    speaker?: {
        deviceId: MediaDeviceInfo['deviceId'];
    };
    callIsAboutToEnd?: boolean;
    beforeSessionStarts?: {
        show: boolean;
        sessionId: number;
    };
    helpPopoverOpen?: 'report' | 'review' | '';
    publicRooms?: IPublicRooms[] | []
}

export interface ICallState {
    data?: ICall;
}

export type TCallActionTypes =
    | ISetCallInitialValuesAction
    | ISetReceivedResolutionAction
    | ISetSendedResolutionAction
    | IResetCallValues
    | ISetCallMicrophoneEnabledAction
    | ISetCallCameraEnabledAction
    | ISetCallIsAboutToEndAction
    | ISetCallBeforeSessionStartsAction
    | ISetOpenHelpPopoverAction
    | ISetCallVideoBackgroundAction
    | ISetPublicRoomsAction
    | ISetWSPublicRoomsAction
    | ISetCallNoiseSuppressionAction
    | ISetCallVideoDeviceId
    | ISetCallAudioDeviceId;
