import {TStore} from 'models';

export const selectCalendarFocusSessions = (state: TStore) =>
    state?.focusSessions?.calendar;
export const selectFocusSessions = (state: TStore) =>
    state?.focusSessions?.sessions;
export const selectFocusSessionsPast = (state: TStore) =>
    state?.focusSessions?.pastSessions;
export const selectFocusSessionsStats = (state: TStore) =>
    state?.focusSessions?.stats;
export const selectFocusSessionsMyFocus = (state: TStore) =>
    state?.focusSessions?.focus;
export const selectFocusSessionsLeaderBoard = (state: TStore) =>
    state?.focusSessions?.leaderBoard;
export const selectFocusSessionsMeta = (state: TStore) =>
    state?.focusSessions?.sessions?.meta;
export const selectFocusSessionsPastMeta = (state: TStore) =>
    state?.focusSessions?.pastSessions?.meta;
export const selectEditingSession = (state: TStore) =>
    state?.focusSessions?.editingFocusSession;
export const selectFocusSession = (state: TStore) =>
    state?.focusSessions?.session;
export const selectIsFocusSessionModalOpen = (state: TStore) =>
    state?.focusSessions?.isFocusSessionModalOpen;
export const selectMyFocusSessionsByDate = (state: TStore) =>
    state?.focusSessions?.mySessionsByDate;
export const selectIsDeleteFocusSessionModalOpen = (state: TStore) =>
    state?.focusSessions?.isDeleteFocusSessionModalOpen;
export const selectMySessionsCurrentDate = (state: TStore) =>
    state?.focusSessions?.calendarCurrentDate?.mySessions;
export const selectFocusSessionReviewSessionsGuidelines = (state: TStore) =>
    state?.focusSessions?.isReviewSessionGuidelinesOpen;
export const selectFocusSessionRepeatConfig = (state: TStore) =>
    state?.focusSessions?.session?.repeat_config;
export const selectFocusSessionChooseUpdateRepeatType = (state: TStore) =>
    state?.focusSessions?.chooseRepeatSessionUpdateType;
export const selectFocusSessionsFavourites = (state: TStore) =>
    state?.focusSessions?.favouritesSessions;
export const selectIsCalendarFullscreen = (state: TStore) =>
    state?.focusSessions?.isCalendarFullscreen;
