import {useEffect, useState, useMemo} from 'react';
import {
    CircularProgress,
    Box,
    Typography,
    useTheme,
    Chip,
    TextField,
    Paper,
    PaperProps,
    Button,
    InputAdornment,
    Tooltip,
    IconButton,
} from '@mui/material';
import {Controller, SubmitHandler} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import * as yup from 'yup';
import Autocomplete, {
    createFilterOptions,
    AutocompleteChangeReason,
    AutocompleteChangeDetails,
} from '@mui/material/Autocomplete';
import * as Sentry from '@sentry/react';

import {ReusableModalWindow} from 'components';
import {AuthService} from 'services';
import {ReactComponent as Email} from 'assets/svgs/email.svg';
import {ReactComponent as Person} from 'assets/svgs/person.svg';
import {ReactComponent as X} from 'assets/svgs/x.svg';
import {IUserFavourites} from 'models';
import {useAppDispatch, showToastAction} from 'store';
import {GroupsService} from '../../../../services/groups';
import styles from './styles.module.scss';
import {useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import FormHelperText from '@mui/material/FormHelperText';

interface OptionType {
    label: string;
    value: string;
}

const CustomPaper = (props: PaperProps) => {
    const theme = useTheme();

    return (
        <Paper
            {...props}
            style={{
                background:
                    theme.palette.mode === 'dark' ? '#1b1e24' : '#FFFFFF',
                boxShadow: '4px 0px 38px 3px #00000021',
                fontFamily: 'Brandon Grotesque',
                fontSize: 17,
                fontWeight: 400,
            }}
        />
    );
};

interface IFormData {
    outsideUsers:
        | {
              email: string;
              name: string;
          }[];
    favourites:
        | {
              value: string;
              label: string;
          }[];
    customError: string;
}

export const InviteGroup = ({
    open = false,
    setOpen,
    groupId,
    getGroupInvites,
    tab,
}: {
    open: boolean;
    setOpen: () => void;
    groupId: number;
    getGroupInvites?: ({setData}: {setData: boolean}) => any;
    tab?: number;
}) => {
    const theme = useTheme();
    const [loading, setLoading] = useState<'AUTOCOMPLETE' | 'SUBMIT' | ''>('');
    const [favourites, setFavourites] = useState<IUserFavourites[] | []>([]);
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const filterOptions = createFilterOptions<OptionType>({
        matchFrom: 'start',
        stringify: (option: any) => option.label,
    });

    const schema = useMemo(
        () =>
            yup
                .object({
                    outsideUsers: yup.array().of(
                        yup
                            .object()
                            .shape({
                                email: yup
                                    .string()
                                    .email(t('login.emailInvalid'))
                                    .when('name', {
                                        is: (value: string) => !!value,
                                        then: yup
                                            .string()
                                            .required(
                                                t('login.emailIsRequired'),
                                            ),
                                        otherwise: yup.string().optional(),
                                    })
                                    .default(''),
                                name: yup.string().optional().default(''),
                            })
                            .default([
                                [
                                    {
                                        email: '',
                                        name: '',
                                    },
                                ],
                            ]),
                    ),
                    favourites: yup.array().of(
                        yup
                            .object()
                            .shape({
                                label: yup.string().optional().default(''),
                                value: yup.string().optional().default(''),
                            })
                            .default([]),
                    ),
                })
                .test(
                    'at-least-one-invite',
                    t('common.atLeastOneInviteIsRequired'),
                    function (value) {
                        const {outsideUsers = [], favourites = []} = value;

                        const parseOutsideUsers = outsideUsers
                            .filter(user => user.email.length)
                            .map(user => {
                                return {
                                    label: user.name,
                                    value: user.email,
                                };
                            });

                        const combinedData =
                            parseOutsideUsers.concat(favourites);

                        return (
                            combinedData.length > 0 ||
                            this.createError({
                                path: 'customError',
                                message: t('common.atLeastOneInviteIsRequired'),
                            })
                        );
                    },
                )
                .required(),
        [t],
    );

    const {
        handleSubmit,
        control,
        watch,
        formState: {errors},
        setValue,
        getValues,
    } = useForm<IFormData>({
        resolver: yupResolver(schema),
        defaultValues: {
            outsideUsers: [
                {
                    email: '',
                    name: '',
                },
            ],
            favourites: [],
            customError: '',
        },
    });

    const handleOnChange = (
        event: React.ChangeEvent<{}>,
        newValue: any,
        reason: AutocompleteChangeReason,
        details?: AutocompleteChangeDetails<OptionType>,
    ) => {
        if (
            reason === 'selectOption' ||
            reason === 'removeOption' ||
            reason === 'clear'
        ) {
            setValue('favourites', newValue);
        } else if (
            reason === 'createOption' &&
            typeof details?.option === 'string'
        ) {
            const newOption: OptionType = {
                label: details.option,
                value: details.option,
            };
            setValue('favourites', [...getValues('favourites'), newOption]);
        }
    };

    const onSubmit: SubmitHandler<IFormData> = async ({
        favourites,
        outsideUsers,
    }) => {
        try {
            if (!groupId) return;

            setLoading('SUBMIT');

            const inviteFunctions = [];
            const data = outsideUsers
                .filter(user => user?.email?.length > 0)
                .map(user => {
                    return {
                        label: user.name,
                        value: user.email,
                    };
                })
                .concat(favourites);

            for (const option of data) {
                inviteFunctions.push(() =>
                    GroupsService.postGroupInvites({
                        id: groupId.toString(),
                        admin: false,
                        name: option.label || undefined,
                        user_id: isNaN(parseInt(option.value, 10))
                            ? undefined
                            : parseInt(option.value, 10),
                        email: isNaN(parseInt(option.value, 10))
                            ? option.value
                            : undefined,
                    }),
                );
            }

            const invitesPromises = inviteFunctions.map(inviteFunction =>
                inviteFunction(),
            );

            await Promise.all(invitesPromises);

            if (getGroupInvites && tab === 1) {
                await getGroupInvites({setData: true});
            }
            setOpen();
            dispatch(
                showToastAction({
                    open: true,
                    horizontal: 'center',
                    subTitle: '',
                    title: t('groups.theGroupInvitesSentSuccessfully'),
                    vertical: 'top',
                }),
            );
        } catch (error) {
            console.error({error});
            dispatch(
                showToastAction({
                    horizontal: 'center',
                    vertical: 'top',
                    open: true,
                    title: t('common.anErrorHasOccurred'),
                    subTitle: t('common.pleaseTryAgain'),
                    icon: 'error',
                }),
            );
            Sentry.captureException(error);
        } finally {
            setLoading('');
        }
    };

    useEffect(() => {
        (async () => {
            try {
                setLoading('AUTOCOMPLETE');
                const {data} = await AuthService.getAuthUserFavorites();
                setFavourites(data);
            } catch (error) {
                console.error({error});
                Sentry.captureException(error);
                dispatch(
                    showToastAction({
                        horizontal: 'center',
                        vertical: 'top',
                        open: true,
                        title: t('common.anErrorHasOccurred'),
                        subTitle: t('common.pleaseTryAgain'),
                        icon: 'error',
                    }),
                );
            } finally {
                setLoading('');
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <ReusableModalWindow
            open={open}
            height={'auto'}
            width={700}
            setOpen={setOpen}
            alignItems="flex-start">
            <Box
                component={'form'}
                onSubmit={handleSubmit(onSubmit)}
                sx={{width: '100%'}}>
                <Typography
                    sx={{
                        color:
                            theme.palette.mode === 'light'
                                ? 'rgba(0, 0, 0, 0.85)'
                                : '#fff7ea',
                    }}
                    className={styles.title}>
                    {t('groups.inviteMember')}
                </Typography>
                <Typography className={styles.subtitle}>
                    {t(
                        'groups.inviteIntoYourGroupsThePersonYouWouldLikeToHaveHere',
                    )}
                </Typography>
                {watch('outsideUsers')?.map((user, index) => {
                    return (
                        <Box key={index}>
                            <Box className={styles.inviteMember}>
                                <Controller
                                    control={control}
                                    name={`outsideUsers.${index}.name`}
                                    render={({field}) => (
                                        <TextField
                                            disabled={loading === 'SUBMIT'}
                                            variant="standard"
                                            placeholder={
                                                t('common.name') as string
                                            }
                                            fullWidth
                                            {...field}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Person
                                                            fill="#CBCED6"
                                                            width={20}
                                                            height={20}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={
                                                errors?.outsideUsers &&
                                                !!errors?.outsideUsers[index]
                                                    ?.name?.message
                                            }
                                            helperText={
                                                errors?.outsideUsers &&
                                                errors?.outsideUsers[index]
                                                    ?.name?.message
                                            }
                                        />
                                    )}
                                />
                                <Controller
                                    control={control}
                                    name={`outsideUsers.${index}.email`}
                                    render={({field}) => (
                                        <TextField
                                            disabled={loading === 'SUBMIT'}
                                            variant="standard"
                                            placeholder={
                                                t('common.email') as string
                                            }
                                            fullWidth
                                            sx={{
                                                input: {
                                                    paddingRight: '15px',
                                                },
                                            }}
                                            {...field}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <Email
                                                            fill="#CBCED6"
                                                            width={20}
                                                            height={20}
                                                        />
                                                    </InputAdornment>
                                                ),
                                            }}
                                            error={
                                                errors?.outsideUsers &&
                                                !!errors?.outsideUsers[index]
                                                    ?.email?.message
                                            }
                                            helperText={
                                                errors?.outsideUsers &&
                                                errors?.outsideUsers[index]
                                                    ?.email?.message
                                            }
                                        />
                                    )}
                                />
                                {index > 0 && (
                                    <Tooltip
                                        placement="top"
                                        title={t('meetings.removeInvite') || ''}
                                        onClick={() => {
                                            const index = getValues(
                                                'outsideUsers',
                                            ).findIndex(
                                                item =>
                                                    item.email === user.email,
                                            );
                                            const newArray = [
                                                ...getValues('outsideUsers'),
                                            ];
                                            newArray.splice(index, 1);

                                            setValue('outsideUsers', newArray);
                                        }}>
                                        <IconButton
                                            disabled={loading === 'SUBMIT'}
                                            sx={{
                                                position: 'absolute',
                                                right: 20,
                                            }}>
                                            <X
                                                width={15}
                                                height={15}
                                                fill={
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? '#cbcbcb'
                                                        : 'rgba(0, 0, 0, 0.85)'
                                                }
                                                className={styles.x}
                                            />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </Box>
                        </Box>
                    );
                })}
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        my: 2,
                    }}>
                    <Button
                        onClick={() => {
                            if (loading === 'SUBMIT') return;

                            setValue(
                                'outsideUsers',
                                getValues('outsideUsers')?.length > 0
                                    ? getValues('outsideUsers').concat([
                                          {email: '', name: ''},
                                      ])
                                    : [{email: '', name: ''}],
                            );
                        }}
                        variant={'outlined'}
                        style={{}}
                        className={styles.inviteMore}>
                        {t('meetings.inviteMore')}
                    </Button>
                </Box>
                <Autocomplete
                    multiple
                    sx={{my: 2}}
                    options={
                        favourites?.map(favourite => {
                            return {
                                value: favourite.user.id?.toString() || '',
                                label: favourite.user.first_name || '',
                            };
                        }) || []
                    }
                    freeSolo={false}
                    filterOptions={filterOptions}
                    loading={loading === 'AUTOCOMPLETE'}
                    disabled={loading === 'SUBMIT'}
                    onChange={handleOnChange}
                    value={watch('favourites')}
                    getOptionLabel={option => option.label}
                    PaperComponent={CustomPaper}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                label={option.label}
                                sx={{
                                    borderColor:
                                        theme.palette.mode === 'light'
                                            ? '#FFFBF5'
                                            : '#FFEACA',
                                    backgroundColor:
                                        theme.palette.mode === 'light'
                                            ? '#FFFBF5'
                                            : '#FFEACA',
                                    color: 'primary.main',
                                    fontFamily: 'Brandon Grotesque',
                                    fontSize: 14,
                                    fontWeight: 400,
                                }}
                                {...getTagProps({index})}
                                deleteIcon={
                                    <svg
                                        width="10"
                                        height="10"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_184_1922)">
                                            <path
                                                d="M7.91683 7.32852L7.32933 7.91602L5.00016 5.58685L2.671 7.91602L2.0835 7.32852L4.41266 4.99935L2.0835 2.67018L2.671 2.08268L5.00016 4.41185L7.32933 2.08268L7.91683 2.67018L5.58766 4.99935L7.91683 7.32852Z"
                                                fill="black"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_184_1922">
                                                <rect
                                                    width="10"
                                                    height="10"
                                                    fill="white"
                                                    transform="matrix(1 0 0 -1 0 10)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                }
                            />
                        ));
                    }}
                    isOptionEqualToValue={(option, value) =>
                        option.value === value.value
                    }
                    renderInput={params => (
                        <TextField
                            {...params}
                            variant="standard"
                            placeholder={
                                t('groups.chooseExistingFavouriteUsers') || ''
                            }
                            InputProps={{
                                ...params.InputProps,
                                startAdornment: (
                                    <>
                                        <InputAdornment position="start">
                                            <Person
                                                fill="#CBCED6"
                                                width={20}
                                                height={20}
                                            />
                                        </InputAdornment>
                                        {params.InputProps.startAdornment}
                                    </>
                                ),
                                endAdornment: (
                                    <>
                                        {loading ? (
                                            <CircularProgress
                                                color="primary"
                                                size={20}
                                            />
                                        ) : null}
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            }}
                        />
                    )}
                />
                {errors?.['customError'] && (
                    <FormHelperText error className={styles.formError}>
                        {errors?.['customError']?.message}
                    </FormHelperText>
                )}
                <Box className={styles.actions}>
                    <Button
                        className={styles.save}
                        type="submit"
                        variant="contained"
                        disabled={loading === 'SUBMIT'}>
                        {loading === 'SUBMIT' ? (
                            <CircularProgress
                                size={15}
                                sx={{color: '#FFFFFF'}}
                            />
                        ) : (
                            t('common.invite')
                        )}
                    </Button>
                    <Button
                        onClick={() => setOpen()}
                        className={styles.cancel}
                        variant="outlined">
                        {t('common.cancel')}
                    </Button>
                </Box>
            </Box>
        </ReusableModalWindow>
    );
};
