import {EUserTypes, IUserData} from 'models';

export const postIgnoreUserSaga = (payload: {
    userId: number;
    callbackOnSuccess?: () => void;
    callbackOnError?: ({message}: {message: string}) => void;
}) => ({
    type: EUserTypes.POST_USERS_IGNORE_SAGA,
    payload,
});

export const deleteIgnoredUserSaga = (payload: {
    userId: number;
    callbackOnSuccess?: () => void;
    callbackOnError?: ({message}: {message: string}) => void;
}) => ({
    type: EUserTypes.DELETE_USER_IGNORE_SAGA,
    payload,
});

export const getUserByProfileUrlActionSaga = (payload: {
    profileUrl: string;
    callback?: () => void;
}) => ({
    type: EUserTypes.GET_USER_ACTION_SAGA,
    payload,
});

export const setUserAction = (payload: IUserData | undefined) => ({
    type: EUserTypes.USER_ACTION_SET,
    payload,
});
