import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {Provider, ReactReduxContext} from 'react-redux';
import {PersistGate} from 'redux-persist/lib/integration/react';
import {ConnectedRouter} from 'connected-react-router';
import {Integrations} from '@sentry/tracing';
import * as Sentry from '@sentry/react';
import {matchPath} from 'react-router-dom';
import {I18nextProvider} from 'react-i18next';

import {store, persistor} from './store';
import reportWebVitals from './reportWebVitals';
import history from './utils/createHistory';
import {companyRoutes, userRouses} from 'routes/routes';
import {ErrorBoundaries} from 'components';
import i18n from 'locales/i18n';

const LazyApp = lazy(() => import('./App'));

const integrations: any[] = [
    new Integrations.BrowserTracing({
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(
            history,
            // * Array of Route Config Objects
            // * Make sure the order of the routes is correct. The longest url under the same parent should be placed first and in decreasing order.
            userRouses()
                .concat(companyRoutes(true))
                // @ts-ignore
                ?.map((route: {path: string}) => ({
                    path: route.path,
                }))
                .sort(function (a: {path: string}, b: {path: string}) {
                    return b.path.length - a.path.length;
                }),
            matchPath,
        ),
    }),
];

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: integrations,
    environment: process.env.NODE_ENV,
    debug: false,
    enabled: process.env.REACT_APP_STAGE !== 'local',
    // * Set tracesSampleRate to 1.0 to capture 100%
    // * of transactions for performance monitoring.
    // * We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    normalizeDepth: 10,
    // * This sets the sample rate to be 10%. You may want this to be 100% while
    // * in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // * If the entire session is not sampled, use the below sample rate to sample
    // * sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
});

const myFallback = () => (
    <div
        style={{
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
        }}>
        <ErrorBoundaries />
    </div>
);

ReactDOM.render(
    <React.StrictMode>
        <I18nextProvider i18n={i18n}>
            <Provider store={store}>
                <PersistGate persistor={persistor}>
                    <ConnectedRouter
                        history={history}
                        context={ReactReduxContext}>
                        <Suspense fallback={null}>
                            <Sentry.ErrorBoundary
                                fallback={myFallback}
                                showDialog={false}>
                                <LazyApp />
                            </Sentry.ErrorBoundary>
                        </Suspense>
                    </ConnectedRouter>
                </PersistGate>
            </Provider>
        </I18nextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log as any);
