import { all, put, takeLatest } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import { EPaymentsTypes } from 'models';
import { PaymentsService } from 'services';
import { setSubscriptionAction, getSubscriptionAction } from 'store';


function* handleGetSubscription({
    payload,
}: ReturnType<typeof getSubscriptionAction>) {
    try {
        const { data: subscriptions } = yield PaymentsService.getSubscriptions({
            status: 'active',
        });
        if (subscriptions.length > 0) {
            const { data: subscription } = yield PaymentsService.getSubscription({
                subscriptionId: subscriptions[0].id,
            });

            if (subscription) {
                yield put(setSubscriptionAction(subscription))
                payload.callbackOnSuccess && payload.callbackOnSuccess(subscription.currency)
            }
        } else {
            payload.callbackOnSuccess && payload.callbackOnSuccess('gbp')
        }
    } catch (error: any) {
        console.log({ error });
        payload.callbackOnError && payload.callbackOnError();
        Sentry.captureException(error);
    }
}



export function* PaymentsSagas(): Generator<unknown, any, undefined> {
    return yield all([
        takeLatest(
            EPaymentsTypes.GET_SUBSCRIPTION_ACTION,
            handleGetSubscription,
        ),
    ]);
}
