import { TStore } from "models";

export const selectGiftAccounts = (state: TStore) =>
    state?.giftAccounts?.data;

export const selectGiftAccountsFromMe = (state: TStore) =>
    state?.giftAccounts?.data?.from_me;

export const selectGiftAccountsToMe = (state: TStore) =>
    state?.giftAccounts?.data?.to_me;
