import {SetStateAction, Dispatch, memo} from 'react';
import {Button, TextField, Typography, Box} from '@mui/material';
import {ReusableModalWindow} from 'components';

import styles from './styles.module.scss';
import {useReportLogic} from './localLogix/useReportLogic';

export const ReportUser = memo(
    ({
        open,
        setOpen,
        sessionPartner,
    }: {
        open: boolean;
        setOpen: Dispatch<SetStateAction<boolean>>;
        sessionPartner: any;
    }) => {
        const {reportBody, setReportBody, submitReport, t} = useReportLogic({
            setOpen,
            sessionPartner,
        });

        return (
            <>
                <ReusableModalWindow
                    open={open}
                    setOpen={setOpen}
                    disableEscapeKeyDown={true}
                    height={500}
                    alignItems="flex-start">
                    <Box sx={{position: 'relative', height: '100%'}}>
                        <Typography
                            className={styles.title}
                            variant="h1"
                            component="h2">
                            {t('afterSession.reportAndBlock')}{' '}
                            {sessionPartner && sessionPartner?.first_name}{' '}
                            {sessionPartner &&
                                sessionPartner?.last_name_initial}
                        </Typography>
                        <br />
                        <Typography
                            className={styles.subTitle}
                            variant="h1"
                            component="h2">
                            {t('afterSession.weHaveZeroTolerance')}
                        </Typography>
                        <br />
                        <TextField
                            id="report-subject"
                            label={t('afterSession.subject')}
                            inputProps={{className: styles.subjectInput}}
                            variant="standard"
                            value={reportBody.subject}
                            onChange={(event: any) =>
                                setReportBody(prev => ({
                                    ...prev,
                                    subject: event.target.value,
                                }))
                            }
                            sx={{width: '100%'}}
                        />
                        <br />
                        <br />
                        <TextField
                            id="report-behavior"
                            label={t('afterSession.pleaseDetailExperience')}
                            variant="standard"
                            multiline
                            rows={4}
                            maxRows={4}
                            value={reportBody.info}
                            onChange={(event: any) =>
                                setReportBody(prev => ({
                                    ...prev,
                                    info: event.target.value,
                                }))
                            }
                            inputProps={{className: styles.subjectInput}}
                            sx={{width: '100%'}}
                        />
                        <Box
                            sx={{
                                position: 'absolute',
                                width: '100%',
                                bottom: 0,
                            }}>
                            <Button
                                onClick={submitReport}
                                className={styles.reportButton}
                                variant={'contained'}>
                                {t('afterSession.report')}{' '}
                                {sessionPartner && sessionPartner?.first_name}{' '}
                                {sessionPartner &&
                                    sessionPartner?.last_name_initial}
                            </Button>
                        </Box>
                    </Box>
                </ReusableModalWindow>
            </>
        );
    },
);
