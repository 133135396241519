import {useMemo, useState, memo} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {useForm, SubmitHandler, Controller} from 'react-hook-form';
import * as Sentry from '@sentry/react';
import {
    InputAdornment,
    Typography,
    useTheme,
    Box,
    Button,
    TextField,
    CircularProgress,
} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {ReactComponent as Person} from 'assets/svgs/person.svg';
import {Images} from 'assets';
import {ReusableModalWindow} from 'components';
import {GiftAccountsService} from 'services';
import {IGiftAccount} from 'models';
import {setGiftAccountsAction, showToastAction, useAppDispatch} from 'store';

export const SendGiftAccountModal = memo(
    ({
        onClose,
        giftAccounts,
    }: {
        onClose: () => void;
        giftAccounts: IGiftAccount;
    }) => {
        const theme = useTheme();
        const [loading, setLoading] = useState<boolean>(false);
        const {t} = useTranslation();
        const dispatch = useAppDispatch();
        const schema = useMemo(
            () =>
                yup
                    .object({
                        email: yup
                            .string()
                            .required(t('login.emailIsRequired'))
                            .email(t('login.emailInvalid')),
                        fullName: yup.string().max(255).optional(),
                    })
                    .required(),
            [t],
        );
        const {
            handleSubmit,
            control,
            formState: {errors},
            reset,
        } = useForm<{
            email: string;
            fullName: string;
        }>({
            resolver: yupResolver(schema),
            defaultValues: {
                email: giftAccounts.from_me?.[0]?.gift_receiver_email || '',
                fullName: giftAccounts.from_me?.[0]?.gift_receiver_name || '',
            },
        });

        const onSubmit: SubmitHandler<{
            email: string;
            fullName: string;
        }> = async ({email, fullName}) => {
            try {
                setLoading(true);
                const {data} = await GiftAccountsService.postGiftAccounts({
                    email,
                    fullName,
                });
                dispatch(
                    setGiftAccountsAction({
                        giftAccounts: {
                            ...giftAccounts,
                            from_me: [...giftAccounts.from_me, data],
                        },
                        callbackOnError: () => {},
                        callbackOnSuccess: () => {},
                    }),
                );
                setLoading(false);
                onClose();
                reset();
                dispatch(
                    showToastAction({
                        title: t('giftAccounts.giftSentSuccessfully'),
                        subTitle: t(
                            'giftAccounts.weSentTheGiftAccountToTheRecipient',
                        ),
                        open: true,
                        horizontal: 'center',
                        vertical: 'top',
                    }),
                );
            } catch (error: any) {
                console.log({error});
                setLoading(false);
                Sentry.captureException(error);
                dispatch(
                    showToastAction({
                        title: t('giftAccounts.giftSentError'),
                        subTitle:
                            error?.response?.data?.gift_receiver_email?.[0] ||
                            '',
                        open: true,
                        horizontal: 'center',
                        vertical: 'top',
                        icon: 'error',
                    }),
                );
            }
        };

        return (
            <ReusableModalWindow
                open={true}
                height={'auto'}
                width={517}
                setOpen={() => {
                    onClose();
                }}
                alignItems="flex-start">
                <Box
                    component={'form'}
                    onSubmit={handleSubmit(onSubmit)}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                        gap: 2,
                    }}>
                    <Box component={'img'} src={Images.Gift} alt="gift" />
                    <Typography
                        sx={{
                            color:
                                theme.palette.mode === 'light'
                                    ? 'rgba(0, 0, 0, 0.85)'
                                    : '#fff7ea',
                            fontFamily: 'Proxima Nova',
                            fontWeight: 600,
                            fontSize: {xs: 22, md: 24},
                            textAlign: 'center',
                        }}>
                        {t('giftAccounts.thanksForSubscribing')}
                    </Typography>
                    <Box sx={{display: 'block', width: '100%'}}>
                        <Typography
                            sx={{
                                color: 'var(--theme-label-color)',
                                fontFamily: 'Brandon Grotesque',
                                fontWeight: 400,
                                fontSize: {xs: 16, md: 18},
                                lineHeight: {xs: '20px', md: '22px'},
                                textAlign: 'left',
                            }}>
                            {t('giftAccounts.youHaveEarnedAGift')}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            my: 2,
                            gap: 2,
                            flexDirection: 'column',
                        }}>
                        <Controller
                            control={control}
                            name={'email'}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    variant="standard"
                                    error={!!errors?.email?.message}
                                    helperText={errors?.email?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Box
                                                    component="img"
                                                    alt={'email'}
                                                    src={Images.email}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    placeholder={`${t('common.email')} *`}
                                    sx={{
                                        fontFamily: 'Brandon Grotesque',
                                        fontSize: '17px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                    }}
                                />
                            )}
                        />
                        <Controller
                            control={control}
                            name={'fullName'}
                            render={({field}) => (
                                <TextField
                                    {...field}
                                    variant="standard"
                                    error={!!errors?.fullName?.message}
                                    helperText={errors?.fullName?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <Person
                                                    fill="#CBCED6"
                                                    width={20}
                                                    height={20}
                                                />
                                            </InputAdornment>
                                        ),
                                    }}
                                    fullWidth
                                    placeholder={`${t('common.fullName')} *`}
                                    sx={{
                                        fontFamily: 'Brandon Grotesque',
                                        fontSize: '17px',
                                        fontWeight: 400,
                                        lineHeight: '24px',
                                        letterSpacing: '0em',
                                        textAlign: 'left',
                                    }}
                                />
                            )}
                        />
                    </Box>
                    <Button
                        variant={'contained'}
                        disabled={
                            Object.keys(errors)?.length > 0 ||
                            loading ||
                            giftAccounts?.from_me?.length > 0
                        }
                        type="submit"
                        sx={{
                            width: '100%',
                            boxShadow: 'unset',
                            height: {xs: '40px', md: '50px'},
                            fontFamily: 'Proxima Nova',
                            fontSize: {xs: '15px', md: '18px'},
                            fontWeight: 700,
                            lineHeight: '22px',
                            letterSpacing: '0em',
                            textAlign: 'center',
                            color: 'white',
                            borderRadius: 50,
                        }}>
                        {loading ? (
                            <CircularProgress size={15} />
                        ) : (
                            t('giftAccounts.sendGift')
                        )}
                    </Button>
                </Box>
            </ReusableModalWindow>
        );
    },
);
