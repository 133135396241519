import {AxiosResponse} from 'axios';
import tokenInstance from 'utils/axiosInstance/tokenInstance';

export const wsTicket = ({
    focus_session,
    peer_uuid,
}: {
    focus_session?: string;
    peer_uuid: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`ws/get-ticket`, {
        focus_session,
        peer_uuid,
    });
};

export const getWsRoomsToken = ({
    roomId,
    companyId,
}: {
    roomId: string;
    companyId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`companies/${companyId}/rooms/${roomId}/token`);
};

export const WSService = {
    wsTicket,
    getWsRoomsToken,
};
