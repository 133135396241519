import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`,
    };
}

export const CustomTabs = React.memo(
    ({
        tabs,
        value,
        handleChange,
        loading,
    }: {
        tabs: {label: string}[];
        value: number;
        handleChange: (event: React.SyntheticEvent, newValue: number) => void;
        loading: 'TAB' | 'PAGE' | '';
    }) => {
        return (
            <Box sx={{width: '100%'}}>
                <Box sx={{borderBottom: 2, borderColor: '#C6D0D6'}}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        aria-label="tabs">
                        {tabs.map((tab, index) => (
                            <Tab
                                disabled={loading.length > 0}
                                label={tab.label}
                                {...a11yProps(index)}
                                sx={{
                                    fontFamily: 'Proxima Nova',
                                    fontStyle: 'normal',
                                    fontWeight: 600,
                                    fontSize: '16px',
                                    lineHeight: '21px',
                                    textTransform: 'capitalize',
                                }}
                            />
                        ))}
                    </Tabs>
                </Box>
            </Box>
        );
    },
);
