import { ECallTypes, ICallState, IPublicRooms, TCallActionTypes } from 'models';

const initialState: ICallState = {
    data: {
        audio: {
            audioEnabled: false,
            deviceId: '',
            noiseSuppression: true
        },
        beforeSessionStarts: undefined,
        callIsAboutToEnd: undefined,
        helpPopoverOpen: undefined,
        publicRooms: [],
        speaker: undefined,
        token: '',
        video: undefined
    },
};

const callReducer = (
    state = initialState,
    action: TCallActionTypes,
): ICallState => {
    switch (action.type) {
        case ECallTypes.SET_JOIN_ROOM_INITIAL_VALUES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    ...action.payload.initialConnectionValue,
                },
            };
        }

        case ECallTypes.SET_CALL_VIDEO_DEVICE_ID_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    video: {
                        ...state.data?.video,
                        cameraEnabled: !!state.data?.video?.cameraEnabled,
                        receivedResolution: state.data?.video?.receivedResolution || 'Auto',
                        sendResolution: state.data?.video?.sendResolution || 'Auto',
                        background: state.data?.video?.background,
                        deviceId: action.payload.deviceId
                    }
                }
            }
        }

        case ECallTypes.SET_CALL_AUDIO_DEVICE_ID_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    audio: {
                        deviceId: action.payload.deviceId,
                        audioEnabled: !!state?.data?.audio?.audioEnabled,
                        noiseSuppression: !!state?.data?.audio?.noiseSuppression
                    }
                }
            }
        }

        case ECallTypes.SET_CALL_RECEIVED_RESOLUTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    video: {
                        ...state.data?.video,
                        receivedResolution: action.payload,
                        cameraEnabled: !!state.data?.video?.cameraEnabled,
                        sendResolution: state.data?.video?.sendResolution || 'Auto',
                        background: state.data?.video?.background,
                        deviceId: state.data?.video?.deviceId || ''
                    },
                },
            };
        }

        case ECallTypes.SET_CALL_SENDED_RESOLUTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    video: {
                        ...state.data?.video,
                        sendResolution: action.payload,
                        receivedResolution: state?.data?.video?.receivedResolution || 'Auto',
                        cameraEnabled: !!state.data?.video?.cameraEnabled,
                        background: state.data?.video?.background,
                        deviceId: state.data?.video?.deviceId || ''
                    },
                },
            };
        }

        case ECallTypes.RESET_CALL_VALUES: {
            return {
                ...initialState,
            };
        }

        case ECallTypes.SET_CALL_CAMERA_ENABLED: {
            return {
                ...state,
                data: {
                    ...state.data,
                    video: {
                        ...state.data?.video,
                        cameraEnabled: action.payload,
                        receivedResolution: state?.data?.video?.receivedResolution || 'Auto',
                        background: state.data?.video?.background,
                        deviceId: state.data?.video?.deviceId || '',
                        sendResolution: state.data?.video?.sendResolution || 'Auto'
                    },
                },
            };
        }

        case ECallTypes.SET_CALL_MICROPHONE_ENABLED: {
            return {
                ...state,
                data: {
                    ...state.data,
                    audio: {
                        ...state.data?.audio,
                        audioEnabled: action.payload,
                        deviceId: state?.data?.audio?.deviceId || '',
                        noiseSuppression: !!state?.data?.audio?.noiseSuppression
                    },
                },
            };
        }

        case ECallTypes.SET_CALL_IS_ABOUT_TO_END_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    callIsAboutToEnd: action.payload,
                },
            };
        }

        case ECallTypes.SET_BEFORE_SESSION_STARTS_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    beforeSessionStarts: action.payload,
                },
            };
        }

        case ECallTypes.SET_OPEN_HELP_POPOVER_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    helpPopoverOpen: action.payload,
                },
            };
        }

        case ECallTypes.SET_CALL_VIDEO_BACKGROUND_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    video: {
                        ...state.data?.video,
                        background: action.payload.background,
                        receivedResolution: state?.data?.video?.receivedResolution || 'Auto',
                        deviceId: state.data?.video?.deviceId || '',
                        sendResolution: state.data?.video?.sendResolution || 'Auto',
                        cameraEnabled: !!state.data?.video?.cameraEnabled
                    },
                },
            };
        }

        case ECallTypes.SET_PUBLIC_ROOMS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    publicRooms: action.payload
                },
            };
        }


        case ECallTypes.WS_SET_PUBLIC_ROOM: {
            let publicRooms: IPublicRooms[] | [] = [];
            if (state?.data?.publicRooms?.length) {
                const findIndex = state.data.publicRooms.findIndex(p => p.id === action.payload.id);
                if (findIndex !== -1) {
                    const newPublicRooms = [...state?.data?.publicRooms];
                    newPublicRooms[findIndex] = action.payload
                    publicRooms = newPublicRooms
                }
            } else {
                publicRooms = [action.payload]
            }

            return {
                ...state,
                data: {
                    ...state.data,
                    publicRooms
                },
            };
        }

        case ECallTypes.SET_CALL_NOISE_SUPPRESSION_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    audio: {
                        ...state.data?.audio as unknown as {
                            audioEnabled: boolean;
                            deviceId: MediaDeviceInfo['deviceId'];
                            noiseSuppression: boolean;
                        },
                        noiseSuppression: action.payload
                    }
                }
            }
        }

        default:
            return state;
    }
};

export default callReducer;
