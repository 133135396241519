import {styled} from '@mui/material/styles';
import {Images} from '../../assets/images';

export const BackgroundImage = styled('div')(
    ({theme}) => `
		background-image: url('${Images.background}');
		background-size: cover;
		height: 100%;
		min-height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
);
