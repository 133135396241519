import {AxiosResponse} from 'axios';

import tokenInstance from 'utils/axiosInstance/tokenInstance';

export const postGroups = ({
    name,
}: {
    name: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`groups/`, {name});
};

export const patchGroups = ({
    name,
    id,
}: {
    name: string;
    id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`groups/${id}`, {name});
};

export const getGroups = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('groups/');
};

export const getGroupById = ({
    id,
}: {
    id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`groups/${id}`);
};

export const getGroupMembers = ({
    id,
}: {
    id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`groups/${id}/members/`);
};

export const postGroupsLogo = ({
    id,
    logo,
}: {
    id: string;
    logo: File;
}): Promise<AxiosResponse<any, any>> => {
    const formData = new FormData();
    formData.append('logo', logo as File);
    return tokenInstance().post(`groups/${id}/logo`, formData);
};

export const patchGroupsLogo = ({
    id,
    logo,
}: {
    id: string;
    logo: File;
}): Promise<AxiosResponse<any, any>> => {
    const formData = new FormData();
    formData.append('logo', logo as File);
    return tokenInstance().patch(`groups/${id}/logo`, formData);
};

export const deleteGroup = ({
    id,
}: {
    id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`groups/${id}`);
};

export const getGroupMember = ({
    id,
    member_id,
}: {
    id: string;
    member_id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`groups/${id}/members/${member_id}`);
};

export const deleteGroupMember = ({
    id,
    member_id,
}: {
    id: string;
    member_id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`groups/${id}/members/${member_id}`);
};

export const patchGroupMember = ({
    id,
    member_id,
    admin,
}: {
    id: string;
    member_id: string;
    admin: boolean;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`groups/${id}/members/${member_id}`, {
        admin,
    });
};

export const postGroupInvites = ({
    id,
    name,
    email,
    user_id,
    admin,
}: {
    id: string;
    name?: string;
    email?: string;
    user_id?: number;
    admin: boolean;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`groups/${id}/invites/`, {
        name,
        email,
        user_id,
        admin,
    });
};

export const postGroupReInvite = ({
    id,
    inviteId,
}: {
    id: string;
    inviteId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`groups/${id}/invites/${inviteId}/re-invite`);
};

export const deleteGroupInvite = ({
    id,
    inviteId,
}: {
    id: string;
    inviteId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`groups/${id}/invites/${inviteId}`);
};

export const postGroupInvitesAccept = ({
    id,
    invite_id,
}: {
    id: string;
    invite_id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`groups/${id}/invites/${invite_id}/accept`);
};

export const getGroupInvites = ({
    id,
}: {
    id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`groups/${id}/invites/`);
};

export const postGroupInvitesReject = ({
    id,
    invite_id,
}: {
    id: string;
    invite_id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`groups/${id}/invites/${invite_id}/reject`);
};

export const getGroupInviteById = ({
    id,
    invite_id,
}: {
    id: string;
    invite_id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`groups/${id}/invites/${invite_id}`);
};

export const postLeaveGroup = ({
    id,
}: {
    id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`groups/${id}/leave`);
};

export const GroupsService = {
    postGroups,
    getGroups,
    getGroupById,
    deleteGroup,
    postGroupsLogo,
    patchGroups,
    patchGroupsLogo,
    postGroupInvites,
    getGroupMembers,
    postGroupInvitesAccept,
    postGroupInvitesReject,
    getGroupInviteById,
    getGroupInvites,
    deleteGroupMember,
    patchGroupMember,
    postLeaveGroup,
    getGroupMember,
    postGroupReInvite,
    deleteGroupInvite,
};
