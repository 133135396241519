import {TStore} from 'models';

export const selectMusicPlayerTimePlayed = (state: TStore) =>
    state?.musicPlayer?.data?.time;

export const selectWhiteSounds = (state: TStore) =>
    state?.musicPlayer?.whiteSounds;

export const selectWhiteSoundsVolume = (state: TStore) =>
    state?.musicPlayer?.data?.volume;

export const selectWhiteSound = (state: TStore) =>
    state?.musicPlayer?.data?.whiteSound;

export const selectWhiteSoundIsPlaying = (state: TStore) =>
    state?.musicPlayer?.data?.isPlaying;
