import {SetStateAction, Dispatch} from 'react';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';

import OrangeCircle from 'assets/images/Ellipse264.png';
import {
    tourCompletedSaga,
    openFocusSessionReviewSessionsGuidelinesAction,
} from 'store';
import styles from './styles.module.scss';

export const steps = (
    setCurrentStep: Dispatch<SetStateAction<number>>,
    dispatch: (action: any) => void,
    appMode: 'light' | 'dark',
    t: (key: string) => string,
    setIsOpen: Dispatch<SetStateAction<Boolean>>,
) => {
    return [
        {
            selector: '#whatsYourFocus-box',
            content: (
                <div>
                    <div
                        style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                        <div className={styles.stepCircle}>
                            <img
                                src={OrangeCircle}
                                alt="1"
                                style={{height: 29, width: 29}}
                            />
                            <span className={styles.stepNumber}>1</span>
                        </div>
                        <p
                            className={styles.title}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '000000d9',
                            }}>
                            {t('tour.focus')}
                        </p>
                    </div>
                    <div style={{margin: '10px 0'}} />
                    <p
                        className={styles.subTitle}
                        style={{
                            color: appMode === 'dark' ? '#FFFFFF' : '#59617b',
                        }}>
                        {t('tour.enterYourFocus')}
                    </p>
                    <br />
                    <div style={{display: 'flex'}}>
                        <span
                            onClick={() => {
                                setIsOpen(false);
                                dispatch(tourCompletedSaga());
                            }}
                            className={styles.skip}>
                            {t('tour.skip')}
                        </span>
                        <div className={styles.nextPrev}>
                            <button
                                onClick={() => {
                                    setCurrentStep(1);
                                }}
                                className={styles.next}>
                                {t('tour.next')}
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            selector: '#sessions-box',
            content: (
                <div>
                    <div
                        style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                        <div className={styles.stepCircle}>
                            <img
                                src={OrangeCircle}
                                alt="1"
                                style={{height: 29, width: 29}}
                            />
                            <span className={styles.stepNumber}>2</span>
                        </div>
                        <p
                            className={styles.title}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '000000d9',
                            }}>
                            {t('tour.sessionDetails')}
                        </p>
                    </div>
                    <div style={{margin: '10px 0'}} />
                    <p
                        className={styles.subTitle}
                        style={{
                            color: appMode === 'dark' ? '#FFFFFF' : '#59617b',
                        }}>
                        {t('tour.checkAllUpcoming')}{' '}
                        {t('tour.importantReading')}
                    </p>
                    <ol
                        style={{
                            marginTop: 5,
                            paddingLeft: 15,
                        }}>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <p
                                    onClick={() => {
                                        setIsOpen(false);
                                        dispatch(
                                            openFocusSessionReviewSessionsGuidelinesAction(
                                                true,
                                            ),
                                        );
                                    }}
                                    className={styles.list}
                                    style={{
                                        cursor: 'pointer',
                                        color: '#ff9a00',
                                        textDecoration: 'underline',
                                    }}>
                                    {t('tour.reviewSessionGuidelines')}
                                </p>
                                <p className={styles.important}>
                                    {t('tour.important')}
                                </p>
                            </div>
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            {t('tour.checkUpcomingAndPastSessions')}
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            {t('tour.bookYourNewSession')}
                        </li>
                    </ol>
                    <div style={{display: 'flex'}}>
                        <span
                            onClick={() => {
                                setIsOpen(false);
                                dispatch(tourCompletedSaga());
                            }}
                            className={styles.skip}>
                            {t('tour.skip')}
                        </span>
                        <div className={styles.nextPrev}>
                            <button
                                onClick={() => {
                                    setCurrentStep((prev: any) => prev - 1);
                                }}
                                className={styles.prev}>
                                {t('tour.prev')}
                            </button>
                            <button
                                onClick={() => {
                                    setCurrentStep(2);
                                }}
                                className={styles.next}>
                                {t('tour.next')}
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            selector: '#calendar-box',
            content: (
                <div>
                    <div
                        style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                        <div className={styles.stepCircle}>
                            <img
                                src={OrangeCircle}
                                alt="1"
                                style={{height: 29, width: 29}}
                            />
                            <span className={styles.stepNumber}>3</span>
                        </div>
                        <p
                            className={styles.title}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '000000d9',
                            }}>
                            {t('tour.calendar')}
                        </p>
                    </div>
                    <div style={{margin: '10px 0'}} />
                    <p
                        className={styles.subTitle}
                        style={{
                            color: appMode === 'dark' ? '#FFFFFF' : '#59617b',
                        }}>
                        {t('tour.trackAllYourSession')}
                        <br />
                        <br />
                        {t('tour.importantThings')}
                    </p>
                    <ol
                        style={{
                            marginTop: 5,
                            paddingLeft: 15,
                        }}>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            {t('tour.tourItemOne')}
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            {t('tour.checkOtherPeoplesSession')}
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            {t('tour.clickOnCalendarToCreate')}
                        </li>
                    </ol>
                    <div style={{display: 'flex'}}>
                        <span
                            onClick={() => {
                                setIsOpen(false);
                                dispatch(tourCompletedSaga());
                            }}
                            className={styles.skip}>
                            {t('tour.skip')}
                        </span>
                        <div className={styles.nextPrev}>
                            <button
                                onClick={() => {
                                    setCurrentStep((prev: any) => prev - 1);
                                }}
                                className={styles.prev}>
                                {t('tour.prev')}
                            </button>
                            <button
                                onClick={() => {
                                    setCurrentStep(3);
                                }}
                                className={styles.next}>
                                {t('tour.next')}
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            selector: '#home_calendar_change_view',
            content: (
                <div>
                    <div
                        style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                        <div className={styles.stepCircle}>
                            <img
                                src={OrangeCircle}
                                alt="1"
                                style={{height: 29, width: 29}}
                            />
                            <span className={styles.stepNumber}>4</span>
                        </div>
                        <p
                            className={styles.title}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '000000d9',
                            }}>
                            {t('tour.calendarView')}
                        </p>
                    </div>
                    <div style={{margin: '10px 0'}} />
                    <p
                        className={styles.subTitle}
                        style={{
                            color: appMode === 'dark' ? '#FFFFFF' : '#59617b',
                        }}>
                        {t('tour.changePublicCalendar')}
                    </p>
                    <ol
                        style={{
                            marginTop: 5,
                            paddingLeft: 15,
                        }}>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            {t('tour.timeGridDay')}
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            {t('tour.dayGridWeek')}
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                }}>
                                <p
                                    className={styles.list}
                                    style={{
                                        color:
                                            appMode === 'dark'
                                                ? '#FFFFFF'
                                                : '#59617b',
                                    }}>
                                    {t('tour.dayGridMonth')}
                                </p>
                                <p className={styles.comingSoon}>
                                    {t('tour.comingSoon')}
                                </p>
                            </div>
                        </li>
                    </ol>
                    <div style={{display: 'flex'}}>
                        <span
                            onClick={() => {
                                setIsOpen(false);
                                dispatch(tourCompletedSaga());
                            }}
                            className={styles.skip}>
                            {t('tour.skip')}
                        </span>
                        <div className={styles.nextPrev}>
                            <button
                                onClick={() => {
                                    setCurrentStep((prev: any) => prev - 1);
                                }}
                                className={styles.prev}>
                                {t('tour.prev')}
                            </button>
                            <button
                                onClick={() => {
                                    setCurrentStep(4);
                                }}
                                className={styles.next}>
                                {t('tour.next')}
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            selector: '.fc-timegrid-now-indicator-line',
            content: (
                <div>
                    <div
                        style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                        <div className={styles.stepCircle}>
                            <img
                                src={OrangeCircle}
                                alt="1"
                                style={{height: 29, width: 29}}
                            />
                            <span className={styles.stepNumber}>5</span>
                        </div>
                        <p
                            className={styles.title}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '000000d9',
                            }}>
                            {t('tour.clickToCreate')}
                        </p>
                    </div>
                    <div style={{margin: '10px 0'}} />
                    <div
                        className={styles.subTitle}
                        style={{
                            color: appMode === 'dark' ? '#FFFFFF' : '#59617b',
                        }}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            {t('tour.clickingOnCell')}
                        </div>
                    </div>
                    <div style={{display: 'flex', marginTop: 20}}>
                        <span
                            onClick={() => {
                                setIsOpen(false);
                                dispatch(tourCompletedSaga());
                            }}
                            className={styles.skip}>
                            {t('tour.skip')}
                        </span>
                        <div className={styles.nextPrev}>
                            <button
                                onClick={() => {
                                    setCurrentStep((prev: any) => prev - 1);
                                }}
                                className={styles.prev}>
                                {t('tour.prev')}
                            </button>
                            <button
                                onClick={() => {
                                    setCurrentStep(5);
                                }}
                                className={styles.next}>
                                {t('tour.next')}
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            selector: '#tour_calendar',
            content: (
                <div>
                    <div
                        style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                        <div className={styles.stepCircle}>
                            <img
                                src={OrangeCircle}
                                alt="1"
                                style={{height: 29, width: 29}}
                            />
                            <span className={styles.stepNumber}>6</span>
                        </div>
                        <p
                            className={styles.title}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '000000d9',
                            }}>
                            {t('tour.actionOnOwnSessions')}
                        </p>
                    </div>
                    <div style={{margin: '10px 0'}} />
                    <p
                        className={styles.subTitle}
                        style={{
                            color: appMode === 'dark' ? '#FFFFFF' : '#59617b',
                        }}>
                        {t('tour.youCanPerform')}
                    </p>
                    <ol
                        style={{
                            marginTop: 5,
                            paddingLeft: 15,
                        }}>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            <strong
                                style={{
                                    color: '#ff9a00',
                                }}>
                                {t('tour.hover')}{' '}
                            </strong>{' '}
                            {t('tour.sessionToOpenDetails')}
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            <strong style={{color: '#ff9a00'}}>
                                {t('tour.drag')}
                            </strong>{' '}
                            {t('tour.sessionToDesiredHour')}
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            <strong style={{color: '#ff9a00'}}>
                                {t('tour.resize')}
                            </strong>{' '}
                            {t('tour.sessionToDesiredDuration')}
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            <strong style={{color: '#ff9a00'}}>
                                {t('tour.click')}
                            </strong>{' '}
                            {t('tour.viewMoreInformation')}
                        </li>
                    </ol>
                    <div style={{display: 'flex', marginTop: 20}}>
                        <span
                            onClick={() => {
                                setIsOpen(false);
                                dispatch(tourCompletedSaga());
                            }}
                            className={styles.skip}>
                            {t('tour.skip')}
                        </span>
                        <div className={styles.nextPrev}>
                            <button
                                onClick={() => {
                                    setCurrentStep((prev: any) => prev - 1);
                                }}
                                className={styles.prev}>
                                {t('tour.prev')}
                            </button>
                            <button
                                onClick={() => {
                                    setCurrentStep(6);
                                }}
                                className={styles.next}>
                                {t('tour.next')}
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            selector: '#tour_calendar',
            content: (
                <div>
                    <div
                        style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                        <div className={styles.stepCircle}>
                            <img
                                src={OrangeCircle}
                                alt="1"
                                style={{height: 29, width: 29}}
                            />
                            <span className={styles.stepNumber}>7</span>
                        </div>
                        <p
                            className={styles.title}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '000000d9',
                            }}>
                            {t('tour.join&LeaveSession')}
                        </p>
                    </div>
                    <div style={{margin: '10px 0'}} />
                    <p
                        className={styles.subTitle}
                        style={{
                            color: appMode === 'dark' ? '#FFFFFF' : '#59617b',
                        }}>
                        {t('tour.youCanJoinOrLeave')}
                    </p>
                    <ol
                        style={{
                            marginTop: 5,
                            paddingLeft: 15,
                        }}>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}>
                                <strong style={{color: '#FF9A00'}}>
                                    {t('tour.leavingOrDeleting')}
                                </strong>{' '}
                                {t('tour.aSession')}{' '}
                                <svg
                                    width="14"
                                    height="18"
                                    viewBox="0 0 14 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M1 16C1.00158 16.5299 1.2128 17.0377 1.58753 17.4125C1.96227 17.7872 2.47005 17.9984 3 18H11C11.5299 17.9984 12.0377 17.7872 12.4125 17.4125C12.7872 17.0377 12.9984 16.5299 13 16V4H1V16ZM14 1H10.5L9.5 0H4.5L3.5 1H0V3H14V1Z"
                                        fill="#FF9A00"
                                    />
                                </svg>
                                .
                            </div>
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}>
                                <strong style={{color: '#FF9A00'}}>
                                    {t('tour.joining')}
                                </strong>{' '}
                                {t('tour.onVideoCall')}{' '}
                                <svg
                                    width="21"
                                    height="21"
                                    viewBox="0 0 21 21"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M14.0268 10.0083L9.72683 6.88228C9.64953 6.82613 9.55822 6.79241 9.46298 6.78484C9.36773 6.77726 9.27224 6.79613 9.18703 6.83935C9.10182 6.88258 9.0302 6.94848 8.98005 7.02981C8.92991 7.11114 8.90319 7.20474 8.90283 7.30028V13.5503C8.90265 13.6459 8.92901 13.7398 8.97897 13.8213C9.02893 13.9029 9.10054 13.969 9.18583 14.0123C9.25901 14.0492 9.33986 14.0684 9.42183 14.0683C9.52985 14.0672 9.63479 14.0323 9.72183 13.9683L14.0218 10.8453C14.0885 10.7977 14.1429 10.735 14.1804 10.6622C14.2179 10.5894 14.2374 10.5087 14.2374 10.4268C14.2374 10.3449 14.2179 10.2642 14.1804 10.1914C14.1429 10.1186 14.0885 10.0558 14.0218 10.0083H14.0268Z"
                                        fill="#FF9A00"
                                    />
                                    <path
                                        d="M10.6118 0.0576172C8.56418 0.0578151 6.5626 0.665134 4.86011 1.80279C3.15762 2.94044 1.83067 4.55735 1.04704 6.44908C0.263407 8.34081 0.0582721 10.4224 0.457572 12.4307C0.856873 14.439 1.84268 16.2838 3.29035 17.7319C4.73802 19.18 6.58256 20.1664 8.59074 20.5663C10.5989 20.9661 12.6806 20.7616 14.5726 19.9785C16.4645 19.1954 18.0818 17.869 19.22 16.1668C20.3581 14.4646 20.966 12.4632 20.9668 10.4156C20.9673 9.05549 20.6999 7.70859 20.1797 6.45185C19.6595 5.19512 18.8969 4.05319 17.9352 3.09129C16.9736 2.1294 15.8319 1.36639 14.5753 0.845864C13.3187 0.325336 11.9719 0.0574858 10.6118 0.0576172ZM10.6118 19.0376C8.90556 19.038 7.23754 18.5324 5.81869 17.5847C4.39985 16.637 3.29392 15.2898 2.64079 13.7136C1.98766 12.1373 1.81667 10.4027 2.14944 8.72926C2.48221 7.05579 3.3038 5.5186 4.51029 4.31211C5.71678 3.10563 7.25397 2.28404 8.92743 1.95127C10.6009 1.61849 12.3355 1.78949 13.9117 2.44262C15.488 3.09574 16.8352 4.20167 17.7829 5.62052C18.7306 7.03936 19.2362 8.70739 19.2358 10.4136C19.2359 11.5463 19.013 12.6679 18.5796 13.7143C18.1463 14.7608 17.5111 15.7117 16.7103 16.5127C15.9095 17.3137 14.9587 17.9491 13.9124 18.3827C12.866 18.8163 11.7444 19.0395 10.6118 19.0396V19.0376Z"
                                        fill="#FF9A00"
                                    />
                                </svg>{' '}
                                .
                            </div>
                        </li>
                        <li
                            className={styles.list}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '#59617b',
                            }}>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    gap: '5px',
                                }}>
                                <strong style={{color: '#FF9A00'}}>
                                    {t('tour.booking')}
                                </strong>{' '}
                                {t('tour.anExistingSession')}
                                <BookmarkBorderOutlinedIcon
                                    data-action="book"
                                    sx={{
                                        color: '#FF9A00',
                                        width: 21,
                                        height: 21,
                                    }}
                                />
                                .
                            </div>
                        </li>
                    </ol>
                    <div style={{display: 'flex', marginTop: 20}}>
                        <span
                            onClick={() => {
                                setIsOpen(false);
                                dispatch(tourCompletedSaga());
                            }}
                            className={styles.skip}>
                            {t('tour.skip')}
                        </span>
                        <div className={styles.nextPrev}>
                            <button
                                onClick={() => {
                                    setCurrentStep((prev: any) => prev - 1);
                                }}
                                className={styles.prev}>
                                {t('tour.prev')}
                            </button>
                            <button
                                onClick={() => {
                                    setCurrentStep(7);
                                }}
                                className={styles.next}>
                                {t('tour.next')}
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
        {
            selector: '#public-rooms-box',
            content: (
                <div>
                    <div
                        style={{display: 'flex', gap: 5, alignItems: 'center'}}>
                        <div className={styles.stepCircle}>
                            <img
                                src={OrangeCircle}
                                alt="1"
                                style={{height: 29, width: 29}}
                            />
                            <span className={styles.stepNumber}>8</span>
                        </div>
                        <p
                            className={styles.title}
                            style={{
                                color:
                                    appMode === 'dark' ? '#FFFFFF' : '000000d9',
                            }}>
                            {t('homeNav.rooms')}
                        </p>
                    </div>
                    <div style={{margin: '10px 0'}} />
                    <p
                        className={styles.subTitle}
                        style={{
                            color: appMode === 'dark' ? '#FFFFFF' : '#59617b',
                        }}>
                        {t('tour.hereYouCanJoinAccountabilityRooms')}
                    </p>
                    <div style={{display: 'flex', marginTop: 20}}>
                        <div className={styles.nextPrev}>
                            <button
                                onClick={() => {
                                    setCurrentStep((prev: any) => prev - 1);
                                }}
                                className={styles.prev}>
                                {t('tour.prev')}
                            </button>
                            <button
                                onClick={() => {
                                    setIsOpen(false);
                                    dispatch(tourCompletedSaga());
                                }}
                                className={styles.next}>
                                {t('tour.done')}
                            </button>
                        </div>
                    </div>
                </div>
            ),
        },
    ];
};
