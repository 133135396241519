import {all, put, takeLatest} from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import * as Sentry from '@sentry/react';
import i18n from 'locales/i18n';

import {EUserTypes} from 'models';
import {
    deleteIgnoredUserSaga,
    postIgnoreUserSaga,
    getUserByProfileUrlActionSaga,
    setUserAction,
} from 'store';
import {UsersService} from 'services';

function* handlePostIgnoreUser({
    payload,
}: ReturnType<typeof postIgnoreUserSaga>) {
    try {
        if (!payload.userId) {
            throw new Error('Param missing.');
        }
        const {data}: AxiosResponse = yield UsersService.postIgnoreUser({
            userId: payload.userId,
        });

        if (data) {
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        const e: string = yield i18n.t('common.anErrorHasOccurred');
        payload.callbackOnError && payload.callbackOnError({message: e});
        Sentry.captureException(error);
    }
}

function* handleDeleteIgnoredUser({
    payload,
}: ReturnType<typeof deleteIgnoredUserSaga>) {
    try {
        if (!payload.userId) {
            throw new Error('Param missing.');
        }

        yield UsersService.deleteIgnoredUser({
            userId: payload.userId,
        });

        payload.callbackOnSuccess && payload.callbackOnSuccess();
    } catch (error: any) {
        const e: string = yield i18n.t('common.anErrorHasOccurred');
        payload.callbackOnError && payload.callbackOnError({message: e});
        Sentry.captureException(error);
    }
}

function* handleSetUser({
    payload,
}: ReturnType<typeof getUserByProfileUrlActionSaga>) {
    try {
        const {data} = yield UsersService.getUserByProfileUrl({
            profileUrl: payload.profileUrl,
        });

        if (data) {
            yield put(setUserAction(data));
            payload.callback && payload.callback();
        }
    } catch (error) {
        console.log({handleSetUser: error});
        payload.callback && payload.callback();
        Sentry.captureException(error);
    }
}

export function* UsersSagas(): Generator<unknown, any, undefined> {
    return yield all([
        takeLatest(EUserTypes.POST_USERS_IGNORE_SAGA, handlePostIgnoreUser),
        takeLatest(EUserTypes.DELETE_USER_IGNORE_SAGA, handleDeleteIgnoredUser),
        takeLatest(EUserTypes.GET_USER_ACTION_SAGA, handleSetUser),
    ]);
}
