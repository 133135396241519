import {CircularProgress, Paper, InputAdornment} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {useTheme} from '@mui/material/styles';
import {UseFormSetError} from 'react-hook-form';
import {useTranslation} from 'react-i18next';

import {ReactComponent as Done} from 'assets/images/done.svg';
import {ReactComponent as Clock} from 'assets/images/clock.svg';
import {IUser} from 'models';

export const TimezoneAutocomplete = ({
    appTimezones,
    timezoneLoading,
    authUser,
    onChange,
    setHookError,
    formErrors,
    value,
    inputHeight,
    fontSize,
}: {
    appTimezones: string[];
    timezoneLoading: boolean;
    authUser: IUser;
    onChange: (...event: any[]) => void;
    setHookError: UseFormSetError<any>;
    formErrors: Record<string, any>;
    value: string;
    inputHeight?: number;
    fontSize?: number;
}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    return (
        <Autocomplete
            disabled={timezoneLoading}
            disableClearable
            options={
                appTimezones && appTimezones?.length > 0 ? appTimezones : []
            }
            defaultValue={
                authUser?.user?.timezone ||
                Intl.DateTimeFormat().resolvedOptions().timeZone
            }
            clearOnEscape={true}
            onChange={(event, newValue) => {
                onChange(newValue);
                if (newValue?.length === 0) {
                    setHookError('timezone', {
                        message: ``,
                    });
                    return;
                }

                if (
                    newValue !==
                    Intl.DateTimeFormat().resolvedOptions().timeZone
                ) {
                    setHookError('timezone', {
                        message:
                            `${t('timezone.theRecommendedTimezoneForYouIs')} ` +
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                    });
                }
            }}
            PaperComponent={({children}) => (
                <Paper
                    style={{
                        background:
                            theme.palette.mode === 'dark'
                                ? '#1b1e24'
                                : '#FFFFFF',
                    }}>
                    {children}
                </Paper>
            )}
            renderInput={params => (
                <TextField
                    {...params}
                    className="language_box"
                    variant="standard"
                    required
                    placeholder={`${t('timezone.chooseYourTimezone')} *`}
                    error={!!formErrors.timezone}
                    helperText={
                        formErrors?.timezone?.message
                            ? formErrors?.timezone?.message
                            : t('timezone.searchYourDesiredTimezone') || ''
                    }
                    label=""
                    value={value}
                    InputProps={{
                        style: {
                            height: inputHeight ? inputHeight : 50,
                            color:
                                theme.palette.mode === 'dark'
                                    ? '#a6a9aa'
                                    : '#59617b',
                            // opacity: 0.38,
                            paddingRight: 0,
                            fontSize: fontSize ? fontSize : 16,
                        },
                        ...params.InputProps,
                        // type: 'search',
                        startAdornment: (
                            <InputAdornment position="start">
                                {!formErrors?.timezone?.message && value ? (
                                    <Done width={14} height={15} />
                                ) : (
                                    <Clock
                                        fill="#CBCED6"
                                        width={20}
                                        height={20}
                                    />
                                )}
                            </InputAdornment>
                        ),
                        endAdornment: (
                            <InputAdornment position="start">
                                {timezoneLoading && (
                                    <CircularProgress
                                        size={15}
                                        sx={{
                                            color: 'primary.main',
                                            mr: 1,
                                        }}
                                    />
                                )}
                                <svg
                                    width="10"
                                    height="5"
                                    viewBox="0 0 10 5"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 0L5 5L10 0H0Z" fill="#808080" />
                                </svg>
                            </InputAdornment>
                        ),
                    }}
                />
            )}
        />
    );
};
