import {memo, useState} from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import {useTheme} from '@mui/system';
import {InputAdornment, MenuProps} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import {ReactComponent as DropDown} from 'assets/images/dropdownIcon.svg';

export const SelectComponent = memo(
    ({
        displayEmpty,
        helperText,
        emLabel,
        items,
        placeholder,
        startAdornment,
        field,
        error,
        MenuProps,
        required,
    }: {
        displayEmpty?: boolean;
        helperText?: string;
        emLabel: string;
        items: {label: string; value: string | number}[];
        placeholder?: string;
        startAdornment?: any;
        MenuProps?: Partial<MenuProps>;
        field?: any;
        error?: any;
        required?: boolean;
    }) => {
        const theme = useTheme();
        const [dropDownMenuOpen, setDropDownMenuOpen] =
            useState<boolean>(false);

        return (
            <FormControl fullWidth error={error}>
                <InputLabel id="custom-select" style={{marginLeft: -15}}>
                    {emLabel}
                </InputLabel>
                <Select
                    variant="standard"
                    required={required}
                    displayEmpty={false}
                    placeholder={placeholder}
                    onOpen={() => {
                        setDropDownMenuOpen(true);
                    }}
                    startAdornment={
                        startAdornment ? (
                            <InputAdornment position="start">
                                {startAdornment}
                            </InputAdornment>
                        ) : null
                    }
                    onClose={() => {
                        setDropDownMenuOpen(false);
                    }}
                    sx={{
                        width: '100%',
                    }}
                    id="custom-select"
                    error={error}
                    IconComponent={() => <DropDown />}
                    MenuProps={{...MenuProps}}
                    style={{marginTop: 10}}
                    {...field}>
                    <MenuItem disabled value="" sx={{display: 'none'}}>
                        <em
                            style={{
                                fontStyle: 'normal',
                                fontFamily: 'Brandon Grotesque',
                                fontWeight: 400,
                                fontSize: 17,
                                color: helperText
                                    ? theme.palette.error.main
                                    : theme.palette.text.primary,
                                opacity: 0.38,
                            }}>
                            {emLabel}
                        </em>
                    </MenuItem>
                    {items?.map(
                        (
                            item: {label: string; value: string | number},
                            index: number,
                        ) => {
                            return (
                                <MenuItem
                                    value={item.value}
                                    key={`dropdown-items-${item.label}-${item.value}-${index}`}
                                    sx={{
                                        fontFamily: 'Brandon Grotesque',
                                        fontStyle: 'normal',
                                        fontWeight: 500,
                                        fontSize: 17,
                                        '&:hover': {
                                            backgroundColor: 'primary.main',
                                            color: '#fff',
                                        },
                                        color: !dropDownMenuOpen
                                            ? 'primary.main'
                                            : theme.palette.text.primary,
                                    }}>
                                    {item.label}
                                </MenuItem>
                            );
                        },
                    )}
                </Select>
                {error && (
                    <FormHelperText
                        style={{
                            marginLeft: 0,
                            fontFamily: 'Brandon Grotesque',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: 14,
                            lineHeight: '20px',
                        }}>
                        {error}
                    </FormHelperText>
                )}
            </FormControl>
        );
    },
);
