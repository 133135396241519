import { useCallback } from 'react';

export const useTawkTo = () => {
    const showWidget = useCallback(() => {
        if (
            window?.Tawk_API !== undefined &&
            typeof window?.Tawk_API.showWidget === 'function'
        ) {
            window.Tawk_API.showWidget();
        }
    }, [])

    const hideWidget = useCallback(() => {
        if (
            window?.Tawk_API !== undefined &&
            typeof window?.Tawk_API.hideWidget === 'function'
        ) {
            window.Tawk_API.hideWidget();
        }
    }, [])

    return {
        showWidget,
        hideWidget
    }
}