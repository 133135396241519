import {ISetCompanyAction, ISetCompanyAddressAction} from './actions';

export enum ECompanyTypes {
    SET_COMPANY = 'SET/COMPANY/ACTION',
    SET_COMPANY_ADDRESS = 'SET/COMPANY/ADDRESS/ACTION',
}

export interface ICompanyAddress {
    contact_email: string;
    created_by: number;
    id: number;
    locality: string;
    name: string;
    phone_number: string;
    postal_code: string;
    state_or_county: string;
    street_address_1: string;
    street_address_2: string;
    updated_by: number | null;
}
export interface ICompany {
    id: number;
    name: string;
    updated_at: Date | null;
    logo: string | null;
    address: ICompanyAddress | null;
}

export interface ICompanyMember {
    id: number;
    user: number;
    company: number;
    admin: boolean;
}

export interface ICompanyState {
    data?: ICompany;
}

export type TCompanyActionTypes = ISetCompanyAction | ISetCompanyAddressAction;
