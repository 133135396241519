import axios from 'axios';
import {retryInstance} from './retryInstance';

const defaultOptions: {
    baseURL: string | undefined;
    timeout: number;
} = {
    baseURL: process.env.REACT_APP_URL as string | undefined,
    timeout: 10000,
};

let activeToken: string | null = null;
// * Create instance
let instance = axios.create(defaultOptions);
let interceptor: number | null = null;

export const updateToken: (token: string) => boolean = (token: string) => {
    if (interceptor) {
        instance.interceptors.request.eject(interceptor);
    }

    try {
        interceptor = instance.interceptors.request.use(
            (config: any) => {
                activeToken = token;
                config.headers['Authorization'] = token
                    ? `Token ${token}` || ''
                    : '';

                return config;
            },
            (error: any) => {
                console.log({AxiosInstance: error});
                return Promise.reject(error);
            },
        );
    } catch (error: any) {
        console.log({interceptorError: error});
    }

    return true;
};

export const getActiveToken: () => string | null = () => {
    return activeToken;
};

export const getApiURL: () => string | undefined = () => {
    return defaultOptions.baseURL;
};

export const clearToken: () => void = () => {
    instance = axios.create(defaultOptions);
};

// eslint-disable-next-line import/no-anonymous-default-export
export default () => instance;
retryInstance(instance);
