import {memo} from 'react';
import PageVisibility from 'react-page-visibility';
// @ts-ignore
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

import {PomodoroWidget} from 'pages/pomodoro/widget';
import {VerifyEmail} from './components';
import {useLayout} from './hooks/useLayout';

/**
 * @param {{children: React.ReactNode}} {children}
 * @return {*}
 */
export const Layout = memo(({children}: {children: React.ReactNode}) => {
    const {
        tawkMessengerRef,
        handleVisibilityChange,
        authUserToken,
        authUserProfileComplete,
        authUserTourComplete,
        location,
        showPomodoroWidget,
        authUserMessage,
    } = useLayout();

    return (
        <PageVisibility onChange={handleVisibilityChange}>
            <>
                {authUserToken &&
                    authUserProfileComplete &&
                    authUserTourComplete &&
                    authUserMessage && <VerifyEmail />}
                {children}
                {location.pathname !== '/pomodoro' && showPomodoroWidget && (
                    <PomodoroWidget />
                )}
                {process.env.REACT_APP_TAWKTO_PROPERTY_ID &&
                    process.env.REACT_APP_TAWKTO_WIDGET_ID && (
                        <TawkMessengerReact
                            propertyId={
                                process.env.REACT_APP_TAWKTO_PROPERTY_ID
                            }
                            widgetId={process.env.REACT_APP_TAWKTO_WIDGET_ID}
                            ref={tawkMessengerRef}
                        />
                    )}
            </>
        </PageVisibility>
    );
});
