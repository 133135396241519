import {memo, useRef, useState, useEffect} from 'react';
import {Box, CircularProgress, Typography, useTheme} from '@mui/material';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Pagination, EffectCoverflow, Navigation} from 'swiper';
import {useTranslation} from 'react-i18next';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.scss';

import {Images} from 'assets/images';
import {ReusableModalWindow} from 'components/modal';
import styles from './styles.module.scss';

export const HowItWorksCarousel = memo(
    ({setOpen, open}: {setOpen: (isOpen: boolean) => void; open: boolean}) => {
        const theme = useTheme();
        const {t} = useTranslation();
        const modalRef = useRef<HTMLDivElement | null>(null);
        const [initialized, setInitialized] = useState<boolean>(false);
        const [showCarousel, setShowCarousel] = useState<boolean>(false);
        const pagination = {
            clickable: true,
            renderBullet: function (index: number, className: string) {
                return (
                    '<span class="' + className + '">' + (index + 1) + '</span>'
                );
            },
        };

        useEffect(() => {
            if (open && initialized) {
                const element = document.getElementById(
                    'focus101-action-modal',
                ) as unknown as HTMLDivElement | null;
                modalRef.current = element;
                setShowCarousel(true);
            }
        }, [open, initialized]);

        return (
            <ReusableModalWindow
                open={open}
                setOpen={setOpen}
                alignItems="center"
                height={'auto'}
                setInitialized={setInitialized}>
                <Box
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                    <Typography
                        id="modal-modal-title"
                        className={styles.title}
                        variant="h1"
                        sx={{
                            color:
                                theme.palette.mode !== 'dark'
                                    ? 'rgba(0, 0, 0, 0.85)'
                                    : '#fff7ea',
                        }}
                        component="h2">
                        {t('home.welcomeModal.howItWorks')}
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{
                            color:
                                theme.palette.mode !== 'dark'
                                    ? '#59617b'
                                    : '#a6a9aa',
                        }}
                        className={styles.description}>
                        {t('home.welcomeModal.pleaseTakeAMomentToReview')}
                    </Typography>
                    {showCarousel ? (
                        <Box
                            sx={{
                                height: {xs: '300px', sm: '400px'},
                                '> .swiper': {
                                    height: '100%',
                                    width: {
                                        xs:
                                            (modalRef?.current
                                                ?.offsetWidth as number) - 50 ||
                                            300,
                                        md:
                                            (modalRef?.current
                                                ?.offsetWidth as number) - 66 ||
                                            550,
                                    },
                                },
                            }}>
                            <Swiper
                                spaceBetween={0}
                                pagination={pagination}
                                modules={[
                                    Pagination,
                                    EffectCoverflow,
                                    Navigation,
                                ]}
                                slidesPerView={1}
                                loop={true}
                                navigation={true}
                                direction="horizontal"
                                centeredSlides={true}
                                onSlideChange={() => {}}
                                onSwiper={swiper => {}}>
                                <SwiperSlide>
                                    <Box
                                        style={{
                                            color: 'white',
                                        }}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Proxima Nova',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '16px',
                                                    md: '20px',
                                                },
                                                lineHeight: {
                                                    xs: '25px',
                                                    md: '28px',
                                                },
                                                textAlign: 'left',
                                                color:
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'white'
                                                        : '#000000D9',
                                                mb: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#FF9A00',
                                                    width: {xs: 25, md: 30},
                                                    height: {xs: 25, md: 30},
                                                    minHeight: {xs: 25, md: 30},
                                                    minWidth: {xs: 25, md: 30},
                                                    fontFamily: 'Proxima Nova',
                                                    fontStyle: 'normal',
                                                    fontWeight: 700,
                                                    fontSize: {
                                                        xs: '18px',
                                                        md: '22px',
                                                    },
                                                    color: '#FFFFFF',
                                                    borderRadius: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                1
                                            </Box>
                                            {t(
                                                'home.welcomeModal.bookASession',
                                            )}
                                        </Typography>
                                        <Box>
                                            <Box
                                                component={'img'}
                                                sx={{
                                                    objectFit: {
                                                        xs: 'contain',
                                                        sm: 'unset',
                                                    },
                                                    width: '100%',
                                                    height: {sm: '310px'},
                                                    minHeight: {sm: '310px'},
                                                    borderRadius: '8px',
                                                }}
                                                src={Images.BookSession}></Box>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box style={{color: 'white'}}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Proxima Nova',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '16px',
                                                    md: '20px',
                                                },
                                                lineHeight: {
                                                    xs: '25px',
                                                    md: '28px',
                                                },
                                                textAlign: 'left',
                                                color:
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'white'
                                                        : '#000000D9',
                                                mb: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#FF9A00',
                                                    width: {xs: 25, md: 30},
                                                    height: {xs: 25, md: 30},
                                                    minHeight: {xs: 25, md: 30},
                                                    minWidth: {xs: 25, md: 30},
                                                    fontFamily: 'Proxima Nova',
                                                    fontStyle: 'normal',
                                                    fontWeight: 700,
                                                    fontSize: {
                                                        xs: '18px',
                                                        md: '22px',
                                                    },
                                                    color: '#FFFFFF',
                                                    borderRadius: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                2
                                            </Box>
                                            {t(
                                                'home.welcomeModal.shareYourPrimaryTask',
                                            )}
                                        </Typography>
                                        <Box sx={{position: 'relative'}}>
                                            <Box
                                                component={'img'}
                                                sx={{
                                                    objectFit: {
                                                        xs: 'contain',
                                                        sm: 'unset',
                                                    },
                                                    width: '100%',
                                                    height: {sm: '310px'},
                                                    minHeight: {sm: '310px'},
                                                    borderRadius: '8px',
                                                }}
                                                src={
                                                    Images.BeforeSession
                                                }></Box>
                                            <Box
                                                component={'img'}
                                                sx={{
                                                    position: 'absolute',
                                                    right: {xs: 0, md: -30},
                                                    width: {xs: 125, sm: 250},
                                                    height: {xs: 125, sm: 250},
                                                    bottom: {xs: -40, sm: -60},
                                                    objectFit: {
                                                        xs: 'contain',
                                                    },
                                                }}
                                                src={Images.WireFrame}></Box>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box style={{color: 'white'}}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Proxima Nova',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '16px',
                                                    md: '20px',
                                                },
                                                lineHeight: {
                                                    xs: '25px',
                                                    md: '28px',
                                                },
                                                textAlign: 'left',
                                                color:
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'white'
                                                        : '#000000D9',
                                                mb: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#FF9A00',
                                                    width: {xs: 25, md: 30},
                                                    height: {xs: 25, md: 30},
                                                    minHeight: {xs: 25, md: 30},
                                                    minWidth: {xs: 25, md: 30},
                                                    fontFamily: 'Proxima Nova',
                                                    fontStyle: 'normal',
                                                    fontWeight: 700,
                                                    fontSize: {
                                                        xs: '18px',
                                                        md: '22px',
                                                    },
                                                    color: 'white',
                                                    borderRadius: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                3
                                            </Box>
                                            {t(
                                                'home.welcomeModal.startWorkingWithYourCameraOn',
                                            )}
                                        </Typography>
                                        <Box sx={{position: 'relative'}}>
                                            <Box
                                                component={'img'}
                                                sx={{
                                                    objectFit: {
                                                        xs: 'contain',
                                                        sm: 'unset',
                                                    },
                                                    width: '100%',
                                                    height: {sm: '310px'},
                                                    minHeight: {sm: '310px'},
                                                    borderRadius: '8px',
                                                }}
                                                src={Images.VideoCall}></Box>
                                            <Box
                                                component={'img'}
                                                sx={{
                                                    position: 'absolute',
                                                    left: {xs: 0, md: -8},
                                                    width: {xs: 125, sm: 210},
                                                    height: {xs: 125, sm: 210},
                                                    bottom: {xs: -40, sm: -30},
                                                    objectFit: {
                                                        xs: 'contain',
                                                    },
                                                }}
                                                src={Images.SessionGoal}></Box>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box style={{color: 'white'}}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Proxima Nova',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '16px',
                                                    md: '20px',
                                                },
                                                lineHeight: {
                                                    xs: '25px',
                                                    md: '28px',
                                                },
                                                textAlign: 'left',
                                                color:
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'white'
                                                        : '#000000D9',
                                                mb: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#FF9A00',
                                                    width: {xs: 25, md: 30},
                                                    height: {xs: 25, md: 30},
                                                    minHeight: {xs: 25, md: 30},
                                                    minWidth: {xs: 25, md: 30},
                                                    fontFamily: 'Proxima Nova',
                                                    fontStyle: 'normal',
                                                    fontWeight: 700,
                                                    fontSize: {
                                                        xs: '18px',
                                                        md: '22px',
                                                    },
                                                    color: 'white',
                                                    borderRadius: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                4
                                            </Box>
                                            {t(
                                                'home.welcomeModal.leaveAReviewForYourPartner',
                                            )}
                                        </Typography>
                                        <Box sx={{position: 'relative'}}>
                                            <Box
                                                component={'img'}
                                                sx={{
                                                    objectFit: {
                                                        xs: 'contain',
                                                        sm: 'unset',
                                                    },
                                                    width: '100%',
                                                    height: {sm: '310px'},
                                                    minHeight: {sm: '310px'},
                                                    borderRadius: '8px',
                                                }}
                                                src={Images.RatePartner}></Box>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box style={{color: 'white'}}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Proxima Nova',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '16px',
                                                    md: '20px',
                                                },
                                                lineHeight: {
                                                    xs: '25px',
                                                    md: '28px',
                                                },
                                                textAlign: 'left',
                                                color:
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'white'
                                                        : '#000000D9',
                                                mb: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#FF9A00',
                                                    width: {xs: 25, md: 30},
                                                    height: {xs: 25, md: 30},
                                                    minHeight: {xs: 25, md: 30},
                                                    minWidth: {xs: 25, md: 30},
                                                    fontFamily: 'Proxima Nova',
                                                    fontStyle: 'normal',
                                                    fontWeight: 700,
                                                    fontSize: {
                                                        xs: '18px',
                                                        md: '22px',
                                                    },
                                                    color: 'white',
                                                    borderRadius: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                5
                                            </Box>
                                            {t(
                                                'home.welcomeModal.addAPartnerToYourFavorites',
                                            )}
                                        </Typography>
                                        <Box>
                                            <Box
                                                component={'img'}
                                                sx={{
                                                    objectFit: {
                                                        xs: 'contain',
                                                        sm: 'unset',
                                                    },
                                                    width: '100%',
                                                    height: {sm: '310px'},
                                                    minHeight: {sm: '310px'},
                                                    borderRadius: '8px',
                                                }}
                                                src={
                                                    Images.FavouritePartners
                                                }></Box>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                                <SwiperSlide>
                                    <Box style={{color: 'white'}}>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Proxima Nova',
                                                fontStyle: 'normal',
                                                fontWeight: 700,
                                                fontSize: {
                                                    xs: '16px',
                                                    md: '20px',
                                                },
                                                lineHeight: {
                                                    xs: '25px',
                                                    md: '28px',
                                                },
                                                textAlign: 'left',
                                                color:
                                                    theme.palette.mode ===
                                                    'dark'
                                                        ? 'white'
                                                        : '#000000D9',
                                                mb: 3,
                                                display: 'flex',
                                                alignItems: 'center',
                                                gap: 1,
                                            }}>
                                            <Box
                                                sx={{
                                                    backgroundColor: '#FF9A00',
                                                    width: {xs: 25, md: 30},
                                                    height: {xs: 25, md: 30},
                                                    minHeight: {xs: 25, md: 30},
                                                    minWidth: {xs: 25, md: 30},
                                                    fontFamily: 'Proxima Nova',
                                                    fontStyle: 'normal',
                                                    fontWeight: 700,
                                                    fontSize: {
                                                        xs: '18px',
                                                        md: '22px',
                                                    },
                                                    color: '#FFFFFF',
                                                    borderRadius: '100%',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                }}>
                                                6
                                            </Box>
                                            {t(
                                                'home.welcomeModal.badExperience',
                                            )}
                                        </Typography>
                                        <Box>
                                            <Box
                                                component={'img'}
                                                sx={{
                                                    objectFit: {
                                                        xs: 'contain',
                                                        sm: 'unset',
                                                    },
                                                    width: '100%',
                                                    height: {sm: '310px'},
                                                    minHeight: {sm: '310px'},
                                                    borderRadius: '8px',
                                                }}
                                                src={
                                                    Images.ReportPartner
                                                }></Box>
                                        </Box>
                                    </Box>
                                </SwiperSlide>
                            </Swiper>
                        </Box>
                    ) : (
                        <CircularProgress size={15} />
                    )}
                </Box>
            </ReusableModalWindow>
        );
    },
);
