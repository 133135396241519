
import { EGiftAccountsTypes, IGiftAccount } from "models";

export const getGiftAccountsAction = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError: () => void;
}) => ({
    type: EGiftAccountsTypes.GET_GIFT_ACCOUNTS,
    payload,
});


export const setGiftAccountsAction = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError: () => void;
    giftAccounts: IGiftAccount
}) => ({
    type: EGiftAccountsTypes.SET_GIFT_ACCOUNTS,
    payload,
});
