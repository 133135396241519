import {ETodoTypes, ITodo} from 'models';

export const openCreateOrEditTodoAction = (payload: {
    witch: 'CREATE' | 'EDIT' | '';
}) => ({
    type: ETodoTypes.OPEN_CREATE_OR_EDIT_MODAL,
    payload,
});

export const getTodosActionSaga = (payload: {
    callbackOnSuccess?: () => void;
    callbackOnError?: () => void;
}) => ({
    type: ETodoTypes.GET_TODOS_SAGA,
    payload,
});

export const setTodosAction = (payload: {
    todos: ITodo | ITodo[] | undefined;
    reset?: boolean;
}) => ({
    type: ETodoTypes.SET_TODOS,
    payload,
});

export const postTodoAction = (payload: {todo: Partial<ITodo>}) => ({
    type: ETodoTypes.POST_TODO_SAGA,
    payload,
});

export const setEditableTodoAction = (payload: {todo: ITodo | undefined}) => ({
    type: ETodoTypes.SET_EDITABLE_TODO,
    payload,
});

export const patchTodoActionSaga = (payload: {
    directUpdate?: boolean;
    todo: ITodo;
    callbackOnSuccess?: () => void;
    callbackOnError?: () => void;
}) => ({
    type: ETodoTypes.PATCH_TODO_SAGA,
    payload,
});

export const deleteTodoActionSaga = (payload: {
    callbackOnSuccess?: () => void;
    callbackOnError?: () => void;
}) => ({
    type: ETodoTypes.DELETE_TODO_SAGA,
    payload,
});

export const clearAllTasksActionSaga = (payload: {
    callbackOnSuccess?: () => void;
    callbackOnError?: () => void;
}) => ({
    type: ETodoTypes.CLEAR_ALL_TASKS_SAGA,
    payload,
});

export const clearAllCompletedTasksActionSaga = (payload: {
    callbackOnSuccess?: () => void;
    callbackOnError?: () => void;
}) => ({
    type: ETodoTypes.CLEAR_ALL_COMPLETED_TASKS_SAGA,
    payload,
});

export const finishPomodoroRoundEarlyAction = (payload: boolean) => ({
    type: ETodoTypes.FINISH_POMODORO_ROUND_EARLY,
    payload,
});

export const pickNewTaskWhileOneRunningAction = (payload: {
    pickNew: boolean;
    task?: ITodo | undefined;
}) => ({
    type: ETodoTypes.PICK_NEW_TASK_WHILE_ONE_RUNNING,
    payload,
});

export const pressPomodoroTimerAction = (payload: {
    running?: boolean;
    timeLeft?: {
        pomodoro?: number;
        shortBreak?: number;
        longBreak?: number;
    };
}) => ({
    type: ETodoTypes.PRESS_POMODORO_TIMER,
    payload,
});

export const setPomodoroCurrentTaskAction = (payload: {
    todo: ITodo | undefined;
}) => ({
    type: ETodoTypes.SET_POMODORO_CURRENT_TASK,
    payload,
});

export const setNumberOfTodosFinishedAction = (payload: number) => ({
    type: ETodoTypes.SET_NUMBER_OF_TODOS_FINISHED,
    payload,
});

export const setActivePomodoroTabAction = (
    payload: 'POMODORO' | 'SHORT_BREAK' | 'LONG_BREAK',
) => ({
    type: ETodoTypes.SET_ACTIVE_POMODORO_TAB,
    payload,
});

export const setShowPomodoroWidgetAction = (payload: boolean) => ({
    type: ETodoTypes.SET_SHOW_POMODORO_WIDGET,
    payload,
});

export const setPomodoroSettingsAction = (payload: {
    pomodoro: number;
    shortBreak: number;
    longBreak: number;
}) => ({
    type: ETodoTypes.SET_POMODORO_SETTINGS,
    payload,
});
