import { all } from 'redux-saga/effects';

import { AppSagas } from './app';
import { AuthSagas } from './auth';
import { FocusSessionsSagas } from './focusSessions';
import { PaymentsSagas } from './payments';
import { TodoSagas } from './todo';
import { UsersSagas } from './user';
import { WhiteSoundsSagas } from './whiteSounds';

// ** Root Saga.
export default function* rootSaga() {
    yield all([
        AuthSagas(),
        AppSagas(),
        FocusSessionsSagas(),
        WhiteSoundsSagas(),
        UsersSagas(),
        TodoSagas(),
        PaymentsSagas()
    ]);
}

export * from './app';
export * from './auth';
export * from './focusSessions';
export * from './whiteSounds';
export * from './todo';
export * from './payments';
