import {styled} from '@mui/material/styles';
import {NavLink} from 'react-router-dom';

export const CardContainer = styled('div')`
    width: 539px;
    max-width: 539px;
    border-radius: 20px;
    padding: 40px 40px;
    display: flex;
    align-items: center;
    max-height: 100%;
    flex-direction: column;
    zoom: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: ${props => props.theme.palette.background.default};
    box-shadow: 0px 29px 34px 0px #9aa7bd50;
    max-height: calc(100vh - 20px);

    @media (min-width: 998px) {
        height: fit-content;
    }
    @media (max-width: 768px) {
        max-width: 450px;
        padding: 35px 20px;
    }
    @media (max-width: 567px) {
        width: 100%;
        padding: 30px 20px;
        margin: 0 20px;
    }
`;

export const CardInnerContent = styled('div')`
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 100%;
`;

export const CardLogoImage = styled('img')`
    width: 160px;
    height: 40px;
    @media (max-width: 567px) {
        width: 135px;
        height: 33px;
    }
`;

export const CardTitleHeading = styled('h3')`
    font-style: normal;
    font-weight: 700;
    font-size: 26px;
    line-height: 33px;
    text-align: center;
    color: var(--theme-text-dark-color);
    font-family: Proxima Nova;
    letter-spacing: 0.5px;
    margin: 10px;

    @media (max-width: 768px) {
        font-size: 26px;
        line-height: 28px;
    }
    @media (max-width: 576px) {
        font-size: 21px;
        line-height: 29px;
    }
`;

export const CardTitleHeadingNew = styled('h3')`
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 37px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
    font-family: Proxima Nova;
    letter-spacing: 0.5px;
    margin: 0px 0px 0px 0px;

    @media (max-width: 768px) {
        font-size: 26px;
        line-height: 28px;
    }
    @media (max-width: 576px) {
        font-size: 22px;
        line-height: 24px;
        margin: 15px 0px 15px 6px;
    }
    @media (max-width: 476px) {
        font-size: 20px;
        margin: 10px 0px 10px 6px;
    }
    @media (max-width: 350px) {
        font-size: 18px;
    }
`;
export const CardTitleSpanImg = styled('img')`
    width: 20px;
    height: 20px;
    margin-left: 5px;
    margin-bottom: -2px;

    @media (max-width: 768px) {
        width: 20px;
        height: 20px;
        margin-left: 5px;
        margin-bottom: -5px;
    }
    @media (max-width: 576px) {
        width: 18px;
        height: 18px;
    }
    @media (max-width: 476px) {
        margin-bottom: -2px;
        width: 18px;
        height: 18px;
    }
`;

export const CardTitleSmallText = styled('p')`
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);

    @media (max-width: 576px) {
        font-size: 18px;
    }
    @media (max-width: 476px) {
        font-size: 16px;
    }
`;

export const CardTextFieldContainer = styled('div')`
    display: flex;
    flex-direction: column;
    // margin: 30px 0px 19px 0px;
    width: 100%;

    @media (max-width: 768px) {
        // margin: 15px 0px;
    }
    @media (max-width: 350px) {
        // margin: 5px 0px;
    }
`;

export const CardInputImage = styled('img')`
    width: 20px;
    height: 20px;
`;

export const CardInputIconContainer = styled('div')`
    display: flex;
    align-items: center;
    width: 100%;
    opacity: 0.7;
`;

export const CardInstructionText = styled('p')`
    font-family: Brandon Grotesque;

    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    color: rgba(0, 0, 0, 0.85);

    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-width: 576px) {
        font-size: 16px;
        order: 1;
    }
`;

export const LoginFailedText = styled('p')`
    font-family: Proxima Nova;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 1.66;
    color: ${props => props.theme.palette.error.main};
    text-align: left;
    display: block;
    width: 100%;
`;

export const CardInstructionAhrefText = styled('a')`
    text-decoration: none;
    color: ${props => props.theme.palette.primary.main};
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    margin: 10px 0;

    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-width: 576px) {
        font-size: 16px;
        margin: 8px 0;
    }
    @media (max-width: 350px) {
        font-size: 16px;
        margin: 5px 0;
    }
`;

export const CardInstructionLinkText = styled(NavLink)`
    text-decoration: none;
    color: ${props => props.theme.palette.primary.main};
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    margin: 10px 0;

    @media (max-width: 768px) {
        font-size: 18px;
        line-height: 23px;
    }
    @media (max-width: 576px) {
        font-size: 18px;
        line-height: 23px;
    }
`;

export const CardInstructionLinkTextLogout = styled('div')`
    text-decoration: none;
    color: ${props => props.theme.palette.primary.main};
    font-family: Brandon Grotesque;
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 29px;
    margin: 10px 0;

    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-width: 576px) {
        font-size: 16px;
        margin: 8px 0;
    }
    @media (max-width: 350px) {
        font-size: 16px;
        margin: 5px 0;
    }
`;

export const CardInstructionLinkTextNew = styled(NavLink)`
    text-decoration: none;
    color: ${props => props.theme.palette.primary.main};
    font-family: Brandon Grotesque;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 29px;
    margin: -10px 20px;
    text-align: center;
    width: 100%;
    @media (max-width: 768px) {
        font-size: 18px;
    }
    @media (max-width: 576px) {
        font-size: 16px;
        margin: 8px 0;
    }
    @media (max-width: 350px) {
        font-size: 16px;
        margin: 5px 0;
    }
`;

export const ButtonContainer = styled('div')`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin: 31px 0 31px 0;
    gap: 15px;

    @media (max-width: 768px) {
        margin: 15px 0 15px 0;
    }
    @media (max-width: 576px) {
        margin: 15px 0 15px 0;
        justify-content: center;
        flex-direction: column;
    }
`;

export const Root = styled('div')(({theme}) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
        marginTop: theme.spacing(2),
    },
}));

export const ContinueText = styled('p')`
    font-family: Brandon Grotesque;
    width: 100%;
    font-size: 18px;
    line-height: 26px;
    color: ${props => props.theme.palette.text.secondary};
`;

export const SocialButtons = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin: 35px 10px 10px 10px;

    @media (max-width: 768px) {
        margin: 15px 0px;
    }
    @media (max-width: 576px) {
        margin: 10px 0px;
    }
    @media (max-width: 350px) {
        margin: 5px 0px;
    }
`;

export const SocialBtnImageGoogle = styled('img')`
    width: 100%;
    margin-right: 0px;
    width: 22px;
    height: 22px;
`;

export const SocialBtnImageFacebook = styled('img')`
    width: 100%;
    margin-right: 0px;
    width: 12px;
    height: 22px;
`;

export const FacebookBtn = styled('button')`
    cursor: pointer;
    padding: 15px 40px;
    border-radius: 40px;
    outline: none;
    border: 2px solid #e9f1f6;
    background-color: transparent;
    font-size: 22px;
    line-height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (max-width: 768px) {
        padding: 8px 22px;
    }
    @media (max-width: 576px) {
        font-size: 18px;
    }
    @media (max-width: 476px) {
        font-size: 16px;
    }
    @media (max-width: 350px) {
        margin: 0px 4px;
    }
`;

export const SocialBtnText = styled('p')`
    margin-left: 10px;
    margin-block-start: 0em;
    font-family: Proxima Nova;
    color: #6d7278;
    font-weight: 600;
    margin-block-end: 0em;
    font-style: normal;
    line-height: 19px;

    @media (max-width: 476px) {
        margin-left: 8px;
    }
    @media (max-width: 350px) {
        margin-left: 5px;
    }
`;

export const GoogleBtn = styled('button')`
    cursor: pointer;
    padding: 15px 40px;
    border-radius: 40px;
    outline: none;
    border: 2px solid #e9f1f6;
    font-size: 22px;
    line-height: 31px;
    display: flex;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media (max-width: 768px) {
        padding: 8px 22px;
    }
    @media (max-width: 576px) {
        font-size: 18px;
        line-height: 20px;
        padding: 5px 18px;
    }
    @media (max-width: 476px) {
        font-size: 16px;
        margin: 0px 4px;
    }
`;
