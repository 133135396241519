import {
    EFocusSessionsTypes,
    IFocusSessionsState,
    TFocusSessionsActionTypes,
} from 'models';

const initialState: IFocusSessionsState = {
    calendar: undefined,
    sessions: undefined,
    stats: undefined,
    favouritesSessions: undefined,
    focus: '',
    leaderBoard: undefined,
    editingFocusSession: undefined,
    pastSessions: undefined,
    session: undefined,
    isFocusSessionModalOpen: false,
    mySessionsByDate: undefined,
    isDeleteFocusSessionModalOpen: false,
    calendarCurrentDate: { mySessions: new Date() },
    isReviewSessionGuidelinesOpen: false,
    chooseRepeatSessionUpdateType: {
        open: false,
        type: 'THIS_SESSION',
        sessionData: undefined,
    },
    isCalendarFullscreen: false,
};

const focusSessionsReducer = (
    state = initialState,
    action: TFocusSessionsActionTypes,
): IFocusSessionsState => {
    switch (action.type) {
        case EFocusSessionsTypes.SET_FOCUS_SESSIONS: {
            return {
                ...state,
            };
        }
        case EFocusSessionsTypes.FOCUS_SESSIONS_OPEN_REVIEW_SESSIONS_GUIDELINES: {
            return {
                ...state,
                isReviewSessionGuidelinesOpen: action.payload,
            };
        }
        case EFocusSessionsTypes.FOCUS_SESSIONS_SET_MY_SESSIONS_CURRENT_DATE: {
            return {
                ...state,
                calendarCurrentDate: {
                    mySessions: action.payload.date,
                },
            };
        }

        case EFocusSessionsTypes.SET_FOCUS_SESSIONS_STATS: {
            return {
                ...state,
                stats: {
                    ...action.payload,
                },
            };
        }

        case EFocusSessionsTypes.SET_FOCUS_SESSIONS_MY_FOCUS: {
            return {
                ...state,
                focus: action.payload,
            };
        }

        case EFocusSessionsTypes.SET_FOCUS_SESSIONS_LEADERBOARD: {
            return {
                ...state,
                leaderBoard: action.payload,
            };
        }

        case EFocusSessionsTypes.SET_FOCUS_SESSIONS_ME: {
            return {
                ...state,
                sessions: {
                    meta: action.payload.sessions.meta
                        ? action.payload.sessions.meta
                        : (state?.sessions?.meta as any),
                    results: action.payload.reset
                        ? action.payload.sessions.results
                        : state?.sessions?.results?.concat(
                            action.payload.sessions.results || [],
                        ) || [],
                },
            };
        }

        case EFocusSessionsTypes.SET_FOCUS_SESSION_ACTION: {
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    results: state.sessions?.results ? state.sessions?.results.filter(s => s.id !== action.payload.session.id).concat(action.payload.session) : [action.payload.session]
                }
            }
        }

        case EFocusSessionsTypes.SET_CALENDAR_FOCUS_SESSION_ACTION: {
            const calendarSession = state.calendar?.findIndex(
                el => el.id === action.payload.session.id,
            );

            if (calendarSession !== -1 && typeof calendarSession !== 'undefined' && state.calendar) {
                const oldCalendarSessions = [...state.calendar];
                oldCalendarSessions[calendarSession] = action.payload.session;

                return {
                    ...state,
                    calendar: oldCalendarSessions
                }
            }

            return {
                ...state,
            }
        }

        case EFocusSessionsTypes.SET_FOCUS_SESSIONS_FAVOURITES_ACTION: {
            return {
                ...state,
                favouritesSessions: {
                    meta: action.payload.sessions.meta
                        ? action.payload.sessions.meta
                        : (state?.sessions?.meta as any),
                    results: action.payload.reset
                        ? action.payload.sessions.results
                        : state?.sessions?.results?.concat(
                            action.payload.sessions.results || [],
                        ) || [],
                },
            };
        }

        case EFocusSessionsTypes.SET_EDITING_FOCUS_SESSION: {
            return {
                ...state,
                editingFocusSession: action.payload.session,
            };
        }

        case EFocusSessionsTypes.SET_FOCUS_SESSIONS_BY_DATE: {
            return {
                ...state,
                calendar: action.payload.calendar,
            };
        }

        case EFocusSessionsTypes.SET_FOCUS_SESSIONS_ME_PAST: {
            return {
                ...state,
                pastSessions: {
                    meta: action.payload.sessions.meta
                        ? action.payload.sessions.meta
                        : (state?.sessions?.meta as any),
                    results: action.payload.reset
                        ? action.payload.sessions.results
                        : state?.sessions?.results?.concat(
                            action.payload.sessions.results || [],
                        ) || [],
                },
            };
        }

        case EFocusSessionsTypes.SET_FOCUS_SESSION: {
            return {
                ...state,
                session: action.payload.session,
            };
        }

        case EFocusSessionsTypes.SET_MY_FOCUS_SESSIONS_BY_DATE: {
            return {
                ...state,
                mySessionsByDate: action.payload.mySessionsByDate,
            };
        }

        case EFocusSessionsTypes.OPEN_FOCUS_SESSION_MODAL: {
            return {
                ...state,
                isFocusSessionModalOpen: action.payload,
            };
        }

        case EFocusSessionsTypes.OPEN_DELETE_FOCUS_SESSION_MODAL: {
            return {
                ...state,
                isDeleteFocusSessionModalOpen: action.payload,
            };
        }

        case EFocusSessionsTypes.CHOOSE_REPEAT_SESSION_UPDATE_TYPE: {
            return {
                ...state,
                chooseRepeatSessionUpdateType: action.payload,
            };
        }

        case EFocusSessionsTypes.SET_IS_CALENDAR_FULLSCREEN_ACTION: {
            return {
                ...state,
                isCalendarFullscreen: action.payload,
            };
        }

        default:
            return state;
    }
};

export default focusSessionsReducer;
