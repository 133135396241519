import { AxiosInstance, AxiosStatic, AxiosError } from 'axios';
import axiosRetry from 'axios-retry';
import i18n from 'locales/i18n';

import {
    setAppLastRequestsFailedAction,
    showToastAction,
    store,
    logoutActionSaga,
} from 'store';

export const retryInstance = (instance: AxiosStatic | AxiosInstance) => {
    const showToast = ({ title, subTitle }: { title: string; subTitle: string }) =>
        store.dispatch(
            showToastAction({
                open: true,
                vertical: 'top',
                horizontal: 'right',
                title: title,
                subTitle: subTitle,
                icon: 'error',
            }),
        );
    return axiosRetry(instance, {
        retries: 5,
        retryDelay: (retryCount: number) => {
            console.log(`retry attempt: ${retryCount}`);
            return retryCount * 2500;
        },

        onRetry: async (retryCount: number, error: AxiosError) => {
            // console.log(AppService.appHeathCheck());
            if (
                (error.config.method?.toLowerCase() === 'post' ||
                    error.config.method?.toLowerCase() === 'patch' ||
                    error.config.method?.toLowerCase() === 'delete') &&
                (retryCount === 1 || retryCount === 5)
            ) {
                showToast({
                    title:
                        retryCount === 1
                            ? i18n.t(
                                'axios.youMayExperienceLimitedFunctionality',
                            )
                            : i18n.t('axios.lastRetryAttempt'),
                    subTitle:
                        retryCount === 1
                            ? i18n.t(
                                'axios.looksLikeWeReHavingTroubleConnectingToTheFocus101Servers',
                            )
                            : i18n.t(
                                'axios.weAreTryingOneLastTimeGettingTheDataFromFocus101Servers',
                            ),
                });
            } else if (
                error.config.method?.toLowerCase() === 'get' &&
                (retryCount === 2 || retryCount === 5)
            ) {
                showToast({
                    title:
                        retryCount === 2
                            ? i18n.t(
                                'axios.youMayExperienceLimitedFunctionality',
                            )
                            : i18n.t('axios.lastRetryAttempt'),
                    subTitle:
                        retryCount === 2
                            ? i18n.t(
                                'axios.looksLikeWeReHavingTroubleConnectingToTheFocus101Servers',
                            )
                            : i18n.t(
                                'axios.weAreTryingOneLastTimeGettingTheDataFromFocus101Servers',
                            ),
                });
            }
            if (retryCount === 5) {
                try {
                    if (error?.config?.url)
                        store.dispatch(
                            setAppLastRequestsFailedAction(error.config.url),
                        );
                    // const {data} = await axios.get(
                    //     process.env.REACT_APP_URL + '',
                    // );
                } catch (error) {
                    console.log({ error });
                }
            }
        },
        retryCondition: (error: AxiosError) => {
            console.log({ RetryCondition: error });
            if (!error) {
                store.dispatch(logoutActionSaga());
                return false;
            }
            // if retry condition is not specified, by default idempotent requests are retried
            const errorStatus = error?.response?.status;

            if (
                errorStatus === 401 &&
                error?.response?.data?.detail?.includes('token')
            ) {
                store.dispatch(logoutActionSaga());
                return false;
            }

            return (
                errorStatus !== 200 &&
                errorStatus !== 201 &&
                errorStatus !== 400 &&
                errorStatus !== 401 &&
                errorStatus !== 404 &&
                errorStatus !== 403
            );
        },
    });
};
