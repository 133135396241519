import {ITodoState, TTodoActionTypes, ETodoTypes} from 'models';

const initialState: ITodoState = {
    data: undefined,
    createOrEditModalOpened: '',
    editableTodo: undefined,
    finishPomodoroRoundEarly: false,
    pickNewTaskWhileOneRunning: {pickNew: false, task: undefined},
    isPomodoroTimerOn: {
        running: false,
        timeLeft: {
            pomodoro: 1500000,
            shortBreak: 300000,
            longBreak: 900000,
        },
    },
    settings: {pomodoro: 1500000, shortBreak: 300000, longBreak: 900000},
    currentTask: undefined,
    noOfPomodorosFinished: 0,
    activePomodoroTab: 'POMODORO',
    showPomodoroWidget: false,
};

const todoReducer = (
    state = initialState,
    action: TTodoActionTypes,
): ITodoState => {
    switch (action.type) {
        case ETodoTypes.SET_TODOS: {
            return {
                ...state,
                data:
                    state?.data && !action.payload.reset
                        ? state.data.concat([action.payload.todos])
                        : action.payload.todos,
            };
        }

        case ETodoTypes.OPEN_CREATE_OR_EDIT_MODAL: {
            return {
                ...state,
                createOrEditModalOpened: action.payload.witch,
            };
        }

        case ETodoTypes.SET_POMODORO_SETTINGS: {
            return {
                ...state,
                settings: {
                    ...action.payload,
                },
            };
        }

        case ETodoTypes.SET_EDITABLE_TODO: {
            return {
                ...state,
                editableTodo: action.payload.todo,
            };
        }

        case ETodoTypes.FINISH_POMODORO_ROUND_EARLY: {
            return {
                ...state,
                finishPomodoroRoundEarly: action.payload,
            };
        }

        case ETodoTypes.PICK_NEW_TASK_WHILE_ONE_RUNNING: {
            return {
                ...state,
                pickNewTaskWhileOneRunning: {
                    pickNew: action.payload.pickNew,
                    task: action.payload?.task,
                },
            };
        }

        case ETodoTypes.PRESS_POMODORO_TIMER: {
            return {
                ...state,
                isPomodoroTimerOn: {
                    ...state.isPomodoroTimerOn,
                    running: action.payload.running
                        ? action.payload.running
                        : state.isPomodoroTimerOn.running,
                    timeLeft: {
                        pomodoro: action.payload?.timeLeft?.pomodoro
                            ? action.payload?.timeLeft?.pomodoro
                            : state?.isPomodoroTimerOn?.timeLeft?.pomodoro,
                        shortBreak: action.payload?.timeLeft?.shortBreak
                            ? action.payload?.timeLeft?.shortBreak
                            : state?.isPomodoroTimerOn?.timeLeft?.shortBreak,
                        longBreak: action.payload?.timeLeft?.longBreak
                            ? action.payload?.timeLeft?.longBreak
                            : state?.isPomodoroTimerOn?.timeLeft?.longBreak,
                    },
                },
            };
        }

        case ETodoTypes.SET_POMODORO_CURRENT_TASK: {
            return {
                ...state,
                currentTask: action.payload.todo,
            };
        }

        case ETodoTypes.SET_NUMBER_OF_TODOS_FINISHED: {
            return {
                ...state,
                noOfPomodorosFinished: action.payload,
            };
        }

        case ETodoTypes.SET_ACTIVE_POMODORO_TAB: {
            return {
                ...state,
                activePomodoroTab: action.payload,
            };
        }

        case ETodoTypes.SET_SHOW_POMODORO_WIDGET: {
            return {
                ...state,
                showPomodoroWidget: action.payload,
            };
        }

        default:
            return state;
    }
};

export default todoReducer;
