import {useState, useEffect} from 'react';

const getWidth = () =>
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

export const useCurrentWidth = () => {
    // save current window width in the state object
    let [width, setWidth] = useState<number>(getWidth());

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
        const resizeListener: EventListenerOrEventListenerObject = () => {
            setWidth(getWidth());
        };
        // set resize listener
        window.addEventListener('resize', resizeListener);

        // clean up function
        return (): void => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener);
        };
    }, []);

    return width;
};
