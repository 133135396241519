import { ECallTypes, ICall, IPublicRooms } from 'models';

export const setCallInitialValuesAction = (payload: {
    initialConnectionValue: ICall;
}) => ({
    type: ECallTypes.SET_JOIN_ROOM_INITIAL_VALUES,
    payload,
});

export const setCallReceivedResolution = (
    payload: 'Auto' | '720' | '360' | 'audio_only',
) => ({
    type: ECallTypes.SET_CALL_RECEIVED_RESOLUTION,
    payload,
});

export const setCallSendedResolution = (payload: 'Auto' | '720' | '360') => ({
    type: ECallTypes.SET_CALL_SENDED_RESOLUTION,
    payload,
});

export const resetCallValuesAction = () => ({
    type: ECallTypes.RESET_CALL_VALUES,
});

export const setCallMicrophoneEnabledAction = (payload: boolean) => ({
    type: ECallTypes.SET_CALL_MICROPHONE_ENABLED,
    payload,
});

export const setCallCameraEnabledAction = (payload: boolean) => ({
    type: ECallTypes.SET_CALL_CAMERA_ENABLED,
    payload,
});

export const setCallIsAboutToEnd = (payload: boolean) => ({
    type: ECallTypes.SET_CALL_IS_ABOUT_TO_END_ACTION,
    payload,
});

export const setCallBeforeSessionStartsAction = (payload: {
    show: boolean;
    sessionId: number;
}) => ({
    type: ECallTypes.SET_BEFORE_SESSION_STARTS_ACTION,
    payload,
});

export const setHelpPopoverOpenAction = (
    payload: 'report' | 'review' | '',
) => ({
    type: ECallTypes.SET_OPEN_HELP_POPOVER_ACTION,
    payload,
});

export const setCallVideoBackgroundAction = (payload: {
    background:
    | {
        type: 'virtual-background' | 'background-blur';
        value: string | number;
    }
    | undefined;
}) => ({
    type: ECallTypes.SET_CALL_VIDEO_BACKGROUND_ACTION,
    payload,
});


export const setPublicRoomsAction = (payload: IPublicRooms[]) => ({
    type: ECallTypes.SET_PUBLIC_ROOMS,
    payload
})


export const setWSPublicRoomsAction = (payload: IPublicRooms) => ({
    type: ECallTypes.WS_SET_PUBLIC_ROOM,
    payload
})

export const setCallNoiseSuppressionAction = (payload: boolean) => ({
    type: ECallTypes.SET_CALL_NOISE_SUPPRESSION_ACTION,
    payload
})

export const setCallVideoDeviceId = (payload: {
    deviceId: string
}) => ({
    type: ECallTypes.SET_CALL_VIDEO_DEVICE_ID_ACTION,
    payload
})

export const setCallAudioDeviceId = (payload: {
    deviceId: string
}) => ({
    type: ECallTypes.SET_CALL_AUDIO_DEVICE_ID_ACTION,
    payload
})