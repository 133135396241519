import {AxiosResponse} from 'axios';

import tokenInstance from 'utils/axiosInstance/tokenInstance';

const getCompanyByCompanyId = ({
    companyId,
}: {
    companyId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/companies/${companyId}`);
};

const postCompany = ({
    company_name,
    address: {
        name,
        locality,
        phone_number,
        contact_email,
        postal_code,
        state_or_county,
        street_address_1,
        street_address_2,
    },
}: {
    company_name: string;
    address: {
        name: string;

        locality: string;
        phone_number: string;
        contact_email: string;
        postal_code: string;
        state_or_county: string;
        street_address_1: string;
        street_address_2: string;
    };
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`/companies/`, {
        name: company_name,
        address: {
            name,
            street_address_1,
            locality,
            phone_number,
            contact_email,
            postal_code,
            state_or_county,
            street_address_2,
        },
    });
};

const getCompanyRoomsByCompanyId = ({
    companyId,
}: {
    companyId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/companies/${companyId}/rooms/`);
};

const getCompanySettings = ({
    companyId,
}: {
    companyId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/companies/${companyId}/settings`);
};

const patchCompanySettings = ({
    companyId,
    auto_join_domain,
}: {
    companyId: number;
    auto_join_domain: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`/companies/${companyId}/settings`, {
        auto_join_domain,
    });
};

const patchCompanyByCompanyId = ({
    companyId,
    company_name,
    address: {
        name,
        locality,
        phone_number,
        contact_email,
        postal_code,
        state_or_county,
        street_address_1,
        street_address_2,
    },
}: {
    companyId: number;
    company_name: string;
    address: {
        name: string;
        locality: string;
        phone_number: string;
        contact_email: string;
        postal_code: string;
        state_or_county: string;
        street_address_1: string;
        street_address_2: string;
    };
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`/companies/${companyId}`, {
        name: company_name,
        address: {
            name,
            street_address_1,
            locality,
            phone_number,
            contact_email,
            postal_code,
            state_or_county,
            street_address_2,
        },
    });
};

const postCompanyRoomByCompanyId = ({
    companyId,
    name,
}: {
    companyId: number;
    name: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`/companies/${companyId}/rooms/`, {name});
};

const patchCompanyRoomByCompanyId = ({
    companyId,
    name,
    roomId,
}: {
    companyId: number;
    name: string;
    roomId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`/companies/${companyId}/rooms/${roomId}`, {
        name,
    });
};
const postCompanyInvite = ({
    companyId,
    name,
    email,
    admin,
}: {
    companyId: number;
    name: string | undefined;
    email: string;
    admin?: boolean;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`/companies/${companyId}/members/invites/`, {
        name,
        email,
        admin,
    });
};

const getCompanyInvites = ({
    companyId,
}: {
    companyId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/companies/${companyId}/members/invites/`);
};

const postCompanyMemberById = ({
    companyId,
    member_id,
    admin,
}: {
    companyId: number;
    member_id: string;
    admin?: boolean;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`/companies/${companyId}/members/`, {
        user_id: member_id,
        admin,
    });
};

const getCompanyMembers = ({
    companyId,
}: {
    companyId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/companies/${companyId}/members/`);
};

const patchCompanyMember = ({
    companyId,
    name,
    email,
    admin,
    member_id,
}: {
    companyId: number;
    name?: string;
    email?: string;
    admin?: boolean;
    member_id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(
        `/companies/${companyId}/members/${member_id}`,
        {
            name,
            email,
            admin,
        },
    );
};

const deleteCompanyMember = ({
    companyId,
    member_id,
}: {
    companyId: number;
    member_id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(
        `/companies/${companyId}/members/${member_id}`,
    );
};

const postReInviteUser = ({
    companyId,
    invite_id,
}: {
    companyId: number;
    invite_id: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(
        `/companies/${companyId}/members/invites/${invite_id}/re-invite`,
    );
};

const getInvite = ({
    companyId,
    invite_id,
}: {
    companyId: number;
    invite_id: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(
        `/companies/${companyId}/members/invites/${invite_id}`,
    );
};

const postUploadCompanyAvatar = ({
    logo,
    companyId,
}: {
    logo: File | string;
    companyId: number;
}): Promise<AxiosResponse<any, any>> => {
    const formData = new FormData();
    formData.append('logo', logo as File);
    return tokenInstance().post(`/companies/${companyId}/logo`, formData);
};

export const CompanyService = {
    getCompanyByCompanyId,
    patchCompanyByCompanyId,
    getCompanyRoomsByCompanyId,
    postCompanyRoomByCompanyId,
    postCompanyInvite,
    patchCompanyMember,
    deleteCompanyMember,
    postCompanyMemberById,
    getCompanyInvites,
    getCompanyMembers,
    patchCompanyRoomByCompanyId,
    postReInviteUser,
    getInvite,
    postUploadCompanyAvatar,
    postCompany,
    getCompanySettings,
    patchCompanySettings,
};
