export const emailValidator = (email: any) => {
    const re = /\S+@\S+\.\S+/;

    if (!email || email.length <= 0) return 'Email is required.';
    if (!re.test(email)) return 'Wrong Email';

    return '';
};

export const passwordValidator = (password: any) => {
    if (!password || password.length <= 0) return 'Password is required.';
    if (password.length < 8) return 'min 8 character required';

    return '';
};
