/* eslint-disable no-multi-str */
import {useState, memo} from 'react';
import {Box, Button, Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {ReusableModalWindow} from 'components';
import {ReactComponent as Check} from 'assets/images/check.svg';
import {ReactComponent as RedX} from 'assets/images/redX.svg';
import styles from './styles.module.scss';

interface IReviewSessionGuidelines {
    setOpen: (event: any) => void;
    open: boolean;
}

export const ReviewSessionGuidelines: React.FunctionComponent<IReviewSessionGuidelines> =
    memo(({open, setOpen}) => {
        const [activeItem, setActiveItem] = useState<number>(0);
        const {t} = useTranslation();

        return (
            <ReusableModalWindow
                setOpen={setOpen}
                open={open}
                height={650}
                width={'auto'}
                alignItems={'flex-start'}>
                <Typography
                    variant="h1"
                    component="h2"
                    className={styles.title}>
                    {activeItem < 2
                        ? t('reviewSessionGuidelines.howToGetTheBestResults')
                        : t('reviewSessionGuidelines.aFewFinalTips')}
                </Typography>
                <Typography
                    variant="h1"
                    component="h2"
                    className={styles.subTitle}>
                    {t(
                        'reviewSessionGuidelines.toGetTheMostAndCreateAGrateCommunity',
                    )}
                </Typography>
                <Box className={styles.instructions}>
                    {activeItem !== 2 && (
                        <Box className={styles.instructionsTitleBox}>
                            {activeItem === 0 ? (
                                <Check style={{marginTop: 5}} />
                            ) : (
                                <RedX style={{marginTop: 5}} />
                            )}
                            <Typography
                                variant="h1"
                                component="h2"
                                className={styles.instructionsTitle}>
                                {activeItem === 0
                                    ? t('reviewSessionGuidelines.iWill')
                                    : activeItem === 1
                                    ? t('reviewSessionGuidelines.iWont')
                                    : ''}
                            </Typography>
                        </Box>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                        }}>
                        {(activeItem === 1 || activeItem === 0) && (
                            <Box
                                className={styles.instructionsTitleBoxChildren}>
                                {activeItem === 1 ? (
                                    <RedX
                                        style={{marginTop: 5}}
                                        className={styles.svgs}
                                    />
                                ) : activeItem === 0 ? (
                                    <Check
                                        style={{marginTop: 5}}
                                        className={styles.svgs}
                                    />
                                ) : (
                                    <></>
                                )}
                                <Typography
                                    variant="h1"
                                    component="h2"
                                    className={
                                        styles.instructionsTitleChildren
                                    }>
                                    {activeItem === 0
                                        ? t(
                                              'reviewSessionGuidelines.bePoliteAndRespectful',
                                          )
                                        : activeItem === 1
                                        ? t(
                                              'reviewSessionGuidelines.askForContactDetails',
                                          )
                                        : t(
                                              'reviewSessionGuidelines.startConversationDuringTheSession',
                                          )}
                                </Typography>
                            </Box>
                        )}
                        <Box className={styles.instructionsTitleBoxChildren}>
                            {activeItem === 1 ? (
                                <RedX
                                    style={{marginTop: 5}}
                                    className={styles.svgs}
                                />
                            ) : (
                                <Check
                                    style={{marginTop: 5}}
                                    className={styles.svgs}
                                />
                            )}
                            <Typography
                                variant="h1"
                                component="h2"
                                className={styles.instructionsTitleChildren}>
                                {activeItem === 0
                                    ? t(
                                          'reviewSessionGuidelines.atTheBeggingOfTheSession',
                                      )
                                    : activeItem === 1
                                    ? t(
                                          'reviewSessionGuidelines.startConversationDuringTheSession',
                                      )
                                    : t(
                                          'reviewSessionGuidelines.hideYourPhone',
                                      )}
                            </Typography>
                        </Box>
                        <Box className={styles.instructionsTitleBoxChildren}>
                            {activeItem === 1 ? (
                                <RedX
                                    style={{marginTop: 5}}
                                    className={styles.svgs}
                                />
                            ) : (
                                <Check
                                    style={{marginTop: 5}}
                                    className={styles.svgs}
                                />
                            )}
                            <Typography
                                variant="h1"
                                component="h2"
                                className={styles.instructionsTitleChildren}>
                                {activeItem === 0
                                    ? t(
                                          'reviewSessionGuidelines.preferablyKeepMyMicOn',
                                      )
                                    : activeItem === 1
                                    ? t(
                                          'reviewSessionGuidelines.socializeWithOthersAroundMe',
                                      )
                                    : t(
                                          'reviewSessionGuidelines.writeYourGoalInTheSessionTitle',
                                      )}
                            </Typography>
                        </Box>
                        <Box className={styles.instructionsTitleBoxChildren}>
                            {activeItem === 1 ? (
                                <RedX
                                    style={{marginTop: 5}}
                                    className={styles.svgs}
                                />
                            ) : (
                                <Check
                                    style={{marginTop: 5}}
                                    className={styles.svgs}
                                />
                            )}
                            <Typography
                                variant="h1"
                                component="h2"
                                className={styles.instructionsTitleChildren}>
                                {activeItem === 0
                                    ? t(
                                          'reviewSessionGuidelines.workOnTheTasksIveDeclared',
                                      )
                                    : activeItem === 1
                                    ? t(
                                          'reviewSessionGuidelines.getUpAndDisappear',
                                      )
                                    : t(
                                          'reviewSessionGuidelines.keepThingsShort',
                                      )}
                            </Typography>
                        </Box>
                        <Box className={styles.instructionsTitleBoxChildren}>
                            {activeItem === 1 ? (
                                <RedX
                                    style={{marginTop: 5}}
                                    className={styles.svgs}
                                />
                            ) : (
                                <Check
                                    style={{marginTop: 5}}
                                    className={styles.svgs}
                                />
                            )}

                            <Typography
                                variant="h1"
                                component="h2"
                                className={styles.instructionsTitleChildren}>
                                {activeItem === 0
                                    ? t(
                                          'reviewSessionGuidelines.sayGoodByeAtTheEnd',
                                      )
                                    : activeItem === 1
                                    ? t('reviewSessionGuidelines.leaveEarly')
                                    : t(
                                          'reviewSessionGuidelines.keepYourMicrophoneTurnedOn',
                                      )}
                            </Typography>
                        </Box>
                        {(activeItem === 1 || activeItem === 2) && (
                            <Box
                                className={styles.instructionsTitleBoxChildren}>
                                {activeItem === 1 ? (
                                    <RedX
                                        style={{marginTop: 5}}
                                        className={styles.svgs}
                                    />
                                ) : (
                                    <Check
                                        style={{marginTop: 5}}
                                        className={styles.svgs}
                                    />
                                )}

                                <Typography
                                    variant="h1"
                                    component="h2"
                                    className={
                                        styles.instructionsTitleChildren
                                    }>
                                    {activeItem === 1
                                        ? t(
                                              'reviewSessionGuidelines.takeScreenshotsOrRecordingsOfMySession',
                                          )
                                        : activeItem === 2
                                        ? t(
                                              'reviewSessionGuidelines.announceABioBreak',
                                          )
                                        : ''}
                                </Typography>
                            </Box>
                        )}
                        {activeItem === 2 && (
                            <Box
                                className={styles.instructionsTitleBoxChildren}>
                                <Check
                                    style={{marginTop: 5}}
                                    className={styles.svgs}
                                />

                                <Typography
                                    variant="h1"
                                    component="h2"
                                    className={
                                        styles.instructionsTitleChildren
                                    }>
                                    {t(
                                        'reviewSessionGuidelines.smileAndBeFriendly',
                                    )}
                                </Typography>
                            </Box>
                        )}
                    </Box>
                </Box>
                <Box className={styles.controls}>
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 1}}>
                        <Box
                            onClick={() => setActiveItem(0)}
                            sx={{cursor: 'pointer'}}
                            className={
                                activeItem === 0
                                    ? styles.pagination
                                    : styles.inactivePagination
                            }></Box>
                        <Box
                            onClick={() => setActiveItem(1)}
                            sx={{cursor: 'pointer'}}
                            className={
                                activeItem === 1
                                    ? styles.pagination
                                    : styles.inactivePagination
                            }></Box>
                        <Box
                            onClick={() => setActiveItem(2)}
                            sx={{cursor: 'pointer'}}
                            className={
                                activeItem === 2
                                    ? styles.pagination
                                    : styles.inactivePagination
                            }></Box>
                    </Box>
                    <Box sx={{gap: 1, display: 'flex'}}>
                        {activeItem > 0 && (
                            <Button
                                className={styles.backButton}
                                onClick={() =>
                                    setActiveItem((prev: number) => --prev)
                                }>
                                {t('reviewSessionGuidelines.back')}
                            </Button>
                        )}
                        <Button
                            className={styles.nextButton}
                            onClick={() => {
                                if (activeItem < 2) {
                                    setActiveItem((prev: number) => ++prev);
                                } else {
                                    setOpen(false);
                                    setActiveItem(0);
                                }
                            }}>
                            {activeItem !== 2
                                ? t('reviewSessionGuidelines.next')
                                : t('reviewSessionGuidelines.iAmReady')}
                        </Button>
                    </Box>
                </Box>
            </ReusableModalWindow>
        );
    });
