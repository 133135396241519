import { styled } from "@mui/material/styles";
import { Images } from "../../assets/images";

export const BackgroundImage = styled('div')(
	({ theme }) => `
		background-image: url('${Images.background}');
		background-size: cover;
		height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	`,
);
