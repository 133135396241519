import {
    ISetWhiteSoundPlayingAction,
    IIncreasePlayerTimePlayed,
    ISetWhiteSoundsAction,
    ISetWhiteSoundVolumesAction,
    ISetWhiteSoundAction,
} from 'models';

export enum EMusicPlayerTypes {
    INCREASE_PLAYER_TIME_PLAYED = 'INCREASE/PLAYER/TIME/PLAYED',
    GET_WHITE_SOUNDS_SAGA = 'GET/WHITE/SOUNDS/SAGA',
    SET_WHITE_SOUNDS = 'SET/WHITE/SOUNDS',
    INCREASE_WHITE_SOUND_PLAYED_SAGA = 'INCREASE/WHITE/SOUND/PLAYED/SAGA',
    SET_VOLUME_VALUE = 'SET/VOLUME/VALUE',
    SET_WHITE_SOUND = 'SET/WHITE/SOUND',
    SET_WHITE_SOUND_PLAYING = 'SET/WHITE/SOUND/PLAYING',
}

export interface IWhiteSounds {
    id: number;
    name: string;
    image: string;
    file: string;
    length: number;
    plays: number;
    description: string;
    updated_at: Date;
    updated_by: number | null;
    created_at: Date;
    created_by: number;
}

export interface IMusicPlayer {
    whiteSounds?: IWhiteSounds[];
    data: {
        time: number;
        volume: number;
        whiteSound?: IWhiteSounds;
        isPlaying: {
            state: boolean;
            lastPlayedSongUrl: string;
            currentSong: string;
        };
    };
}

export type TMusicPlayerActionTypes =
    | IIncreasePlayerTimePlayed
    | ISetWhiteSoundsAction
    | ISetWhiteSoundVolumesAction
    | ISetWhiteSoundAction
    | ISetWhiteSoundPlayingAction;
