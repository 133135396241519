import {memo, useState, useCallback, useMemo} from 'react';
import {
    Box,
    Button,
    Typography,
    CircularProgress,
    TextField,
    Avatar,
} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {createSelector} from 'reselect';
import moment from 'moment-timezone';

import {ReusableModalWindow} from 'components';
import styles from './styles.module.scss';
import {
    selectFocusSessions,
    useAppSelector,
    selectAuthUserTimezone,
    selectAuthUserId,
    selectAuthUserDatetimePreference,
} from 'store';
import {newDateWithTimezone} from 'utils';
import {
    IFocusSessionParticipant,
    ISession,
} from '../../models/focusSessions/types';

const stateSelectorHandle = createSelector(
    selectFocusSessions,
    selectAuthUserTimezone,
    selectAuthUserId,
    selectAuthUserDatetimePreference,
    (
        focusSessions,
        authUserTimezone,
        authUserId,
        authUserDatetimePreference,
    ) => {
        return {
            focusSessions,
            authUserTimezone,
            authUserId,
            authUserDatetimePreference,
        };
    },
);

export const ConfirmBookSession = memo(
    ({
        setOpen,
        open,
        loading,
        bookSession,
        cancelBookSession,
        session,
    }: {
        setOpen: () => void;
        open: boolean;
        loading: boolean;
        bookSession: ({title}: {title: string}) => void;
        cancelBookSession: () => void;
        session: {show: boolean; event?: any};
    }) => {
        const {t} = useTranslation();
        const [partnerSessionTitle, setPartnerSessionTitle] =
            useState<string>('');
        const stateSelector = useCallback(stateSelectorHandle, []);
        const {
            focusSessions,
            authUserTimezone,
            authUserId,
            authUserDatetimePreference,
        } = useAppSelector(stateSelector);

        /**
         * @param {Date} date
         * @return string
         */
        const humanDate = (date: Date) => {
            return moment(date).format(
                `${authUserDatetimePreference?.date} ${authUserDatetimePreference?.time}`,
            );
        };

        const overlappingSessions = useMemo(() => {
            if (!session?.event) return [];

            if (
                session?.event?.extendedProps?.participants?.length &&
                session?.event?.extendedProps?.participants?.find(
                    (p: IFocusSessionParticipant) => p.id === authUserId,
                )?.id
            )
                return [];

            if (
                authUserTimezone &&
                focusSessions &&
                focusSessions?.results?.length > 0
            ) {
                let s: ISession[] = [];

                const newRecordStart = moment(session.event.start_at).tz(
                    authUserTimezone,
                );
                const newRecordEnd = moment(session.event.end_at).tz(
                    authUserTimezone,
                );

                for (const record of focusSessions?.results) {
                    const recordStart = moment(record.start_at).tz(
                        authUserTimezone,
                    );
                    const recordEnd = moment(record.end_at).tz(
                        authUserTimezone,
                    );

                    if (
                        (newRecordStart.isBetween(recordStart, recordEnd) ||
                            newRecordEnd.isBetween(recordStart, recordEnd) ||
                            newRecordStart.isSame(recordStart) ||
                            newRecordEnd.isSame(recordEnd)) &&
                        newRecordStart.isSameOrBefore(recordEnd) &&
                        moment().toDate().getTime() <
                            newRecordStart.toDate().getTime()
                    ) {
                        s.push(record);
                    }
                }

                return s;
            }
            return [];
        }, [authUserId, authUserTimezone, focusSessions, session.event]);

        return (
            <ReusableModalWindow
                open={open}
                height={'auto'}
                width={517}
                setOpen={setOpen}
                alignItems="flex-start">
                <Typography className={styles.title}>
                    {t('home.calendar.info')}
                </Typography>
                <Typography sx={{my: 1}} className={styles.subTitle}>
                    {t('home.calendar.bookSessionSure')}
                </Typography>
                {overlappingSessions.length > 0 && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 1,
                        }}>
                        <Typography sx={{mb: 1}}>
                            {t(
                                'home.calendar.theFallowingSessionsOverlapsWithThisOne',
                            )}
                        </Typography>
                        {overlappingSessions.map((session, index) => {
                            const {date: sessionStartDate} =
                                newDateWithTimezone({
                                    currentDate: new Date(
                                        session.start_at as Date,
                                    ),
                                    timezone: authUserTimezone as string,
                                });

                            return (
                                <Box
                                    key={`${session.id}-${index}`}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                    className={styles.session}>
                                    <Typography className={styles.snackTitle}>
                                        {authUserId &&
                                        session?.title &&
                                        session?.title[authUserId.toString()]
                                            ? session?.title[
                                                  authUserId.toString()
                                              ]
                                            : session?.title &&
                                              session?.title[
                                                  session.created_by.toString()
                                              ]
                                            ? session?.title[
                                                  session.created_by.toString()
                                              ]
                                            : session.participants?.length
                                            ? session?.title[
                                                  session.participants[0].id.toString()
                                              ]
                                            : ''}
                                    </Typography>
                                    <Typography
                                        className={styles.sessionPeriod}>
                                        {`${humanDate(sessionStartDate)}
                                            ${t('home.for')} ${
                                            session.duration.toString() === '60'
                                                ? '1 h'
                                                : `${session.duration} m`
                                        }`}
                                    </Typography>
                                    {session.participants.map((part, index) => {
                                        return (
                                            <Box
                                                key={`${part.id}-${index}`}
                                                sx={{
                                                    display: 'flex',
                                                    gap: 1,
                                                    alignItems: 'center',
                                                    mt: 0.5,
                                                }}>
                                                <Avatar
                                                    sx={{
                                                        height: 20,
                                                        width: 20,
                                                    }}
                                                    src={
                                                        part.avatar ||
                                                        '/broken-img.jpg'
                                                    }
                                                />
                                                <Typography
                                                    className={
                                                        styles.participantFullName
                                                    }>
                                                    {part.first_name}
                                                    {part.last_name_initial}.
                                                </Typography>
                                            </Box>
                                        );
                                    })}
                                </Box>
                            );
                        })}
                    </Box>
                )}
                <TextField
                    fullWidth
                    label={t('home.calendar.whatIsYourGoal')}
                    inputProps={{
                        className: styles.input,
                    }}
                    value={partnerSessionTitle}
                    onChange={event =>
                        setPartnerSessionTitle(event.target.value)
                    }
                    variant="standard"
                />
                <Box className={styles.actionButtonsBox} sx={{mt: 2}}>
                    <Button
                        variant="contained"
                        disabled={loading}
                        className={styles.yesImSure}
                        onClick={() =>
                            bookSession({title: partnerSessionTitle})
                        }>
                        {loading ? (
                            <CircularProgress
                                size={15}
                                sx={{color: '#FFFFFF'}}
                            />
                        ) : (
                            t('home.calendar.yesImSure')
                        )}
                    </Button>
                    <Button
                        variant="outlined"
                        className={styles.cancel}
                        onClick={cancelBookSession}>
                        {t('home.calendar.cancel')}
                    </Button>
                </Box>
            </ReusableModalWindow>
        );
    },
);
