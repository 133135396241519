import {Typography} from '@mui/material';
import {useTranslation} from 'react-i18next';

import Background from 'components/Logo';
import {CardContainer, CardInnerContent} from 'styles/common/auth';

export const ErrorBoundaries = () => {
    const {t} = useTranslation();
    return (
        <Background>
            <CardContainer style={{width: '100%', height: 200}}>
                <CardInnerContent>
                    <Typography
                        style={{color: '--theme-text-dark-color'}}
                        sx={{
                            fontFamily: 'Proxima Nova',
                            fontSize: {xs: 19, md: 24},
                            textAlign: 'center',
                            fontWeight: 'bold',
                        }}>
                        {t('error.ohNo')}
                    </Typography>
                    <Typography
                        style={{color: '--theme-text-dark-color'}}
                        sx={{
                            fontFamily: 'Brandon Grotesque',
                            fontSize: 20,
                            textAlign: 'center',
                        }}>
                        {t('error.please')}{' '}
                        <Typography
                            component={'span'}
                            sx={{
                                textDecoration: 'underline',
                                cursor: 'pointer',
                                fontFamily: 'Proxima Nova',
                                fontSize: {xs: 17, md: 20},
                                textAlign: 'center',
                                color: 'rgb(255, 154, 0)',
                            }}
                            onClick={() => window.location.reload()}>
                            {t('error.here')}
                        </Typography>{' '}
                        {t('error.toReloadThePage')}
                    </Typography>
                </CardInnerContent>
            </CardContainer>
        </Background>
    );
};
