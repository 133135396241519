import {AxiosResponse} from 'axios';

import tokenInstance from 'utils/axiosInstance/tokenInstance';

const handleGetRoomToken = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('/rooms/temp-get-token');
};

const getPrivateRooms = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('/rooms/private/');
};

const getPublicRooms = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('/rooms/public/');
};

const postPublicRooms = ({
    name,
}: {
    name: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('/rooms/public/', {name});
};

const patchPublicRooms = ({
    name,
    roomId,
}: {
    name: string;
    roomId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`/rooms/public/${roomId}`, {name});
};

const patchPrivateRooms = ({
    name,
    roomId,
}: {
    name: string;
    roomId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`/rooms/private/${roomId}`, {name});
};

const postPrivateRooms = ({
    name,
}: {
    name: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('/rooms/private/', {name});
};

export const getCompanyLobbyRoomToken = ({
    roomId,
    companyId,
}: {
    roomId: string;
    companyId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(
        `companies/${companyId}/rooms/${roomId}/lobby-token`,
    );
};

export const getPrivateLobbyRoomToken = ({
    roomId,
}: {
    roomId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/rooms/private/${roomId}/lobby-token`);
};

export const getPublicLobbyRoomToken = ({
    roomId,
}: {
    roomId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/rooms/public/${roomId}/lobby-token`);
};

export const getPrivateRoomsToken = ({
    roomId,
}: {
    roomId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/rooms/private/${roomId}/token`);
};

export const getPublicRoomsToken = ({
    roomId,
}: {
    roomId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/rooms/public/${roomId}/token`);
};

export const getCompanyRoomsToken = ({
    roomId,
    companyId,
}: {
    roomId: string;
    companyId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`companies/${companyId}/rooms/${roomId}/token`);
};

export const deleteCompanyRoom = ({
    roomId,
    companyId,
}: {
    roomId: string;
    companyId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`companies/${companyId}/rooms/${roomId}`);
};

export const deletePrivateRoom = ({
    roomId,
}: {
    roomId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`rooms/private/${roomId}`);
};

export const deletePublicRoom = ({
    roomId,
}: {
    roomId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`rooms/public/${roomId}`);
};

export const patchCompanyRoom = ({
    roomId,
    companyId,
    name,
}: {
    roomId: string;
    companyId: string;
    name: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`companies/${companyId}/rooms/${roomId}`, {
        name,
    });
};

export const getBreakoutRoomToken = ({
    roomId,
    companyId,
    name,
}: {
    roomId: string;
    companyId: string;
    name: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(
        `companies/${companyId}/rooms/${roomId}/breakout/${name}/token`,
    );
};

// * Single ROOM SESSIONS
export const getFocusSessionsToken = ({
    sessionID,
}: {
    sessionID: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/focus-sessions/${sessionID}/token`);
};

export const getFocusSessionsLobbyToken = ({
    sessionID,
}: {
    sessionID: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/focus-sessions/${sessionID}/lobby-token`);
};
// * Single ROOM SESSIONS

// * Meetings ROOM
// * Only used by guest user
export const getMeetingTokenWithMeetingUUIDAndPasscode = ({
    meetingUUID,
    passcode,
    uuid,
}: {
    meetingUUID: string;
    passcode: string;
    uuid: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(
        `/meetings/${meetingUUID}/guest-token?passcode=${passcode}&uuid=${uuid}`,
    );
};

export const getMeetingLobbyTokenWithMeetingUUIDAndPasscode = ({
    meetingUUID,
    passcode,
}: {
    meetingUUID: string;
    passcode: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(
        `/meetings/${meetingUUID}/guest-lobby-token?passcode=${passcode}`,
    );
};

export const getMeetingLobbyToken = ({
    meetingUUID,
}: {
    meetingUUID: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/meetings/${meetingUUID}/lobby-token`);
};

export const getTestLobbyToken = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/focus-sessions/test-session/lobby-token`);
};

export const getTestRoomToken = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/focus-sessions/test-session/token`);
};

// * Used by the host
export const getMeetingToken = ({
    meetingUUID,
}: {
    meetingUUID: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/meetings/${meetingUUID}/token`);
};

// * Meeting ROOM

export const RoomsService = {
    handleGetRoomToken,
    getPrivateRooms,
    getPublicRooms,
    postPublicRooms,
    postPrivateRooms,
    getCompanyLobbyRoomToken,
    getCompanyRoomsToken,
    deleteCompanyRoom,
    patchCompanyRoom,
    getPrivateLobbyRoomToken,
    getPublicLobbyRoomToken,
    getPrivateRoomsToken,
    getPublicRoomsToken,
    deletePrivateRoom,
    deletePublicRoom,
    patchPublicRooms,
    patchPrivateRooms,
    getBreakoutRoomToken,
    getFocusSessionsToken,
    getFocusSessionsLobbyToken,
    getMeetingTokenWithMeetingUUIDAndPasscode,
    getMeetingToken,
    getMeetingLobbyTokenWithMeetingUUIDAndPasscode,
    getMeetingLobbyToken,
    getTestLobbyToken,
    getTestRoomToken,
};
