import { EAuthTypes, IAuthUserState, TAuthActionTypes } from 'models/auth/types';

const initialState: IAuthUserState = {
    data: {
        expiry: undefined,
        token: undefined,
        user: {
            profile_complete: false,
            company_membership: undefined,
            profile: {
                dark_mode: 'system',
                language: 'en',
            },
        },
        favourites: undefined,
    },
    temp: undefined,
    isWelcomeVideoOpen: false,
    tourCurrentStep: undefined,
};

const reducer = (
    state = initialState,
    action: TAuthActionTypes,
): IAuthUserState => {
    switch (action.type) {
        case EAuthTypes.AUTH_SET_TOUR_CURRENT_STEP: {
            return {
                ...state,
                tourCurrentStep: action.payload,
            };
        }

        case EAuthTypes.SET_AUTH_USER_IS_PREMIUM_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        is_premium: action.payload
                    }
                }
            }
        }

        case EAuthTypes.AUTH_USER_SET_PROFILE_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        profile: action.payload,
                    },
                },
            };
        }
        case EAuthTypes.AUTH_SET_COMPANY_ADDRESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        company_membership: {
                            ...state.data.user?.company_membership,
                            id: state.data.user?.company_membership
                                ?.id as number,
                            name: state.data.user?.company_membership
                                ?.name as string,
                            logo: state.data.user?.company_membership
                                ?.logo as string,
                            updated_at: state.data.user?.company_membership
                                ?.updated_at as Date,
                            admin: !!state.data.user?.company_membership?.admin,
                            address: action.payload,
                        },
                        profile: {
                            ...state.data.user?.profile,
                        },
                    },
                },
            };
        }
        case EAuthTypes.AUTH_LOGIN_ACTION_SET: {
            return {
                ...state,
                data: {
                    ...action.payload,
                },
            };
        }

        case EAuthTypes.AUTH_SET_USER: {
            return {
                ...state,
                data: {
                    ...state.data,
                    user: {
                        ...state.data?.user,
                        ...action.payload,
                        company_membership: {
                            ...state.data.user?.company_membership,
                        },
                        profile: {
                            ...state.data.user?.profile,
                        },
                    },
                },
            };
        }
        case EAuthTypes.AUTH_SET_USER_AVATAR: {
            return {
                ...state,
                data: {
                    ...state.data,
                    user: {
                        ...state.data?.user,
                        avatar: action.payload.avatar,
                    },
                },
            };
        }

        case EAuthTypes.SET_AUTH_USER_FAVOURITES: {
            return {
                ...state,
                data: {
                    ...state.data,
                    favourites: action.payload.favourites,
                },
            };
        }

        case EAuthTypes.SET_AUTH_USER_REGISTERED_PASSWORD: {
            return {
                ...state,
                temp: {
                    password: action.payload?.password,
                },
            };
        }

        case EAuthTypes.SET_IS_WELCOME_VIDEO_OPEN: {
            return {
                ...state,
                isWelcomeVideoOpen: action.payload,
            };
        }

        case EAuthTypes.AUTH_SHOW_NEW_SESSION_MODAL_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                    user: {
                        ...state.data.user,
                        profile: {
                            ...state.data.user?.profile,
                            show_new_session_modal: action.payload,
                        },
                    },
                },
            };
        }

        default:
            return state;
    }
};

export default reducer;
