import {memo, useMemo} from 'react';
import {Typography, Box} from '@mui/material';

export const WidgetRenderTimer = memo(
    ({
        completed,
        minutes,
        seconds,
    }: {
        completed: boolean;
        minutes: number;
        seconds: number;
    }) => {
        const memorize = useMemo(() => {
            if (completed) {
                return <></>;
            }

            return (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%',
                        marginLeft: '20px',
                    }}>
                    <Box
                        sx={{
                            width: '80px',
                            display: 'flex',
                        }}>
                        <Typography
                            variant="subtitle2"
                            component="p"
                            sx={{
                                fontFamily: 'Brandon Grotesque',
                                fontStyle: 'normal',
                                fontWeight: 500,
                                fontSize: '28px',
                                color: 'rgba(0, 0, 0, 0.85)',
                                textAlign: 'center',
                            }}>
                            {minutes < 10 ? '0' : ''}
                            {minutes}:{seconds < 10 ? '0' : ''}
                            {seconds}
                        </Typography>
                    </Box>
                </Box>
            );
        }, [completed, minutes, seconds]);

        return memorize;
    },
);
