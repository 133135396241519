import {EMusicPlayerTypes, IWhiteSounds} from 'models';

export const increasePlayerTimePlayedAction = (payload: number) => ({
    type: EMusicPlayerTypes.INCREASE_PLAYER_TIME_PLAYED,
    payload,
});

export const getWhiteSoundsSaga = (payload: {
    callbackOnSuccess: () => void;
    callbackOnError: () => void;
}) => ({
    type: EMusicPlayerTypes.GET_WHITE_SOUNDS_SAGA,
    payload,
});

export const setWhiteSoundsAction = (payload: IWhiteSounds[]) => ({
    type: EMusicPlayerTypes.SET_WHITE_SOUNDS,
    payload,
});

export const increaseWhiteSoundPlayedSaga = (payload: {
    whiteSoundId: number;
    callbackOnSuccess?: () => void;
    callbackOnError?: () => void;
}) => ({
    type: EMusicPlayerTypes.INCREASE_WHITE_SOUND_PLAYED_SAGA,
    payload,
});

export const setWhiteSoundsVolume = (payload: number) => ({
    type: EMusicPlayerTypes.SET_VOLUME_VALUE,
    payload,
});

export const setWhiteSoundAction = (payload: IWhiteSounds | undefined) => ({
    type: EMusicPlayerTypes.SET_WHITE_SOUND,
    payload,
});

export const setWhiteSoundPlayingAction = (payload: {
    state: boolean;
    lastPlayedSongUrl: string;
    currentSong: string;
}) => ({
    type: EMusicPlayerTypes.SET_WHITE_SOUND_PLAYING,
    payload,
});
