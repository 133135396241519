import {CircularProgress} from '@mui/material';

export const SuspenseSpinner = () => {
    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <CircularProgress color="primary" />
        </div>
    );
};
