import {memo} from 'react';
import {Box, Tooltip} from '@mui/material';

import {Images} from 'assets';

export const SubscriptionIcon = memo(
    ({
        productId,
        width = 40,
        height = 40,
        showTooltip = true,
    }: {
        productId: string;
        width?: number;
        height?: number;
        showTooltip?: boolean;
    }) => {
        if (productId === process.env.REACT_APP_PREMIUM_PRODUCT_ID)
            return showTooltip ? (
                <Tooltip placement="top" title="Focus101 Premium">
                    <Box
                        component={'img'}
                        src={Images.Crown}
                        sx={{width, height, objectFit: 'contain'}}
                    />
                </Tooltip>
            ) : (
                <Box
                    component={'img'}
                    src={Images.Crown}
                    sx={{width, height, objectFit: 'contain'}}
                />
            );

        if (productId === process.env.REACT_APP_BUSINESS_PRODUCT_ID)
            return null;

        return null;
    },
);
