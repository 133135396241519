import { IUserSetAction } from './actions';

export enum EUserTypes {
    POST_USERS_IGNORE_SAGA = 'POST/USERS/IGNORE/SAGA',
    DELETE_USER_IGNORE_SAGA = 'DELETE/USER/IGNORE/SAGA',
    GET_USER_ACTION_SAGA = 'GET/USER/ACTION/SAGA',
    USER_ACTION_SET = 'USER/ACTION/SET',
}

export interface IOtherUser {
    avatar: string;
    created_at: Date;
    first_name: string;
    gender: string | null;
    last_name: string;
    last_name_initial: string;
    my_focus: string;
    profile_url: string;
    rating: number;
    timezone: string;
    completed_sessions: number;
    user_id: number;
    is_premium: boolean;
}
export interface IUserState {
    data: IOtherUser | undefined;
}

export type TUserActionTypes = IUserSetAction;
