import { TStore } from 'models';

export const selectCallData = (state: TStore) => state?.call?.data;

export const selectCallReceivedResolution = (state: TStore) =>
    state?.call?.data?.video?.receivedResolution;

export const selectCallSendedResolution = (state: TStore) =>
    state?.call?.data?.video?.sendResolution;

export const selectCallIsAboutToEnd = (state: TStore) =>
    state?.call?.data?.callIsAboutToEnd;

export const selectCallBeforeSessionStarts = (state: TStore) =>
    state?.call?.data?.beforeSessionStarts;

export const selectHelpPopoverOpen = (state: TStore) =>
    state?.call?.data?.helpPopoverOpen;

export const selectCallBackground = (state: TStore) =>
    state?.call?.data?.video?.background;

export const selectPublicRooms = (state: TStore) =>
    state?.call?.data?.publicRooms;

export const selectCallNoiseSuppression = (state: TStore) => state?.call?.data?.audio?.noiseSuppression

export const selectCallVideoDeviceId = (state: TStore) =>
    state?.call?.data?.video?.deviceId;

export const selectCallAudioDeviceId = (state: TStore) =>
    state?.call?.data?.audio?.deviceId;