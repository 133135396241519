import {EAppTypes, IAppState, TAppActionTypes} from 'models';

const initialState: IAppState = {
    lastRequestsFailed: [],
    data: {
        timezones: undefined,
    },
    loading: false,
    appVisibility: {
        isVisible: true,
        visibilityState: 'visible',
    },
};

const appReducer = (
    state = initialState,
    action: TAppActionTypes,
): IAppState => {
    switch (action.type) {
        case EAppTypes.APP_META_DATA_TIMEZONES_SET: {
            return {
                ...state,
                data: {
                    timezones: action.payload,
                },
            };
        }
        case EAppTypes.APP_LAST_REQUEST_FAILED_SET_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                },
                lastRequestsFailed: state.lastRequestsFailed
                    ? state.lastRequestsFailed.concat(action.payload)
                    : [action.payload],
            };
        }
        case EAppTypes.APP_LOADING_ACTION: {
            return {
                ...state,
                data: {
                    ...state.data,
                },
                loading: action.payload,
            };
        }
        case EAppTypes.APP_SET_VISIBILITY_ACTION: {
            return {
                ...state,
                appVisibility: action.payload,
            };
        }

        default:
            return state;
    }
};

export default appReducer;
