import { AxiosResponse } from 'axios';

import tokenInstance from 'utils/axiosInstance/tokenInstance';

/**
 * @param {({
 *     currency: 'usd' | 'gbp' | 'eur',
 *     amount_cents: number,
 *     payment_method_type: 'card'
 * })} {
 *     
 *     currency,
 *     amount_cents,
 *     payment_method_type
 * }
 * @return {*}  {Promise<AxiosResponse<any, any>>}
 */
const postCreatePaymentIntent = ({

    currency,
    amount_cents,
    payment_method_type
}: {
    currency: 'usd' | 'gbp' | 'eur',
    amount_cents: number,
    payment_method_type: 'card'
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('payments/create-payment-intent', {
        currency,
        amount_cents,
        payment_method_type
    });
};

/**
 * @param {({
 *     currency: 'usd' | 'gbp' | 'eur',
 *     amount_cents: number,
 *     product_id: string,
 *     recurring_interval: 'month' | 'year'
 * })} {
 *     
 *     currency,
 *     amount_cents,
 *     product_id,
 *     recurring_interval
 * }
 * @return {*}  {Promise<AxiosResponse<any, any>>}
 */
const postCreateSubscription = ({

    currency,
    amount_cents,
    product_id,
    recurring_interval
}: {
    currency: 'usd' | 'gbp' | 'eur' | 'aud',
    amount_cents: number,
    product_id: string,
    recurring_interval: 'month' | 'year'
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('payments/subscriptions/', {
        currency,
        amount_cents,
        product_id,
        recurring_interval
    });
};


export const getSubscriptions = ({ status }: { status: 'all' | 'incomplete' | 'incomplete_expired' | 'trialing' | 'active' | 'past_due' | "canceled" | 'unpaid' }): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`payments/subscriptions/?status=${status}`);
};

export const getSubscription = ({
    subscriptionId
}: {
    subscriptionId: string
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('payments/subscriptions/' + subscriptionId);
};

export const getProducts = ({ currency, interval }: { currency: 'eur' | 'usd' | 'gbp', interval: 'month' | 'year' }): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`payments/products?currency=${currency}&interval=${interval}`);
};

export const deleteSubscription = ({
    subscriptionId
}: {
    subscriptionId: string
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete('payments/subscriptions/' + subscriptionId);
};


export const getInvoices = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('payments/invoices/');
};

export const getPaymentMethods = (): Promise<AxiosResponse<any, {
    id: string;
    type: 'acss_debit' | 'affirm' | 'afterpay_clearpay' | 'alipay' | 'au_becs_debit' | 'bacs_debit' | 'bancontact' | 'blik' | 'boleto' | 'card' | 'card_present' | 'cashapp' | 'customer_balance' | 'eps' | 'fpx' | 'giropay' | 'grabpay' | 'ideal' | 'interac_present' | 'klarna' | 'konbini' | 'link' | 'oxxo' | 'p24' | 'paynow' | 'paypal' | 'pix' | 'promptpay' | 'revolut_pay' | 'sepa_debit' | 'sofort' | 'us_bank_account' | 'wechat_pay' | 'zip'
}>> => {
    return tokenInstance().get('payments/payment-methods/');
};

export const postSetCustomerPaymentMethodDefault = ({
    payment_method_id
}: {
    payment_method_id: string
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('payments/customers/payment-methods/set-default', {
        payment_method_id
    });
};

export const postSetSubscriptionsPaymentMethodDefault = ({
    payment_method_id,
    subscriptionId
}: {
    payment_method_id: string;
    subscriptionId: string
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`payments/subscriptions/${subscriptionId}/set-default-payment-method`, {
        payment_method_id
    });
};

export const deletePaymentMethod = ({
    payment_method_id
}: {
    payment_method_id: string
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`payments/payment-methods/${payment_method_id}/detach`);
};

export const postPaymentsCreateSetupIntent = ({
    payment_method_type
}: {
    payment_method_type: 'acss_debit' | 'affirm' | 'afterpay_clearpay' | 'alipay' | 'au_becs_debit' | 'bacs_debit' | 'bancontact' | 'blik' | 'boleto' | 'card' | 'card_present' | 'cashapp' | 'customer_balance' | 'eps' | 'fpx' | 'giropay' | 'grabpay' | 'ideal' | 'interac_present' | 'klarna' | 'konbini' | 'link' | 'oxxo' | 'p24' | 'paynow' | 'paypal' | 'pix' | 'promptpay' | 'revolut_pay' | 'sepa_debit' | 'sofort' | 'us_bank_account' | 'wechat_pay' | 'zip'
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`payments/setup-intent`, {
        payment_method_type
    });
};

export const patchPaymentsUpdateSubscription = ({
    subscriptionId,
    currency,
    default_payment_method,
    recurring_interval,
    unit_amount,
    product_id
}: {
    subscriptionId: string;
    currency: 'usd' | 'eur' | 'gbp' | 'aud'
    default_payment_method: string;
    recurring_interval: 'month' | 'year',
    unit_amount: string,
    product_id: string
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`payments/subscriptions/${subscriptionId}`, {
        currency,
        default_payment_method,
        recurring_interval,
        unit_amount,
        product_id
    });
};

export const PaymentsService = {
    postCreatePaymentIntent,
    postCreateSubscription,
    getSubscriptions,
    getProducts,
    deleteSubscription,
    getSubscription,
    getInvoices,
    getPaymentMethods,
    postSetCustomerPaymentMethodDefault,
    deletePaymentMethod,
    postSetSubscriptionsPaymentMethodDefault,
    postPaymentsCreateSetupIntent,
    patchPaymentsUpdateSubscription
}