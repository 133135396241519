/* eslint-disable eqeqeq */
export const getBrowser = () => {
    let browser:
        | 'Opera'
        | 'Edge'
        | 'Chrome'
        | 'Safari'
        | 'Firefox'
        | 'IE'
        | 'unknown';

    if (
        (navigator.userAgent.indexOf('Opera') ||
            navigator.userAgent.indexOf('OPR')) != -1
    ) {
        browser = 'Opera';
        return browser;
    } else if (navigator.userAgent.indexOf('Edg') != -1) {
        browser = 'Edge';
        return browser;
    } else if (navigator.userAgent.indexOf('Chrome') != -1) {
        browser = 'Chrome';
        return browser;
    } else if (navigator.userAgent.indexOf('Safari') != -1) {
        browser = 'Safari';
        return browser;
    } else if (navigator.userAgent.indexOf('Firefox') != -1) {
        browser = 'Firefox';
        return browser;
    } else if (
        navigator.userAgent.indexOf('MSIE') != -1 ||
        // @ts-ignore
        !!document.documentMode == true
    ) {
        //IF IE > 10
        browser = 'IE';
        return browser;
    } else {
        browser = 'unknown';
        return browser;
    }
};
