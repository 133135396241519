import {memo, useCallback, useEffect, Dispatch, SetStateAction} from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import {createSelector} from 'reselect';

import styles from './styles.module.scss';
import {ReactComponent as X} from 'assets/images/x.svg';
import {selectAuthUserProfileDarkMode, useAppSelector} from 'store';

const stateSelectorHandle = createSelector(
    selectAuthUserProfileDarkMode,
    appMode => {
        return {appMode};
    },
);

export const ReusableModalWindow = memo(
    ({
        open = false,
        setOpen,
        children,
        alignItems,
        height,
        width,
        closeOption = true,
        top = '50%',
        bottom,
        horizontalPadding = true,
        disableEscapeKeyDown = false,
        setInitialized,
    }: {
        open: boolean;
        setOpen: (state: boolean) => void;
        children: any;
        alignItems?: string;
        height?: string | number;
        width?: string | number;
        closeOption?: boolean;
        top?: string;
        bottom?: string | number;
        horizontalPadding?: boolean;
        disableEscapeKeyDown?: boolean;
        setInitialized?: Dispatch<SetStateAction<boolean>>;
    }) => {
        const stateSelector = useCallback(stateSelectorHandle, []);
        const {appMode} = useAppSelector(stateSelector);

        const handleClose = (
            event: {},
            reason: 'backdropClick' | 'escapeKeyDown',
        ) => {
            if (reason === 'backdropClick' && !disableEscapeKeyDown) {
                setOpen(false);
            }
        };
        useEffect(() => {
            if (setInitialized) setInitialized(true);
        }, [setInitialized]);

        return (
            <Modal
                sx={{zIndex: 1300}}
                open={open}
                onClose={handleClose}
                disableEscapeKeyDown={disableEscapeKeyDown}
                aria-labelledby="focus101"
                aria-describedby="focus101-action-modal">
                <Box
                    id="focus101-action-modal"
                    sx={{
                        overflow: 'auto',
                        position: 'absolute' as 'absolute',
                        top: top ? top : '50%',
                        bottom: bottom ? bottom : '',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: {
                            xs: '95%',
                            md: width ? width : '60%',
                            lg: width ? width : 650,
                        },
                        height: {
                            xs:
                                parseFloat((height as string) ?? '0') + 100 >
                                window.innerHeight
                                    ? '85%'
                                    : height,
                            md: height ? height : '80%',
                            lg: height ? height : 750,
                        },
                        maxHeight: '95%',
                        background: appMode === 'dark' ? '#1b1e24' : '#FFFFFF',
                        boxShadow: '4px 0px 38px 3px rgba(0, 0, 0, 0.13)',
                        borderRadius: 2.5,
                        py: 4,
                        px: horizontalPadding ? {xs: 3, md: 4} : 0,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: alignItems ? alignItems : 'center',
                        justifyContent: 'flex-start',
                        '&:focus-visible': {
                            outline: 'none',
                        },
                    }}>
                    {closeOption && (
                        <Box className={styles.xBox}>
                            <X
                                className={styles.x}
                                fill={
                                    appMode === 'dark'
                                        ? '#cbcbcb'
                                        : 'rgba(0, 0, 0, 0.85)'
                                }
                                onClick={() => setOpen(false)}
                            />
                        </Box>
                    )}
                    {children}
                </Box>
            </Modal>
        );
    },
);
