import {
    memo,
    Dispatch,
    SetStateAction,
    useEffect,
    useCallback,
    useState,
    useMemo,
} from 'react';
import {Controller} from 'react-hook-form';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    Stack,
    Typography,
    useMediaQuery,
} from '@mui/material';
import {useForm, SubmitHandler} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as yup from 'yup';
import {createSelector} from 'reselect';
import {useTranslation} from 'react-i18next';

import {ReusableModalWindow, TimezoneAutocomplete} from 'components';
import {
    useAppDispatch,
    appGetTimezonesActionSaga,
    useAppSelector,
    updateAuthUserSaga,
} from 'store';
import {selectAppTimezones} from 'store/selectors/app';
import {selectAuthUserTimezone} from 'store/selectors/authUser';
import styles from './styles.module.scss';

const stateSelectorHandle = createSelector(
    selectAppTimezones,
    selectAuthUserTimezone,
    (appTimezones, authUserTimezone) => {
        return {appTimezones, authUserTimezone};
    },
);

export const TimezoneChange = memo(
    ({
        setOpen,
        open,
    }: {
        setOpen: Dispatch<SetStateAction<boolean>>;
        open: boolean;
    }) => {
        const dispatch = useAppDispatch();
        const stateSelector = useCallback(stateSelectorHandle, []);
        const {appTimezones, authUserTimezone} = useAppSelector(stateSelector);
        const matches = useMediaQuery('(min-width:567px)');
        const [timezoneLoading, setTimezoneLoading] = useState<boolean>(true);
        const [loading, setLoading] = useState<boolean>(false);
        const {t} = useTranslation();
        const [chooseTimezone, setChooseTimezone] = useState<boolean>(false);
        const currentTimeZone =
            Intl.DateTimeFormat().resolvedOptions().timeZone;

        const schema = useMemo(
            () =>
                yup
                    .object({
                        timezone: yup
                            .string()
                            .oneOf(
                                appTimezones ?? [],
                                t(
                                    'timezoneChange.theChosenTimezoneIsNotAvailable',
                                ),
                            )
                            .required(t('timezoneChange.timezoneIsRequired')),
                    })
                    .required(),
            [appTimezones, t],
        );

        const {
            handleSubmit,
            control,
            setValue,
            setError: setHookError,
            formState: {errors: formErrors},
        } = useForm<{
            timezone: string;
        }>({
            resolver: yupResolver(schema),
            defaultValues: {
                timezone:
                    authUserTimezone ||
                    Intl.DateTimeFormat().resolvedOptions().timeZone,
            },
        });

        const close = () => {
            dispatch(
                updateAuthUserSaga({
                    data: {
                        user: {
                            timezone:
                                authUserTimezone ||
                                Intl.DateTimeFormat().resolvedOptions()
                                    .timeZone,
                            timezone_verified: new Date(
                                new Date().setDate(new Date().getDate() + 3),
                            ),
                        },
                    },
                    callbackOnSuccess: () => {
                        setOpen(false);
                    },
                    callbackOnError: () => {},
                }),
            );
        };

        const saveTimezone: SubmitHandler<{timezone: string}> = useCallback(
            (data: {timezone: string}) => {
                setLoading(true);
                dispatch(
                    updateAuthUserSaga({
                        data: {
                            user: {
                                timezone: data.timezone,
                                timezone_verified: new Date(
                                    new Date().setDate(
                                        new Date().getDate() + 3,
                                    ),
                                ),
                            },
                        },
                        callbackOnSuccess: () => {
                            setLoading(false);
                            setOpen(false);
                        },
                        callbackOnError: () => {
                            setLoading(false);
                        },
                    }),
                );
            },
            [dispatch, setOpen],
        );

        useEffect(() => {
            dispatch(
                appGetTimezonesActionSaga({
                    callback: () => setTimezoneLoading(false),
                }),
            );
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return (
            <ReusableModalWindow
                setOpen={close}
                open={open}
                disableEscapeKeyDown={true}
                height={!matches ? '50%' : 400}
                alignItems={'flex-start'}>
                <form
                    onSubmit={handleSubmit(saveTimezone)}
                    style={{height: '100%'}}>
                    <Box sx={{position: 'relative', height: '100%'}}>
                        <Typography
                            className={styles.title}
                            variant="h6"
                            component="h6">
                            {t('timezoneChange.timezoneIssue')}
                        </Typography>
                        <br />
                        <Typography
                            className={styles.subtitle}
                            variant="subtitle1"
                            component="sub">
                            {t('timezoneChange.info')}
                        </Typography>
                        <br />
                        <Box>
                            <Typography
                                className={styles.subtitle}
                                variant="subtitle1"
                                component="sub">
                                {t('timezoneChange.timezoneSetTo')}
                            </Typography>
                            <Typography
                                className={styles.currentTimezone}
                                variant="subtitle1"
                                component="sub">
                                {' '}
                                {authUserTimezone}
                            </Typography>
                            <Typography
                                className={styles.subtitle}
                                variant="subtitle1"
                                component="sub">
                                {t('timezoneChange.butComputerTimezoneIs')}
                            </Typography>
                            <Typography
                                className={styles.currentTimezone}
                                variant="subtitle1"
                                component="sub">
                                {' '}
                                {
                                    Intl.DateTimeFormat().resolvedOptions()
                                        .timeZone
                                }
                                .
                            </Typography>
                        </Box>
                        {formErrors?.timezone?.message && (
                            <>
                                <br />
                                <Typography
                                    className={styles.currentTimezone}
                                    style={{color: '#F14437'}}
                                    variant="subtitle1"
                                    component="sub">
                                    {formErrors?.timezone?.message}
                                </Typography>
                            </>
                        )}
                        {chooseTimezone && (
                            <Controller
                                name="timezone"
                                control={control}
                                render={({field: {onChange, value}}) => (
                                    <FormControl
                                        variant="standard"
                                        className="select-TimeZone">
                                        <Stack sx={{mt: 2}}>
                                            <TimezoneAutocomplete
                                                appTimezones={
                                                    appTimezones ?? []
                                                }
                                                timezoneLoading={
                                                    timezoneLoading
                                                }
                                                authUser={{
                                                    user: {
                                                        timezone:
                                                            authUserTimezone ||
                                                            Intl.DateTimeFormat().resolvedOptions()
                                                                .timeZone,
                                                    },
                                                }}
                                                onChange={onChange}
                                                setHookError={setHookError}
                                                formErrors={formErrors}
                                                value={value}
                                            />
                                        </Stack>
                                    </FormControl>
                                )}
                            />
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                position: 'absolute',
                                bottom: 0,
                                width: '100%',
                                flexDirection: {xs: 'column', sm: 'row'},
                            }}>
                            <Button
                                disabled={loading}
                                type="submit"
                                onClick={() => {
                                    if (!chooseTimezone) {
                                        setValue(
                                            'timezone',
                                            Intl.DateTimeFormat().resolvedOptions()
                                                .timeZone,
                                        );
                                    }
                                }}
                                variant={'contained'}
                                className={styles.setDefault}>
                                {loading ? (
                                    <CircularProgress size={15} />
                                ) : !chooseTimezone ? (
                                    ` ${t('timezoneChange.setTimezone', {
                                        timezone: currentTimeZone,
                                    })}`
                                ) : (
                                    t('common.save')
                                )}
                            </Button>
                            <Button
                                onClick={() => setChooseTimezone(prev => !prev)}
                                disabled={loading}
                                variant={'outlined'}
                                className={styles.chooseTimezone}>
                                {loading ? (
                                    <CircularProgress size={15} />
                                ) : !chooseTimezone ? (
                                    t('timezoneChange.chooseDifferent')
                                ) : (
                                    t('common.cancel')
                                )}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </ReusableModalWindow>
        );
    },
);
