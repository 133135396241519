import { ISetSubscriptionAction, ISetSubscriptionDefaultPaymentMethodAction } from "./actions"

export enum EPaymentsTypes {
    SET_SUBSCRIPTION_ACTION = 'SET/SUBSCRIPTION/ACTION',
    GET_SUBSCRIPTION_ACTION = 'GET/SUBSCRIPTION/ACTION',
    SET_DEFAULT_PAYMENT_METHOD = 'SET/DEFAULT/PAYMENT/METHOD'
}

export interface IPaymentsState {
    data: {
        subscription?: ISubscription
    };
}

export type TPaymentsActionTypes = ISetSubscriptionAction | ISetSubscriptionDefaultPaymentMethodAction

export type TPaymentMethodDetails = {
    brand: string;
    checks: {
        address_line1_check: string | null;
        address_postal_code_check: string | null;
        cvc_check: string;
    };
    country: string;
    exp_month: number;
    exp_year: number;
    fingerprint: string;
    funding: string;
    generated_from: string | null;
    last4: string;
    networks: { available: string[]; preferred: string | null };
    three_d_secure_usage: { supported: boolean };
    wallet: {} | null;
    payer_email: string;
    payer_id: string;
}

export interface IPaymentMethods {
    payment_method_details: TPaymentMethodDetails;
    type: string;
    id: string;
}

export interface ISubscription {
    created: string;
    currency: string;
    current_period_end: string;
    current_period_start: string;
    days_until_due: null | string;
    default_payment_method: string;
    id: string;
    status: string
    product: {
        unit_amount: number;
        unit_amount_decimal: string;
        recurring: {
            interval: string,
            interval_count: number
        },
        name: string,
        id: string
    }
}

export interface ICurrencyData {
    [currencyCode: string]: {
        month: number;
        year: number;
    };
}

export interface IProduct {
    active: boolean;
    description: string | null;
    id: string;
    name: string;
    price: {
        id: string;
        currency: string;
        recurring: {
            interval: string;
            usage_type: string;
            interval_count: number;
            aggregate_usage: string | null;
            trial_period_days: string | null;
        };
        unit_amount: string;
        unit_amount_decimal: string;
    }[];
    min_price: ICurrencyData[];
    features: { name: string }[]
}