import {EWSActionTypes, IWSState, TWSActionTypes} from 'models';

const initialState: IWSState = {
    data: {
        reconnectInterval: 2000, //* it's 2000 because the first attempt to reconnect is instant.
        reconnectAttempts: 0,
    },
    events: undefined,
    lastEvent: undefined,
};

const wsReducer = (state = initialState, action: TWSActionTypes): IWSState => {
    switch (action.type) {
        case EWSActionTypes.SET_WS_RECONNECT_INTERVAL: {
            return {
                ...state,
                data: {
                    ...state.data,
                    reconnectInterval: action.payload.interval,
                },
            };
        }

        case EWSActionTypes.SET_WS_RECONNECT_ATTEMPTS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    reconnectAttempts: action.payload.number,
                },
            };
        }

        case EWSActionTypes.SET_WS_EVENT: {
            const data = initialState?.events
                ? initialState?.events?.concat(action.payload.event)
                : [action.payload.event];

            return {
                ...state,
                events: {
                    ...state.events,
                    ...data,
                },
                lastEvent: action.payload.event,
            };
        }

        default:
            return state;
    }
};

export default wsReducer;
