import { applyMiddleware, createStore, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import localStorage from 'redux-persist/lib/storage';
// import {composeWithDevTools} from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import * as Sentry from '@sentry/react';
import logger from 'redux-logger';
import createCompressor from 'redux-persist-transform-compress';
import {
    createWhitelistFilter,
    createBlacklistFilter,
} from 'redux-persist-transform-filter';

import { rootReducer } from './reducers';
import sagas from './sagas';
import history from '../utils/createHistory';

// ** Conditional encryptTransform for redux-persist
const persistTransforms =
    process.env.REACT_APP_STAGE === 'production'
        ? {
            transforms: [
                createWhitelistFilter('app', ['language']),
                createWhitelistFilter('focusSessions', [
                    'focus',
                    'leaderboard',
                ]),
                createBlacklistFilter('todo', [
                    'data',
                    'createOrEditModalOpened',
                    'finishPomodoroRoundEarly',
                ]),
                createBlacklistFilter('musicPlayer', [
                    'whiteSounds',
                    'data.time',
                    'data.whiteSound',
                    'data.isPlaying',
                ]),
                createBlacklistFilter('call', [
                    'data.publicRooms',
                ]),
                createCompressor(),
                encryptTransform({
                    secretKey: process.env
                        .REACT_APP_REDUX_PERSIST_ENCRYPTION_KEY as string,
                    onError: function (error) {
                        console.log({ encryptTransform: error });
                    },
                }),
            ],
        }
        : {
            transforms: [
                createWhitelistFilter('app', ['language']),
                createWhitelistFilter('focusSessions', [
                    'focus',
                    'leaderboard',
                ]),
                createBlacklistFilter('todo', [
                    'data',
                    'createOrEditModalOpened',
                    'finishPomodoroRoundEarly',
                ]),
                createBlacklistFilter('musicPlayer', [
                    'whiteSounds',
                    'data.time',
                    'data.whiteSound',
                    'data.isPlaying',
                ]),
                createBlacklistFilter('call', [
                    'data.publicRooms',
                ]),
            ],
        };

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
    // Optionally pass options listed below
});

// ** Persist keys
const persistConfig = {
    key: 'root',
    whitelist: ['auth', 'focusSessions', 'call', 'todo', 'musicPlayer', 'app'],
    storage: localStorage,
    ...persistTransforms,
};

// ** Create Redux Saga Middleware
const sagaMiddleware = createSagaMiddleware({
    onError: (err: any) => {
        console.log('err', err);
    },
});

// ** Redux Persist Reducer
const persistedReducer = persistReducer(
    persistConfig,
    rootReducer(history as any),
);

// ** Create Middleware
const middleware =
    process.env.REACT_APP_STAGE === 'production'
        ? [sagaMiddleware, routerMiddleware(history)]
        : [sagaMiddleware, logger, routerMiddleware(history)];

// ** Add conditional showing of the redux devtools
const composeEnhancers =
    (process.env.REACT_APP_STAGE !== 'production' &&
        typeof window !== 'undefined' &&
        // @ts-ignore
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

// ** Crete Redux-Store
export const store: any = createStore(
    persistedReducer,
    {
        // middleware: (getDefaultMiddleware: any) =>
        //     getDefaultMiddleware({serializableCheck: false}),
    },
    composeEnhancers(applyMiddleware(...middleware), sentryReduxEnhancer),
);

// ** Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// ** Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
// ** Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

// ** Persist Store
export const persistor = persistStore(store);

// ** Run Sagas
sagaMiddleware.run(sagas);

export * from './actions';
export * from './reducers';
export * from './sagas';
export * from './selectors';
