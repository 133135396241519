import {AxiosResponse} from 'axios';

import * as apiRequest from 'utils/axiosInstance/basicInstance';
import tokenInstance from 'utils/axiosInstance/tokenInstance';

export const getMeetings = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('meetings/');
};

// * Only the host of the meeting should call this endpoint
export const getMeetingById = ({
    meeting_id,
}: {
    meeting_id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('meetings/' + meeting_id);
};

export const getMeetingByIdAndPasscode = ({
    meeting_id,
    passcode,
}: {
    meeting_id: string;
    passcode: string;
}): Promise<AxiosResponse<any, any>> => {
    return apiRequest
        .basicInstance()
        .get('meetings/' + meeting_id + '?passcode=' + passcode);
};

export const deleteMeetingsById = ({
    meeting_id,
}: {
    meeting_id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete('meetings/' + meeting_id);
};

export const postMeetings = ({
    name,
    start_at,
    end_at,
    public_guests,
    private_guests,
    passcode,
    description,
    notify_guests,
}: {
    name: string;
    start_at: string;
    end_at: string;
    public_guests?: {email: string; name?: string}[] | undefined;
    private_guests?: {user_id: number}[] | undefined;
    passcode: string;
    description?: string;
    notify_guests: boolean;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('meetings/', {
        name,
        start_at,
        end_at,
        public_guests,
        private_guests: private_guests || [],
        passcode,
        description,
        notify_guests,
    });
};

export const pathMeetings = ({
    name,
    start_at,
    end_at,
    public_guests,
    private_guests,
    uuid,
    passcode,
    description,
    notify_guests,
}: {
    name: string;
    start_at: string;
    end_at: string;
    public_guests?: {email: string; name?: string}[] | undefined;
    private_guests?: {user_id: number}[] | undefined;
    uuid: string;
    passcode: string;
    description?: string;
    notify_guests: boolean;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch('meetings/' + uuid, {
        name,
        start_at,
        end_at,
        public_guests,
        private_guests: private_guests || [],
        passcode,
        description,
        notify_guests,
    });
};

export const postMeetingsAskToJoin = ({
    meeting_id,
    passcode,
    name,
    uuid,
}: {
    meeting_id: string;
    passcode: string;
    name: string;
    uuid: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(
        'meetings/' + meeting_id + '/ask-to-join?passcode=' + passcode,
        {
            name,
            uuid,
        },
    );
};

export const postMeetingsAllowToJoin = ({
    meeting_id,
    data,
}: {
    meeting_id: string;
    data: {
        email: string | null;
        created_at: Date;
        uuid: string;
        name: string;
    };
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('meetings/' + meeting_id + '/allow-join', {
        ...data,
    });
};

export const postMeetingsDenyJoin = ({
    meeting_id,
    data,
}: {
    meeting_id: string;
    data: {
        email: string | null;
        created_at: Date;
        uuid: string;
        name: string;
    };
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post('meetings/' + meeting_id + '/deny-join', {
        ...data,
    });
};

export const getMeetingByDate = ({
    start_date,
    end_date,
}: {
    start_date?: string;
    end_date?: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(
        start_date
            ? `meetings/?start_date=${start_date}`
            : end_date
            ? `meetings/?end_date=${end_date}`
            : '',
    );
};

export const postRemoveGuest = ({
    meetingUUID,
    data,
}: {
    meetingUUID: string;
    data: {
        user_id?: number;
        uuid: string;
    };
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`meetings/${meetingUUID}/remove-guest`, {
        ...data,
    });
};

export const MeetingsService = {
    getMeetings,
    getMeetingById,
    deleteMeetingsById,
    postMeetings,
    pathMeetings,
    getMeetingByIdAndPasscode,
    postMeetingsAskToJoin,
    postMeetingsAllowToJoin,
    postMeetingsDenyJoin,
    getMeetingByDate,
    postRemoveGuest,
};
