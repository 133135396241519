import {AxiosResponse} from 'axios';
import {ITodo} from 'models';
import tokenInstance from 'utils/axiosInstance/tokenInstance';

export const getTodos = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('todos/');
};

export const postTodos = ({
    todo,
}: {
    todo: Partial<ITodo>;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`todos/`, todo);
};

export const getTodo = (toDoId: string): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`todos/${toDoId}`);
};

export const patchTodo = ({
    todoId,
    todo,
}: {
    todoId: number;
    todo: Partial<ITodo>;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`todos/${todoId}`, todo);
};

export const deleteTodo = ({
    todoId,
}: {
    todoId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`todos/${todoId}`);
};

export const ToDosServices = {
    getTodos,
    postTodos,
    patchTodo,
    deleteTodo,
};
