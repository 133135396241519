import React, { ReactNode } from 'react';
import { BackgroundImage } from './styles'

interface Props {
	children: ReactNode;
}

const Background: React.FC<Props> = ({ children }) => {
	return (
		<BackgroundImage>
			{children}
		</BackgroundImage>
	);
};

export default Background;