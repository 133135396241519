import {useEffect, memo, useCallback, useState} from 'react';
import {
    useTheme,
    Typography,
    Box,
    Button,
    Avatar,
    Link,
    CircularProgress,
} from '@mui/material';
import {createSelector} from 'reselect';
import * as Sentry from '@sentry/react';
import {useTranslation} from 'react-i18next';

import {ReusableModalWindow} from 'components';
import {
    selectGiftAccountsToMe,
    useAppSelector,
    showToastAction,
    useAppDispatch,
    setGiftAccountsAction,
} from 'store';
import {GiftAccountsService, UsersService} from 'services';
import {IUserData} from 'models';
import styles from './styles.module.scss';

const stateSelectorHandle = createSelector(
    selectGiftAccountsToMe,
    giftAccountsToMe => {
        return {giftAccountsToMe};
    },
);

export const GiftAccounts = memo(({onClose}: {onClose: () => void}) => {
    const theme = useTheme();
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const stateSelector = useCallback(stateSelectorHandle, []);
    const {giftAccountsToMe} = useAppSelector(stateSelector);
    const [user, setUser] = useState<IUserData | undefined>();
    const [loading, setLoading] = useState<boolean>(true);
    const [isSubmitting, setIsSubmitting] = useState<'accept' | 'decline' | ''>(
        '',
    );

    const decline = async () => {
        try {
            setIsSubmitting('decline');
            const {data} = await GiftAccountsService.postGiftAccountsReject({
                giftAccountsId: giftAccountsToMe?.[0].id.toString(),
            });

            onClose();
            dispatch(
                setGiftAccountsAction({
                    callbackOnError: () => {},
                    callbackOnSuccess: () => {},
                    giftAccounts: {
                        to_me: [data],
                        from_me: [],
                    },
                }),
            );
            dispatch(
                showToastAction({
                    open: true,
                    horizontal: 'center',
                    subTitle: t('giftAccounts.giftAccountRejectedSuccessfully'),
                    title: t('giftAccounts.giftRejected'),
                    vertical: 'top',
                }),
            );
            setIsSubmitting('');
        } catch (error) {
            console.log({error});
            Sentry.captureException(error);
        }
    };

    const accept = async () => {
        try {
            setIsSubmitting('accept');
            const {data} = await GiftAccountsService.postGiftAccountsAccept({
                giftAccountsId: giftAccountsToMe?.[0].id.toString(),
            });
            onClose();
            dispatch(
                setGiftAccountsAction({
                    callbackOnError: () => {},
                    callbackOnSuccess: () => {},
                    giftAccounts: {
                        to_me: [data],
                        from_me: [],
                    },
                }),
            );

            dispatch(
                showToastAction({
                    open: true,
                    horizontal: 'center',
                    subTitle: t('giftAccounts.giftAccountAcceptedSuccessfully'),
                    title: t('giftAccounts.giftAccepted'),
                    vertical: 'top',
                }),
            );
            setIsSubmitting('');
        } catch (error) {
            console.log({error});
            Sentry.captureException(error);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                if (!giftAccountsToMe?.[0]?.sponsored_by) return;
                setLoading(true);
                const {data} = await UsersService.getUserByUserId({
                    id: giftAccountsToMe?.[0]?.sponsored_by?.toString() as string,
                });
                setUser(data);
                setLoading(false);
            } catch (error) {
                console.log({error});
            }
        })();
    }, [giftAccountsToMe]);

    return (
        <ReusableModalWindow
            open={true}
            setOpen={() => onClose()}
            height={'auto'}
            alignItems="flex-start">
            {loading ? (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '100%',
                    }}>
                    <CircularProgress size={20} />
                </Box>
            ) : (
                <>
                    <Typography
                        className={styles.title}
                        sx={{
                            color:
                                theme.palette.mode === 'light'
                                    ? 'rgba(0, 0, 0, 0.85)'
                                    : '#fff7ea',
                        }}>
                        {t('giftAccounts.youReceivedAGiftAccount')} 🎉
                    </Typography>
                    <Typography
                        className={styles.subtitle}
                        variant="subtitle1"
                        component="sub">
                        {t(
                            'giftAccounts.youHaveReceivedAGiftAccountFromAPremiumMember',
                        )}
                    </Typography>
                    <Box
                        className={styles.userContainer}
                        style={{
                            backgroundColor:
                                theme.palette.mode !== 'dark'
                                    ? '#fffbf5'
                                    : '#ff9a0033',
                        }}>
                        <Box>
                            <Avatar
                                className={styles.avatar}
                                src={user?.avatar || '/broken-img.jpg'}
                            />
                            <Box className={styles.userDetails}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: 1,
                                    }}>
                                    <Typography
                                        className={styles.name}
                                        sx={{
                                            color:
                                                theme.palette.mode === 'light'
                                                    ? 'rgba(0, 0, 0, 0.85)'
                                                    : '#fff7ea',
                                        }}>
                                        {user?.profile &&
                                            user?.profile.first_name}{' '}
                                        {user?.profile &&
                                            user?.profile.last_name_initial}
                                        .
                                    </Typography>
                                    <Box className={styles.rating}>
                                        <svg
                                            width="12"
                                            height="12"
                                            viewBox="0 0 13 13"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <g clip-path="url(#clip0_130_1935)">
                                                <path
                                                    d="M0.033884 4.9871C0.118984 4.72386 0.352465 4.5369 0.628695 4.512L4.38085 4.1713L5.86456 0.698551C5.97396 0.444043 6.22311 0.279297 6.49993 0.279297C6.77676 0.279297 7.02591 0.444043 7.13531 0.699146L8.61901 4.1713L12.3718 4.512C12.6475 4.53749 12.8804 4.72386 12.966 4.9871C13.0516 5.25033 12.9725 5.53906 12.7639 5.72107L9.92776 8.20842L10.7641 11.8924C10.8253 12.1633 10.7201 12.4433 10.4954 12.6058C10.3746 12.6931 10.2332 12.7375 10.0907 12.7375C9.96783 12.7375 9.84593 12.7044 9.73653 12.6389L6.49993 10.7045L3.26453 12.6389C3.02777 12.7813 2.72933 12.7683 2.50507 12.6058C2.28022 12.4428 2.17518 12.1627 2.23638 11.8924L3.0727 8.20842L0.236518 5.72156C0.0279322 5.53906 -0.051713 5.25083 0.033884 4.9871Z"
                                                    fill="#FAC300"
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_130_1935">
                                                    <rect
                                                        width="13"
                                                        height="13"
                                                        fill="white"
                                                        transform="matrix(-1 0 0 1 13 0)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        {(user?.profile &&
                                            user?.profile?.rating
                                                ?.toString()
                                                ?.substring(0, 3)) ||
                                            0}
                                    </Box>
                                </Box>
                                <Typography
                                    style={{
                                        color:
                                            theme.palette.mode === 'light'
                                                ? 'rgba(0, 0, 0, 0.85)'
                                                : '#fff7ea',
                                    }}
                                    className={styles.sessionsComplete}>
                                    {user?.profile?.completed_sessions || 0}{' '}
                                    {t('profile.sessionsCompleted')}
                                </Typography>
                            </Box>
                        </Box>
                        <Link
                            href={`/user/${
                                user?.profile ? user?.profile_url : '#'
                            }`}
                            style={{textDecoration: 'none'}}>
                            <Typography className={styles.viewProfile}>
                                {t('home.bookSession.viewProfile')}
                            </Typography>
                        </Link>
                    </Box>
                    <Box className={styles.actionsContainer}>
                        <Button
                            variant={'outlined'}
                            disabled={isSubmitting.length > 0}
                            className={styles.decline}
                            onClick={decline}>
                            {isSubmitting === 'decline' ? (
                                <CircularProgress size={15} />
                            ) : (
                                t('common.decline').toUpperCase()
                            )}
                        </Button>
                        <Button
                            variant={'contained'}
                            disabled={isSubmitting.length > 0}
                            className={styles.accept}
                            onClick={accept}>
                            {isSubmitting === 'accept' ? (
                                <CircularProgress size={15} />
                            ) : (
                                t('common.accept').toUpperCase()
                            )}
                        </Button>
                    </Box>
                </>
            )}
        </ReusableModalWindow>
    );
});
