import {useEffect} from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import {getCookie} from 'utils';
import {useHistory} from 'react-router-dom';

import {OAuthLogUser, useAppDispatch} from 'store';

export const OAuthPage = () => {
    const dispatch = useAppDispatch();
    const history = useHistory();

    useEffect(() => {
        const cookieToken = getCookie({name: 'token'});
        const state = localStorage.getItem(
            process.env.REACT_APP_OAUTH_STATE as string,
        );

        if (cookieToken && state) {
            dispatch(
                OAuthLogUser({
                    token: cookieToken,
                    provider: state?.split('|')[0] as 'google' | 'facebook',
                    state: state?.split('|')[1] as string,
                }),
            );
        } else {
            // ** If no OAuth cookie token redirect back to login
            history.push('/login');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            style={{
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
            <CircularProgress color="primary" style={{}} />
        </div>
    );
};
