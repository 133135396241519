import {AxiosResponse} from 'axios';
import {all, put, select, takeLatest} from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {
    getTodosActionSaga,
    openCreateOrEditTodoAction,
    postTodoAction,
    setTodosAction,
    patchTodoActionSaga,
    deleteTodoActionSaga,
    clearAllCompletedTasksActionSaga,
    clearAllTasksActionSaga,
} from 'store/actions';
import {ToDosServices} from 'services';
import {ETodoTypes, ITodo} from 'models';
import {selectEditableTodo, selectTodos} from '../../selectors';

function* handleGetTodos({payload}: ReturnType<typeof getTodosActionSaga>) {
    try {
        const {data}: AxiosResponse<ITodo> = yield ToDosServices.getTodos();
        yield put(setTodosAction({todos: data, reset: true}));
        payload.callbackOnSuccess && payload.callbackOnSuccess();
    } catch (error: any) {
        console.log({error});
        payload.callbackOnError && payload.callbackOnError();
        Sentry.captureException(error);
    }
}

function* handlePostTodo({payload}: ReturnType<typeof postTodoAction>) {
    try {
        if (!payload?.todo) {
            throw Error('No params provided.');
        }

        const {data}: AxiosResponse = yield ToDosServices.postTodos({
            todo: payload.todo,
        });

        if (data) {
            yield put(setTodosAction({todos: data}));
            yield put(openCreateOrEditTodoAction({witch: ''}));
        }
    } catch (error: any) {
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handlePatchTodo({payload}: ReturnType<typeof patchTodoActionSaga>) {
    try {
        if (!payload?.todo) {
            throw Error('No params provided.');
        }

        const selectCurrentEditableTodo: ITodo = yield select(
            selectEditableTodo,
        );

        const {data}: AxiosResponse = yield ToDosServices.patchTodo({
            todoId: payload.directUpdate
                ? payload.todo.id
                : selectCurrentEditableTodo.id,
            todo: payload.todo,
        });

        if (data) {
            const {data: todos}: AxiosResponse<ITodo> =
                yield ToDosServices.getTodos();
            if (todos) {
                yield put(setTodosAction({todos: todos, reset: true}));
                yield put(openCreateOrEditTodoAction({witch: ''}));
            }
        }

        yield;
    } catch (error: any) {
        console.log({error});
        Sentry.captureException(error);
    }
}

function* handleDeleteTodo({payload}: ReturnType<typeof deleteTodoActionSaga>) {
    try {
        const selectCurrentEditableTodo: ITodo = yield select(
            selectEditableTodo,
        );
        yield ToDosServices.deleteTodo({
            todoId: selectCurrentEditableTodo.id,
        });

        const {data: todos}: AxiosResponse<ITodo> =
            yield ToDosServices.getTodos();
        if (todos) {
            yield put(setTodosAction({todos: todos, reset: true}));
            yield put(openCreateOrEditTodoAction({witch: ''}));
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        payload.callbackOnError && payload.callbackOnError();
        Sentry.captureException(error);
    }
}

function* handleClearAllTasks({
    payload,
}: ReturnType<typeof clearAllTasksActionSaga>) {
    try {
        const selectTodosData: ITodo[] = yield select(selectTodos);
        yield selectTodosData.forEach((todo: ITodo) => {
            ToDosServices.deleteTodo({
                todoId: todo.id,
            });
        });
        payload.callbackOnSuccess && payload.callbackOnSuccess();
        yield put(setTodosAction({todos: undefined, reset: true}));
    } catch (error: any) {
        console.log({error});
        payload.callbackOnError && payload.callbackOnError();
        Sentry.captureException(error);
    }
}

function* handleClearAllCompletedTasks({
    payload,
}: ReturnType<typeof clearAllCompletedTasksActionSaga>) {
    try {
        const selectTodosData: ITodo[] = yield select(selectTodos);
        yield selectTodosData.forEach((todo: ITodo) => {
            if (todo.completed_at !== null)
                ToDosServices.deleteTodo({
                    todoId: todo.id,
                });
        });
        payload.callbackOnSuccess && payload.callbackOnSuccess();
        yield put(
            setTodosAction({
                todos: selectTodosData.filter(
                    (todo: ITodo) =>
                        !todo.completed_at || todo.completed_at === null,
                ),
                reset: true,
            }),
        );
    } catch (error: any) {
        console.log({error});
        payload.callbackOnError && payload.callbackOnError();
        Sentry.captureException(error);
    }
}

export function* TodoSagas(): Generator<unknown, any, undefined> {
    return yield all([
        takeLatest(ETodoTypes.GET_TODOS_SAGA, handleGetTodos),
        takeLatest(ETodoTypes.POST_TODO_SAGA, handlePostTodo),
        takeLatest(ETodoTypes.PATCH_TODO_SAGA, handlePatchTodo),
        takeLatest(ETodoTypes.DELETE_TODO_SAGA, handleDeleteTodo),
        takeLatest(ETodoTypes.CLEAR_ALL_TASKS_SAGA, handleClearAllTasks),
        takeLatest(
            ETodoTypes.CLEAR_ALL_COMPLETED_TASKS_SAGA,
            handleClearAllCompletedTasks,
        ),
    ]);
}
