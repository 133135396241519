import {
    useState,
    useCallback,
    useEffect,
    Dispatch,
    SetStateAction,
    useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { SnackbarOrigin } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, postFocusSessionReportUserSaga } from 'store';
import { getFocusSessionReports } from 'services';
import { showToastAction } from 'store/actions/toast';

export interface ReportUserToast extends SnackbarOrigin {
    open: boolean;
    title: string;
    subTitle: string;
    icon?: any;
}

export const useReportLogic = ({
    setOpen,
    sessionPartner
}: {
    setOpen: Dispatch<SetStateAction<boolean>>;
    sessionPartner: any
}) => {
    const [reportBody, setReportBody] = useState<{
        info: string;
        subject: string;
    }>({ info: '', subject: '' });
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const params = useParams<{ sessionId: string }>();
    const mounted = useRef<boolean>(false);

    const getReports = useCallback(async () => {
        try {
            if (!params.sessionId) return;
            const { data } = await getFocusSessionReports({
                focusSessionId: parseFloat(params.sessionId),
            });
            if (mounted.current) {
                setReportBody({ info: data?.info, subject: data?.subject });
            }
        } catch (error: any) {
            console.log({ error });
            dispatch(
                showToastAction({
                    open: true,
                    vertical: 'top',
                    horizontal: 'center',
                    title: t('common.anErrorHasOccurred'),
                    subTitle: t('common.pleaseTryAgain'),
                    icon: 'error',
                }),
            );
        }
    }, [dispatch, params.sessionId, t]);

    useEffect(() => {
        mounted.current = true;
        getReports();

        return () => {
            mounted.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const submitReport = useCallback(() => {
        if (!sessionPartner || !params?.sessionId) return;

        dispatch(
            postFocusSessionReportUserSaga({
                data: {
                    ...reportBody,
                },
                sessionId: parseInt(params.sessionId),
                callbackOnSuccess: () => {
                    setOpen(false);
                    dispatch(
                        showToastAction({
                            open: true,
                            vertical: 'top',
                            horizontal: 'center',
                            title: t('report.reportSubmitted'),
                            subTitle: t('report.weReceivedYourReport'),
                        }),
                    );
                },
            }),
        );
    }, [dispatch, reportBody, sessionPartner, setOpen, t, params.sessionId]);

    return {
        reportBody,
        setReportBody,
        submitReport,
        t,
    };
};
