import {Dispatch, memo, SetStateAction, useState} from 'react';
import {Popover, Typography, Box, useTheme, Button} from '@mui/material';

const colors = [
    '#FA9A09',
    '#D50000',
    '#E67C73',
    '#F4511E',
    '#F6BF26',
    '#33B679',
    '#0B8043',
    '#039BE5',
    '#3F51B5',
    '#7986CB',
    '#8E24AA',
    '#616161',
];

export const ColorPicker = memo(
    ({
        colorPickerAnchorEl,
        setColorPickerAnchorEl,
    }: {
        colorPickerAnchorEl: HTMLDivElement | null;
        setColorPickerAnchorEl: Dispatch<SetStateAction<HTMLDivElement | null>>;
    }) => {
        const [selectedColor, setSelectedColor] =
            useState<string>('rgb(255, 154, 0)');
        const theme = useTheme();
        const open = Boolean(colorPickerAnchorEl);
        const id = open ? 'color-picker-simple-popover' : undefined;

        const handleClose = () => {
            setColorPickerAnchorEl(null);
        };

        const saveColor = () => {
            try {
            } catch (error) {
                console.log({error});
            }
        };

        return (
            <Popover
                id={id}
                open={open}
                anchorEl={colorPickerAnchorEl}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        background: theme.palette.background.default,
                        boxShadow: '4px 0px 38px 3px rgba(0, 0, 0, 0.13)',
                        borderRadius: '10px',
                        width: 290,
                        maxHeight: 500,
                    },
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <Box
                    sx={{
                        p: 2,
                        display: 'flex',
                        gap: 1,
                        flexDirection: 'column',
                    }}>
                    <Typography
                        sx={{
                            fontFamily: 'Proxima Nova',
                            fontSize: '20px',
                            fontWeight: 700,
                            lineHeight: '24px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                            color:
                                theme.palette.mode === 'light'
                                    ? 'rgba(0, 0, 0, 0.85)'
                                    : '#fff7ea',
                        }}>
                        Color Picker
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Brandon Grotesque',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '20px',
                            letterSpacing: '0em',
                            textAlign: 'left',
                        }}>
                        Select the perfect color for your sessions with our
                        intuitive color picker options
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1,
                            flexWrap: 'wrap',
                            mt: 1,
                        }}>
                        {colors.map((color, index) => {
                            return (
                                <Box
                                    key={`${color}-${index}`}
                                    onClick={() => {
                                        setSelectedColor(color);
                                    }}
                                    sx={{
                                        minWidth: 20,
                                        minHeight: 20,
                                        width: 20,
                                        height: 20,
                                        borderRadius: 50,
                                        backgroundColor: color,
                                        cursor: 'pointer',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    }}>
                                    {selectedColor === color && (
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            height="1em"
                                            viewBox="0 0 448 512">
                                            <path
                                                d="M438.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L160 338.7 393.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                                                fill="#FFFFFF"
                                            />
                                        </svg>
                                    )}
                                </Box>
                            );
                        })}
                    </Box>
                    <Button
                        onClick={saveColor}
                        variant={'contained'}
                        sx={{
                            fontFamily: 'Proxima Nova',
                            fontSize: '18px',
                            fontWeight: 700,
                            lineHeight: '22px',
                            letterSpacing: '0em',
                            textAlign: 'center',
                            borderRadius: 50,
                            color: '#FFFFFF',
                            boxShadow: 'unset',
                            mt: 2,
                            height: 40,
                        }}>
                        SAVE
                    </Button>
                </Box>
            </Popover>
        );
    },
);
