import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from './en.json';
import translationRO from './ro.json';
import translationES from './es.json';
import translationPT from './pt.json';

// the translations
const resources = {
    en: {
        translation: translationEN,
    },
    ro: {
        translation: translationRO,
    },
    es: {
        translation: translationES,
    },
    pt: {
        translation: translationPT,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next) // * passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        // keySeparator: false, // * we do not use keys in form messages.welcome
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // * react already safes from xss
        },
    });

export default i18n;
