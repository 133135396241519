import {AxiosResponse} from 'axios';

import tokenInstance from 'utils/axiosInstance/tokenInstance';

export const postIgnoreUser = ({
    userId,
}: {
    userId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`users/ignore/${userId}`);
};

export const deleteIgnoredUser = ({
    userId,
}: {
    userId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`users/ignore/${userId}`);
};

export const getUserByUserId = ({
    id,
}: {
    id: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`users/${id}`);
};

export const getUserByProfileUrl = ({
    profileUrl,
}: {
    profileUrl: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`users/profile/${profileUrl}`);
};

export const UsersService = {
    postIgnoreUser,
    deleteIgnoredUser,
    getUserByUserId,
    getUserByProfileUrl,
};
