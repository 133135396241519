import {TToastActionTypes, IToastSate, EToastTypes} from 'models';

const initialState: IToastSate = {
    data: {
        open: false,
        horizontal: 'center',
        vertical: 'top',
        title: '',
        subTitle: '',
        icon: '',
        transitionDirection: 'left',
        emoji: undefined,
    },
};

const toastReducer = (
    state = initialState,
    action: TToastActionTypes,
): IToastSate => {
    switch (action.type) {
        case EToastTypes.SHOW_TOAST_ELEMENT: {
            return {
                ...state,
                data: action.payload,
            };
        }

        default:
            return state;
    }
};

export default toastReducer;
