import {KnownVisibilityStates} from 'react-page-visibility';

import {EAppTypes} from 'models/app/types';

export const appGetTimezonesActionSaga = (payload?: {
    callback: () => void;
}) => ({
    type: EAppTypes.APP_META_DATA_TIMEZONES_GET,
    payload,
});

export const appSetTimezonesAction = (payload: {timezones: string[]}) => ({
    type: EAppTypes.APP_META_DATA_TIMEZONES_SET,
    payload,
});

export const setAppLastRequestsFailedAction = (payload: string) => ({
    type: EAppTypes.APP_LAST_REQUEST_FAILED_SET_ACTION,
    payload,
});

export const setAppLoadingAction = (payload: boolean) => ({
    type: EAppTypes.APP_LOADING_ACTION,
    payload,
});

export const setAppVisibilityAction = (payload: {
    isVisible: boolean;
    visibilityState: KnownVisibilityStates;
}) => ({
    type: EAppTypes.APP_SET_VISIBILITY_ACTION,
    payload,
});
