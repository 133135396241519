import {
    IGiftAccountsAction
} from 'models';

export enum EGiftAccountsTypes {
    GET_GIFT_ACCOUNTS = 'GET/GIFT/ACCOUNTS',
    SET_GIFT_ACCOUNTS = 'SET/GIFT/ACCOUNTS'
}

export interface IGiftAccount {
    from_me: {
        id: number,
        gift_receiver_email: string,
        gift_receiver_name: null | string,
        sponsored_user: null | string,
        accepted_at: null | string,
        sponsored_by: null | string,
        rejected_at: null | string,
        message_from_sponsor: null | string
    }[] | [],
    to_me: {
        id: number,
        gift_receiver_email: string,
        gift_receiver_name: null | string,
        sponsored_user: null | string,
        sponsored_by: null | string,
        accepted_at: null | string,
        rejected_at: null | string,
        message_from_sponsor: null | string
    }[] | []
}

export interface IGiftAccountsState {
    data: IGiftAccount;
}

export type TGiftAccountsActionTypes =
    IGiftAccountsAction;
