export const parseTextToHref = (text: string) => {
    //*  Regular expression pattern to match URLs
    const urlPattern = /(https?:\/\/[^\s]+)/g;

    // *  Replace URLs with clickable links
    const replacedText = text.replace(
        urlPattern,
        '<a href="$1" style="color: #fa9a09; text-decoration: underline;" target="_blank">$1</a>',
    );

    return replacedText;
};
