import {AxiosResponse} from 'axios';

import tokenInstance from 'utils/axiosInstance/tokenInstance';

export const getAvailableForms = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('forms/');
};

export const postForm = (
    formType: 'feedback' | 'contact_us',
    body: {
        title: string;
        data: Record<string, any>;
    },
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`forms/${formType}`, {...body});
};

export const FormsService = {
    getAvailableForms,
    postForm,
};
