import { EPaymentsTypes, ISubscription } from 'models';

export const setSubscriptionAction = (payload: {
    subscription: ISubscription;
    callbackOnSuccess?: () => void;
    callbackOnError?: () => void;
}) => ({
    type: EPaymentsTypes.SET_SUBSCRIPTION_ACTION,
    payload,
});


export const getSubscriptionAction = (payload: {
    callbackOnSuccess?: (currency: "usd" | "eur" | "gbp") => void;
    callbackOnError?: () => void;
}) => ({
    type: EPaymentsTypes.GET_SUBSCRIPTION_ACTION,
    payload,
});

export const setSubscriptionDefaultPaymentMethod = (payload: string) => ({
    type: EPaymentsTypes.SET_DEFAULT_PAYMENT_METHOD,
    payload
})