import { AxiosResponse } from "axios";

import tokenInstance from 'utils/axiosInstance/tokenInstance';

export const postGiftAccounts = (
    {
        email,
        fullName = undefined,
        message = undefined,
    }: {
        email: string,
        fullName?: string | undefined
        message?: string | undefined
    }
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`gift-accounts/`, { gift_receiver_email: email, gift_receiver_name: fullName, message_from_sponsor: message });
};

export const postGiftAccountsAccept = (
    {
        giftAccountsId
    }: {
        giftAccountsId: string,
    }
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`gift-accounts/${giftAccountsId}/accept`);
};

export const postGiftAccountsReject = (
    {
        giftAccountsId
    }: {
        giftAccountsId: string,
    }
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`gift-accounts/${giftAccountsId}/reject`);
};

export const getGiftAccounts = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`gift-accounts/`);
};

export const deleteGiftAccounts = (
    {
        giftAccountsId
    }: {
        giftAccountsId: string,
    }
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`gift-accounts/${giftAccountsId}`, {});
};

export const GiftAccountsService = {
    postGiftAccounts,
    postGiftAccountsAccept,
    postGiftAccountsReject,
    getGiftAccounts,
    deleteGiftAccounts
};