import {styled} from '@mui/material/styles';

export const PrimaryButton = styled('button')(
    ({theme}) => `
		cursor: pointer;
		padding: 20px 40px;
		background-color: #FA9A09;
		border-radius: 36px;
		color: #FFFFFF;
		text-transform: uppercase;
		font-style: normal;
		font-weight: bold;
		font-size: 22px;
		line-height: 31px;
		border: none;
	`,
);
