import {AxiosResponse} from 'axios';

import {ILeaderBoard, IRatings} from 'models';
import tokenInstance from 'utils/axiosInstance/tokenInstance';

export const getFocusSessions = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('focus-sessions/');
};

export const getFocusSession = ({
    focusSessionId,
}: {
    focusSessionId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`focus-sessions/${focusSessionId}`);
};

export const getMyStatistics = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`users/me/stats`);
};

export const getMyFocus = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`users/me/my-focus`);
};

export const patchMyFocus = (
    myFocus: string,
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`users/me/my-focus`, {my_focus: myFocus});
};

export const getLeaderBoard = (): Promise<AxiosResponse<ILeaderBoard>> => {
    return tokenInstance().get(`stats/leaderboard`);
};

export const getTodaysFocusSessions = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`focus-sessions/today`);
};

export const getAllFocusSessions = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`focus-sessions/`);
};

export const getFocusSessionsByDate = ({
    date,
    filter,
    duration,
    groups,
}: {
    date: string;
    filter?: 'from_favourites' | 'my_sessions';
    duration?: number[];
    groups?: number[];
}): Promise<AxiosResponse<any, any>> => {
    let url = `focus-sessions/by-date/${date}`;

    if (groups && groups?.length > 0) {
        url += `?user_groups=[${groups?.join(',')}]`;
    }

    return tokenInstance().get(url, {
        params: {filter, duration: duration ? duration.join(',') : ''},
    });
};

export const getMyFocusSessionsByDate = (
    date: string,
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`focus-sessions/me/by-date/${date}`);
};

export const getFocusSessionsMe = (
    limit?: number,
    offset?: number,
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(
        `focus-sessions/me/?limit=${limit}&offset=${offset}`,
    );
};

export const getFocusSessionsFavourites = (
    limit?: number,
    offset?: number,
): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(
        `focus-sessions/from-my-favourites/?limit=${limit}&offset=${offset}`,
    );
};

export const bookFocusSession = ({
    data,
}: {
    data: {
        name: string;
        start_at: string;
        duration: 30 | 45 | 60;
        notes: string;
        title: string;
        colour?: string;
        user_group_id?: string;
    };
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`focus-sessions/`, {
        ...data,
    });
};

export const patchBookedFocusSession = ({
    data,
}: {
    data: {
        id: number;
        name: string;
        start_at: string;
        duration: 30 | 45 | 60;
        update_type: 'THIS_SESSION' | 'THIS_AND_FOLLOWING_SESSIONS';
        notes: string;
        title: string;
        colour?: string;
        user_group_id?: string | null;
    };
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`focus-sessions/${data.id}`, {
        ...data,
    });
};

export const deleteFocusSession = ({
    id,
}: {
    id: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`focus-sessions/${id}`);
};

export const getFocusSessionsMePast = ({
    limit,
    offset,
}: {
    limit: number;
    offset: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(
        `focus-sessions/me/past?limit=${limit}&offset=${offset}`,
    );
};

export const joinFocusSessions = ({
    sessionId,
    title,
}: {
    sessionId: number;
    title?: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`focus-sessions/${sessionId}/join`, {
        title,
    });
};

export const postRateFocusSession = ({
    sessionId,
    ratedSession,
}: {
    sessionId: number;
    ratedSession: IRatings;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`focus-sessions/${sessionId}/rate-user`, {
        ...ratedSession,
    });
};

export const addUserToFavouritesList = ({
    userId,
}: {
    userId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`users/favourite/${userId}`);
};

export const postReportFocusSessionUser = ({
    focusSessionId,
    data,
}: {
    focusSessionId: number;
    data: {
        reported_user: number;
        reported_by: number;
        info: string;
        subject: string;
    };
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(
        `focus-sessions/${focusSessionId}/report-user`,
        {
            ...data,
        },
    );
};

export const postLeaveFocusSession = ({
    focusSessionId,
    deleteType,
    leaveMessage,
}: {
    focusSessionId: number;
    deleteType: 'THIS_SESSION' | 'THIS_AND_FOLLOWING_SESSIONS' | 'ALL_SESSIONS';
    leaveMessage?: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`focus-sessions/${focusSessionId}/leave`, {
        delete_type: deleteType,
        leave_message: leaveMessage,
    });
};

export const getFocusSessionReports = ({
    focusSessionId,
}: {
    focusSessionId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`focus-sessions/${focusSessionId}/report`);
};

export const getUUID = (): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get('uuid');
};

export const getFocusSessionsByDateRange = ({
    startDate,
    endDate,
    filter,
    duration,
    groups,
}: {
    startDate: string;
    endDate: string;
    filter?: 'from_favourites' | 'my_sessions';
    duration?: number[];
    groups?: number[];
}): Promise<AxiosResponse<any, any>> => {
    let url = `focus-sessions/?start_date=${startDate}&end_date=${endDate}`;

    if (groups && groups?.length > 0) {
        url += `&user_groups=[${groups.join(',')}]`;
    }

    return tokenInstance().get(url, {
        params: {
            filter,
            duration: duration ? duration.join(',') : '',
        },
    });
};

export const postFocusSessionsRepeat = ({
    parentSession,
    repeatFrequency,
    endsNever,
    endsAfter,
    endsAfterOccurrences,
}: {
    parentSession: number;
    repeatFrequency: 'DAILY' | 'WEEKDAY' | 'WEEKEND' | 'WEEKLY';
    endsNever: boolean;
    endsAfter: string | null;
    endsAfterOccurrences: number | null;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`/focus-sessions/repeat/`, {
        parent_session: parentSession,
        repeat_frequency: repeatFrequency,
        ends_never: endsNever,
        ends_after: endsAfter,
        ends_after_occurrences: endsAfterOccurrences,
    });
};

export const patchFocusSessionsRepeat = ({
    parentSession,
    repeatFrequency,
    endsNever,
    endsAfter,
    endsAfterOccurrences,
    focusSessionId,
}: {
    parentSession: number;
    repeatFrequency: 'DAILY' | 'WEEKDAY' | 'WEEKEND' | 'WEEKLY';
    endsNever: boolean;
    endsAfter: string | null;
    endsAfterOccurrences: number | null;
    focusSessionId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().patch(`/focus-sessions/repeat/${focusSessionId}`, {
        parent_session: parentSession,
        repeat_frequency: repeatFrequency,
        ends_never: endsNever,
        ends_after: endsAfter,
        ends_after_occurrences: endsAfterOccurrences,
    });
};

export const getFocusSessionsRepeat = ({
    focusSessionId,
}: {
    focusSessionId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/focus-sessions/repeat/${focusSessionId}`);
};

export const deleteFocusSessionsRepeat = ({
    focusSessionId,
}: {
    focusSessionId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().delete(`/focus-sessions/repeat/${focusSessionId}`);
};

export const getFocusSessionsByUserId = ({
    userId,
}: {
    userId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/focus-sessions/by-user/${userId}`);
};

export const getFocusSessionsActivity = ({
    focusSessionId,
}: {
    focusSessionId: string;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().get(`/focus-sessions/${focusSessionId}/activity`);
};

export const postFocusSessionsActivity = ({
    focusSessionId,
    data,
}: {
    focusSessionId: string;
    data: {
        type:
            | 'USER_JOINED_LOBBY'
            | 'USER_JOINED_SESSION'
            | 'USER_LEFT_SESSION'
            | undefined;
        data: string;
        session: number;
        createdAt: string;
        user: number;
    };
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`/focus-sessions/${focusSessionId}/activity`, {
        ...data,
    });
};

export const SessionsService = {
    getFocusSessions,
    getFocusSession,
    getMyStatistics,
    getMyFocus,
    getLeaderBoard,
    getTodaysFocusSessions,
    getFocusSessionsByDate,
    getAllFocusSessions,
    patchMyFocus,
    getFocusSessionsMe,
    bookFocusSession,
    patchBookedFocusSession,
    deleteFocusSession,
    joinFocusSessions,
    getFocusSessionsMePast,
    postRateFocusSession,
    addUserToFavouritesList,
    postReportFocusSessionUser,
    postLeaveFocusSession,
    getFocusSessionReports,
    getUUID,
    getMyFocusSessionsByDate,
    getFocusSessionsByDateRange,
    postFocusSessionsRepeat,
    patchFocusSessionsRepeat,
    getFocusSessionsRepeat,
    deleteFocusSessionsRepeat,
    getFocusSessionsByUserId,
    postFocusSessionsActivity,
    getFocusSessionsActivity,
    getFocusSessionsFavourites,
};
