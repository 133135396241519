import {useCallback, useState} from 'react';
import {
    Box,
    Typography,
    Button,
    useTheme,
    CircularProgress,
} from '@mui/material';
import {createSelector} from 'reselect';
import {useTranslation} from 'react-i18next';
import {useLocation} from 'react-router-dom';

import {
    useAppSelector,
    selectAuthUserMessage,
    resendAuthUserVerificationEmail,
    useAppDispatch,
} from 'store';
import styles from './styles.module.scss';

const stateSelectorHandle = createSelector(
    selectAuthUserMessage,
    authUserMessage => {
        return {
            authUserMessage,
        };
    },
);

export const VerifyEmail = () => {
    const theme = useTheme();
    const stateSelector = useCallback(stateSelectorHandle, []);
    const {authUserMessage} = useAppSelector(stateSelector);
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const [loading, setLoading] = useState<boolean>(false);
    const [message, setMessage] = useState<string>('');
    const location = useLocation();

    const resendEmail = () => {
        if (!authUserMessage?.action) return;

        setLoading(true);
        const inputString = authUserMessage?.action;
        const regex = /\/\/[^/]+\/(.+)/;
        const match = inputString.match(regex);

        if (match && match.length > 1) {
            const extractedValue = match[1];
            dispatch(
                resendAuthUserVerificationEmail({
                    url: extractedValue,
                    callbackOnSuccess: ({data}) => {
                        console.log({data});
                        setMessage(data?.message);
                    },
                    callbackOnError: (message: string) => {},
                }),
            );

            setLoading(false);
        } else {
            console.log('No match found');
            setLoading(false);
        }
    };

    if (
        location.pathname.includes('lobby') ||
        location.pathname.includes('room') ||
        location.pathname.includes('meeting') ||
        location.pathname.includes('focus-session') ||
        location.pathname.includes('do-action')
    )
        return <></>;

    return (
        <Box
            className={styles.emailNotVerifiedBox}
            sx={{
                backgroundColor:
                    theme.palette.mode === 'dark' ? '#ff9a0033' : '#fffdfa',
            }}>
            <Typography className={styles.emailText}>
                {message ? (
                    <>{message}</>
                ) : (
                    <>{t('verifyEmail.weHaveSentAVerificationMailTo')}</>
                )}
            </Typography>
            <Button
                onClick={resendEmail}
                variant={'contained'}
                disabled={loading}
                className={styles.resendEmail}>
                {loading ? (
                    <CircularProgress size={15} />
                ) : (
                    t('verifyEmail.resendEmail')
                )}
            </Button>
        </Box>
    );
};
