export const getCookie = ({name}: {name: string}) => {
    const uniqueName = name + '=';
    const split = document.cookie.split(';');
    for (let i = 0; i < split.length; i++) {
        let c = split[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(uniqueName) === 0) {
            return c.substring(uniqueName.length, c.length);
        }
    }
    return null;
};

export const setCookie = ({
    name,
    value,
    days,
    minutes,
}: {
    name: string;
    value: string;
    days: number | null;
    minutes: number | null;
}) => {
    let expires: string = '';
    let date: Date = new Date();

    if (days) {
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    }
    if (minutes) {
        date.setTime(date.getTime() + minutes * 60000);
    }

    expires = '; expires=' + date.toUTCString();

    // console.log(
    //     name +
    //         '=' +
    //         (value || '') +
    //         ';domain=.localhost' +
    //         expires +
    //         '; path=/',
    // );

    document.cookie =
        name +
        '=' +
        (value || '') +
        expires +
        ';domain=.dev.focus101.com' +
        '; path=/';
};

export const eraseCookie = (name: string) => {
    document.cookie =
        name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
};
