import {all, put, takeLatest} from 'redux-saga/effects';
import * as Sentry from '@sentry/react';

import {appGetTimezonesActionSaga, appSetTimezonesAction} from 'store';
import {EAppTypes} from 'models/app/types';
import {AuthService} from 'services/authService';

function* handleAppTimezones({
    payload,
}: ReturnType<typeof appGetTimezonesActionSaga>) {
    try {
        const {data} = yield AuthService.handleMetaData();
        if (data) {
            yield put(appSetTimezonesAction(data.timezones));
            payload?.callback && payload?.callback();
        }
    } catch (error: any) {
        console.log({error});
        payload?.callback && payload?.callback();
        Sentry.captureException(error);
    }
}

export function* AppSagas(): Generator<unknown, any, undefined> {
    return yield all([
        takeLatest(EAppTypes.APP_META_DATA_TIMEZONES_GET, handleAppTimezones),
    ]);
}
