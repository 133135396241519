import {useState, useRef, useCallback} from 'react';
import {
    Box,
    Tooltip,
    Typography,
    IconButton,
    Divider,
    useTheme,
} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
// import StopIcon from '@mui/icons-material/Stop';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import ArrowBackIosNewOutlinedIcon from '@mui/icons-material/ArrowBackIosNewOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import {createSelector} from 'reselect';
import * as Sentry from '@sentry/react';
import {useTranslation} from 'react-i18next';

import {ReactComponent as X} from 'assets/svgs/x.svg';
import {WidgetCounter} from './components/counter';
import styles from './styles.module.scss';
import {
    pressPomodoroTimerAction,
    useAppDispatch,
    selectPomodoroTimerRunning,
    useAppSelector,
    selectPomodoroCurrentTask,
    selectNoOfPomodorosFinished,
    selectActivePomodoroTab,
    setShowPomodoroWidgetAction,
    setNumberOfTodosFinishedAction,
    setActivePomodoroTabAction,
    selectPomodoroSettings,
} from 'store';

const stateSelectorHandle = createSelector(
    selectPomodoroTimerRunning,
    selectPomodoroCurrentTask,
    selectNoOfPomodorosFinished,
    selectActivePomodoroTab,
    selectPomodoroSettings,
    (
        pomodoroRunning,
        pomodoroCurrentTask,
        pomodorosFinished,
        pomodoroActiveTab,
        pomodoroSettings,
    ) => {
        return {
            pomodoroRunning,
            pomodoroCurrentTask,
            pomodorosFinished,
            pomodoroActiveTab,
            pomodoroSettings,
        };
    },
);

export const PomodoroWidget = () => {
    const [expendPomodoroWidget, setExpendPomodoroWidget] =
        useState<boolean>(false);
    const refCallback = useRef<any>();
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const theme = useTheme();
    const [timerState, setTimerState] = useState<'START' | 'PAUSE'>('START');
    const stateSelector = useCallback(stateSelectorHandle, []);
    const {
        pomodoroRunning,
        pomodoroCurrentTask,
        pomodorosFinished,
        pomodoroActiveTab,
        pomodoroSettings,
    } = useAppSelector(stateSelector);

    const skip = useCallback(() => {
        try {
            dispatch(
                pressPomodoroTimerAction({
                    running: false,
                    timeLeft: {
                        pomodoro:
                            pomodoroActiveTab === 'POMODORO'
                                ? pomodoroSettings?.pomodoro ?? 1500000
                                : undefined,
                        shortBreak:
                            pomodoroActiveTab === 'SHORT_BREAK'
                                ? pomodoroSettings?.shortBreak ?? 300000
                                : undefined,
                        longBreak:
                            pomodoroActiveTab === 'LONG_BREAK'
                                ? pomodoroSettings?.longBreak ?? 900000
                                : undefined,
                    },
                }),
            );

            if (pomodoroActiveTab === 'POMODORO') {
                if (pomodorosFinished < 4) {
                    // * Continue with a short break.
                    dispatch(setActivePomodoroTabAction('SHORT_BREAK'));
                } else if (pomodorosFinished === 4) {
                    // * Transition to a long break and reset the count.
                    dispatch(setActivePomodoroTabAction('LONG_BREAK'));
                    dispatch(setNumberOfTodosFinishedAction(0));
                }
            } else if (pomodoroActiveTab === 'SHORT_BREAK') {
                // * Go back to the next pomodoro.
                dispatch(setActivePomodoroTabAction('POMODORO'));
                dispatch(setNumberOfTodosFinishedAction(pomodorosFinished + 1));
            } else if (pomodoroActiveTab === 'LONG_BREAK') {
                // * After a long break, start a new set of pomodoros.
                dispatch(setActivePomodoroTabAction('POMODORO'));
                dispatch(setNumberOfTodosFinishedAction(1));
            }

            refCallback.current.pause();
        } catch (error) {
            console.log({error});
            Sentry.captureException(error);
        }
    }, [
        dispatch,
        pomodoroActiveTab,
        pomodoroSettings?.longBreak,
        pomodoroSettings?.pomodoro,
        pomodoroSettings?.shortBreak,
        pomodorosFinished,
    ]);

    return (
        <Box
            sx={{
                zIndex: 1301,
                position: 'fixed',
                bottom: 80,
                right: 0,
                width: expendPomodoroWidget ? 355 : 255,
                transitionTimingFunction: 'ease-in-out',
                transitionProperty: 'width',
                transitionDuration: '0.8s',
                background: '#FFFDFA',
                border: '2px solid #FFE9C8',
                borderRight: 'unset',
                borderTopLeftRadius: '8px',
                borderBottomLeftRadius: '8px',
            }}>
            <Box sx={{position: 'relative'}}>
                <Box sx={{p: 1}}>
                    <Typography className={styles.timeTracker}>
                        {t('pomodoro.pomodoroTracker')}
                    </Typography>
                </Box>
                <Divider
                    sx={{color: '#E0E8EC', background: '#E0E8EC', opacity: 0.4}}
                />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        p: 1,
                        gap: 2,
                    }}>
                    <Tooltip
                        placement="top"
                        title={t('pomodoro.close') as string}>
                        <IconButton
                            onClick={() =>
                                setExpendPomodoroWidget(prev => !prev)
                            }>
                            {expendPomodoroWidget ? (
                                <ArrowForwardIosOutlinedIcon
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.85)',
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            ) : (
                                <ArrowBackIosNewOutlinedIcon
                                    sx={{
                                        color: 'rgba(0, 0, 0, 0.85)',
                                        width: 20,
                                        height: 20,
                                    }}
                                />
                            )}
                        </IconButton>
                    </Tooltip>
                    <WidgetCounter
                        key={'pomodoroWidgetCounter'}
                        refCallback={refCallback}
                        setTimerState={setTimerState}
                        noOfPomodorosFinished={pomodorosFinished}
                        pomodoroActiveTab={pomodoroActiveTab}
                        pomodoroRunning={pomodoroRunning}
                    />
                    {expendPomodoroWidget && pomodoroCurrentTask && (
                        <>
                            <Box className={styles.taskBox}>
                                <Typography className={styles.taskName}>
                                    {pomodoroCurrentTask?.name || ''}
                                </Typography>
                            </Box>
                        </>
                    )}
                    <Box
                        sx={{
                            display: 'flex',
                            gap: 1,
                        }}>
                        <Tooltip
                            placement="top"
                            title={
                                timerState === 'START'
                                    ? (t('pomodoro.start') as string)
                                    : (t('pomodoro.pause') as string)
                            }>
                            <IconButton
                                onClick={() => {
                                    timerState === 'START'
                                        ? refCallback.current.start()
                                        : refCallback.current.pause();
                                    setTimerState('PAUSE');
                                    dispatch(
                                        pressPomodoroTimerAction({
                                            running: timerState === 'START',
                                            timeLeft: {
                                                pomodoro:
                                                    pomodoroActiveTab ===
                                                    'POMODORO'
                                                        ? refCallback?.current
                                                              ?.state?.timeDelta
                                                              ?.total - 1
                                                        : pomodoroRunning
                                                              .timeLeft
                                                              .pomodoro ||
                                                          1500000,
                                                shortBreak:
                                                    pomodoroActiveTab ===
                                                    'SHORT_BREAK'
                                                        ? refCallback?.current
                                                              ?.state?.timeDelta
                                                              ?.total - 1
                                                        : pomodoroRunning
                                                              .timeLeft
                                                              .shortBreak ||
                                                          300000,
                                                longBreak:
                                                    pomodoroActiveTab ===
                                                    'LONG_BREAK'
                                                        ? refCallback?.current
                                                              ?.state?.timeDelta
                                                              ?.total - 1
                                                        : pomodoroRunning
                                                              .timeLeft
                                                              .longBreak ||
                                                          900000,
                                            },
                                        }),
                                    );
                                }}
                                className={styles.startTimer}>
                                {timerState === 'START' ? (
                                    <PlayArrowIcon
                                        sx={{
                                            color: '#FA9A09',
                                            width: 20,
                                            height: 20,
                                        }}
                                    />
                                ) : (
                                    <PauseIcon
                                        sx={{
                                            color: '#FA9A09',
                                            width: 20,
                                            height: 20,
                                        }}
                                    />
                                )}
                            </IconButton>
                        </Tooltip>
                        <Tooltip
                            title={t('pomodoro.skip') as string}
                            placement="top">
                            <IconButton onClick={skip} className={styles.skip}>
                                <SkipNextIcon
                                    sx={{
                                        color: '#FA9A09',
                                        width: 22,
                                        height: 22,
                                    }}
                                />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Stop" placement="top">
                            <IconButton
                                sx={{
                                    borderRadius: '50px',
                                    width: 35,
                                    height: 35,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    '&:hover': {
                                        svg: {
                                            fill: 'rgba(250, 154, 9, .7)',
                                        },
                                    },
                                }}>
                                <StopIcon
                                    sx={{
                                        color: '#FA9A09',
                                        width: 22,
                                        height: 22,
                                    }}
                                />
                            </IconButton>
                        </Tooltip> */}
                    </Box>
                </Box>
                <IconButton
                    onClick={() => dispatch(setShowPomodoroWidgetAction(false))}
                    sx={{position: 'absolute', right: 5, top: 5}}>
                    <X
                        fill={
                            theme.palette.mode === 'dark'
                                ? '#cbcbcb'
                                : 'rgba(0, 0, 0, 0.85)'
                        }
                        className={styles.x}
                        width={13}
                        height={13}
                    />
                </IconButton>
            </Box>
        </Box>
    );
};
