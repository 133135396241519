import {TUserActionTypes, IUserState, EUserTypes} from 'models';

const initialState: IUserState = {
    data: undefined,
};

const userReducer = (
    state = initialState,
    action: TUserActionTypes,
): IUserState => {
    switch (action.type) {
        case EUserTypes.USER_ACTION_SET: {
            return {
                ...state,
                data: action.payload,
            };
        }

        default:
            return state;
    }
};

export default userReducer;
