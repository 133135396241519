import axios from 'axios';
import {retryInstance} from './retryInstance';

export function basicInstance(header?: string) {
    return axios.create({
        baseURL: process.env.REACT_APP_URL,
        headers: {
            Authorization: header ? `Basic ${header}` : '',
        },
        timeout: parseFloat(process.env.REACT_APP_TIMEOUT as string),
    });
}

retryInstance(basicInstance());
