import {useCallback, memo} from 'react';
import {
    RadioGroup,
    FormLabel,
    FormControl,
    FormControlLabel,
    CircularProgress,
    Button,
    Typography,
    Box,
    Stack,
    Avatar,
    useTheme,
    Radio,
} from '@mui/material';
import {createSelector} from 'reselect';
import {useTranslation} from 'react-i18next';
import * as Sentry from '@sentry/react';

import {ReusableModalWindow} from 'components/modal';
import {
    selectEditingSession,
    useAppDispatch,
    useAppSelector,
    selectAuthUserId,
    selectFocusSessionRepeatConfig,
    selectFocusSessionChooseUpdateRepeatType,
    setChooseRepeatSessionUpdateType,
    openFocusSessionModalAction,
    patchBookedFocusSessionSaga,
    selectIsFocusSessionModalOpen,
    showToastAction,
} from 'store';
import {ReactComponent as Star} from 'assets/images/star.svg';
import styles from './styles.module.scss';

const stateSelectorHandle = createSelector(
    selectEditingSession,
    selectAuthUserId,
    selectFocusSessionRepeatConfig,
    selectFocusSessionChooseUpdateRepeatType,
    selectIsFocusSessionModalOpen,
    (
        editingSession,
        authUserId,
        focusSessionRepeatConfig,
        chooseFocusSessionChooseUpdateRepeatType,
        isFocusSessionModalOpen,
    ) => {
        return {
            editingSession,
            authUserId,
            focusSessionRepeatConfig,
            chooseFocusSessionChooseUpdateRepeatType,
            isFocusSessionModalOpen,
        };
    },
);

export const UpdateSessionWithRepeatConfig = memo(
    ({
        updateEventDrop,
        eventRevert,
        loading,
        currentEvent,
        updateEventResize,
    }: {
        updateEventDrop: (event?: any) => void;
        eventRevert: () => void;
        loading: {initial: boolean; action: boolean};
        currentEvent: any;
        updateEventResize: (event?: any) => void;
    }) => {
        const stateSelector = useCallback(stateSelectorHandle, []);
        const {
            editingSession,
            authUserId,
            focusSessionRepeatConfig,
            chooseFocusSessionChooseUpdateRepeatType,
            isFocusSessionModalOpen,
        } = useAppSelector(stateSelector);
        const {t} = useTranslation();
        const dispatch = useAppDispatch();
        const theme = useTheme();
        const partner =
            editingSession &&
            editingSession?.participants?.length > 1 &&
            editingSession?.participants?.find(part => part?.id !== authUserId);

        const handleUpdateSessionWithRepeat = () => {
            try {
                if (
                    isFocusSessionModalOpen &&
                    chooseFocusSessionChooseUpdateRepeatType?.sessionData
                ) {
                    dispatch(
                        patchBookedFocusSessionSaga({
                            ...chooseFocusSessionChooseUpdateRepeatType?.sessionData,
                            updateType:
                                chooseFocusSessionChooseUpdateRepeatType.type,
                            callbackOnSuccess: () => {
                                dispatch(openFocusSessionModalAction(false));
                                dispatch(
                                    showToastAction({
                                        title: t(
                                            'home.bookSession.sessionUpdatedSuccessfully',
                                        ),
                                        subTitle: t(
                                            'home.bookSession.sessionUpdatedSuccessfullyLookingForPartner',
                                        ),
                                        open: true,
                                        vertical: 'top',
                                        horizontal: 'center',
                                    }),
                                );

                                dispatch(
                                    setChooseRepeatSessionUpdateType({
                                        open: false,
                                        type: 'THIS_SESSION',
                                        sessionData: undefined,
                                    }),
                                );
                            },
                            callbackOnError: ({message}) => {
                                dispatch(
                                    showToastAction({
                                        title: t('common.anErrorHasOccurred'),
                                        subTitle:
                                            message ||
                                            t('common.pleaseTryAgain'),
                                        open: true,
                                        vertical: 'top',
                                        horizontal: 'center',
                                        icon: 'error',
                                    }),
                                );
                            },
                        }),
                    );
                    return;
                }

                if (!currentEvent || !currentEvent?.action)
                    return alert(t('home.calendar.noActionFoundForThisEvent'));

                currentEvent?.action === 'drop'
                    ? updateEventDrop({event: undefined})
                    : updateEventResize({event: undefined});
            } catch (error) {
                dispatch(
                    showToastAction({
                        title: t('common.anErrorHasOccurred'),
                        subTitle: t('common.pleaseTryAgain'),
                        open: true,
                        vertical: 'top',
                        horizontal: 'center',
                        icon: 'error',
                    }),
                );
                Sentry.captureException(error);
                console.log({error});
            }
        };

        const handleCancelSessionUpdateWithRepeat = () => {
            try {
                if (currentEvent && currentEvent?.action) {
                    eventRevert();
                }
                dispatch(
                    setChooseRepeatSessionUpdateType({
                        open: false,
                        type: 'THIS_SESSION',
                    }),
                );
            } catch (error) {
                dispatch(
                    showToastAction({
                        title: t('common.anErrorHasOccurred'),
                        subTitle: t('common.pleaseTryAgain'),
                        open: true,
                        vertical: 'top',
                        horizontal: 'center',
                        icon: 'error',
                    }),
                );
                Sentry.captureException(error);
                console.log({error});
            }
        };

        return (
            <ReusableModalWindow
                open={!!chooseFocusSessionChooseUpdateRepeatType?.open}
                setOpen={() => {
                    if (currentEvent && currentEvent?.action) {
                        eventRevert();
                    }
                    dispatch(
                        setChooseRepeatSessionUpdateType({
                            open: false,
                            type: 'THIS_SESSION',
                        }),
                    );
                }}
                disableEscapeKeyDown={true}
                height={'auto'}
                width={'auto'}
                alignItems={'flex-start'}>
                <Typography
                    variant="h1"
                    component="h2"
                    className={styles.title}>
                    {t('home.calendar.updateSession')}
                </Typography>
                <Box
                    sx={{
                        my: 2,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        height: '100%',
                    }}>
                    <Typography
                        variant="h1"
                        component="h2"
                        className={styles.areYouSure}>
                        {!partner
                            ? t('home.calendar.partOfARepeat')
                            : t('home.calendar.partOfARepeatWith', {
                                  first_name: partner.first_name,
                                  last_name_initial: `${partner.last_name_initial}.`,
                              })}
                    </Typography>
                    {editingSession &&
                        editingSession?.participants?.length > 1 && (
                            <Stack
                                direction="row"
                                spacing={2}
                                alignItems="center">
                                <Avatar
                                    sx={{my: 2}}
                                    src={
                                        (partner && partner.avatar) ||
                                        '/broken-image.jpg'
                                    }
                                />
                                <Stack>
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}>
                                        <Typography
                                            variant="h1"
                                            component="h2"
                                            className={
                                                styles.otherParticipantName
                                            }>
                                            {partner &&
                                                `${partner.first_name} ${partner.last_name_initial}.`}
                                        </Typography>
                                        {partner && partner.rating > 0 && (
                                            <Box
                                                className={
                                                    styles.participantRating
                                                }>
                                                <Star
                                                    width={10}
                                                    height={10}
                                                    style={{
                                                        minWidth: 10,
                                                        minHeight: 10,
                                                    }}
                                                />
                                                <Typography
                                                    variant="h1"
                                                    component="h2"
                                                    className={styles.rating}>
                                                    {partner &&
                                                        partner.rating
                                                            ?.toString()
                                                            ?.substring(0, 3)}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Stack>
                                    <Typography
                                        variant="h1"
                                        component="h2"
                                        className={
                                            styles.otherParticipantSessionsCompleted
                                        }>
                                        {partner && partner.completed_sessions}{' '}
                                        '
                                        {t(
                                            'home.deleteModal.sessionsCompleted',
                                        )}
                                        '
                                    </Typography>
                                </Stack>
                            </Stack>
                        )}
                    {(editingSession?.focus_session_repeat_parent ||
                        editingSession?.repeat_config ||
                        focusSessionRepeatConfig?.id) && (
                        <FormControl sx={{mt: 2}}>
                            <FormLabel
                                className={styles.areYouSure}
                                style={{
                                    fontSize: 18,
                                    color: theme.palette.text.primary,
                                }}>
                                {t('home.calendar.updateRecurringEvent')}
                            </FormLabel>
                            <RadioGroup
                                defaultValue={
                                    chooseFocusSessionChooseUpdateRepeatType?.type ||
                                    'THIS_SESSION'
                                }
                                onChange={event => {
                                    dispatch(
                                        setChooseRepeatSessionUpdateType({
                                            open: true,
                                            type: event.target.value as
                                                | 'THIS_SESSION'
                                                | 'THIS_AND_FOLLOWING_SESSIONS',
                                            sessionData:
                                                chooseFocusSessionChooseUpdateRepeatType?.sessionData,
                                        }),
                                    );
                                }}>
                                <FormControlLabel
                                    className={styles.eventTypes}
                                    value="THIS_SESSION"
                                    control={<Radio />}
                                    label={
                                        t(
                                            'home.deleteModal.thisSession',
                                        ) as string
                                    }
                                />
                                <FormControlLabel
                                    className={styles.eventTypes}
                                    value="THIS_AND_FOLLOWING_SESSIONS"
                                    control={
                                        <Radio sx={{pointerEvents: 'none'}} />
                                    }
                                    label={
                                        t(
                                            'home.calendar.thisAndFutureSessions',
                                        ) as string
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    )}
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{
                            flexGrow: 1,
                            alignItems: 'flex-end',
                            mt: 2,
                            gap: 2,
                        }}>
                        <Button
                            className={styles.deleteButton}
                            variant="contained"
                            disabled={loading.action}
                            onClick={handleUpdateSessionWithRepeat}>
                            {loading.action ? (
                                <CircularProgress
                                    size={15}
                                    sx={{color: '#fff'}}
                                />
                            ) : (
                                t('common.update')
                            )}
                        </Button>
                        <Button
                            className={styles.cancelButton}
                            onClick={handleCancelSessionUpdateWithRepeat}>
                            {t('home.deleteModal.cancel')}
                        </Button>
                    </Stack>
                </Box>
            </ReusableModalWindow>
        );
    },
);
