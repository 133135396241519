import {memo, useState} from 'react';
import {
    Box,
    Button,
    CircularProgress,
    TextField,
    useTheme,
    Typography,
} from '@mui/material';
import {useTranslation} from 'react-i18next';

import {ReusableModalWindow} from 'components';
import {FormsService} from 'services';
import {useAppDispatch} from 'store';
import {showToastAction} from 'store/actions/toast';
import styles from './styles.module.scss';

export const Feedback = memo(
    ({open, setOpen}: {open: boolean; setOpen: () => void}) => {
        const [feedback, setFeedback] = useState<string>('');
        const [message, setMessage] = useState<string>('');
        const theme = useTheme();
        const dispatch = useAppDispatch();
        const {t} = useTranslation();
        const [loading, setLoading] = useState<boolean>(false);

        const sendFeedback = async () => {
            try {
                if (feedback.length <= 0) {
                    setMessage(t('home.feedback.leaveFeedback'));
                    return;
                }
                setLoading(true);
                const {data} = await FormsService.postForm('feedback', {
                    title: 'Feedback',
                    data: {
                        message: feedback,
                    },
                });

                if (data) {
                    dispatch(
                        showToastAction({
                            open: true,
                            vertical: 'top',
                            horizontal: 'center',
                            title: t('feedback.feedbackSent'),
                            subTitle: t('feedback.weReceivedYourFeedback'),
                        }),
                    );
                    setLoading(false);
                    setMessage('');
                    setFeedback('');
                }
            } catch (error) {
                console.log({error});
                setMessage(t('feedback.feedbackWasNotSent'));
                dispatch(
                    showToastAction({
                        open: true,
                        vertical: 'top',
                        horizontal: 'center',
                        title: t('feedback.feedbackWasNotSent'),
                        subTitle: t('common.pleaseTryAgain'),
                        icon: 'error',
                    }),
                );
            }
        };

        return (
            <ReusableModalWindow
                open={open}
                height={'auto'}
                width={517}
                setOpen={setOpen}
                alignItems="flex-start">
                <Box className={styles.boxWrapper}>
                    <Typography
                        className={styles.giveFeedBack}
                        sx={{
                            color:
                                theme.palette.mode === 'light'
                                    ? 'rgba(0, 0, 0, 0.85)'
                                    : '#fff7ea',
                        }}>
                        {t('home.feedback.helpUsImprove')}
                    </Typography>
                    <Typography className={styles.description}>
                        {t('home.feedback.currentlyRunningPublic')}
                    </Typography>
                    <Typography className={styles.description}>
                        {t('home.feedback.yourFeedbackIsValuable')}
                    </Typography>
                    <TextField
                        id="feedback_input"
                        variant="standard"
                        value={feedback}
                        error={message.length > 0}
                        helperText={message}
                        onChange={event => {
                            setFeedback(event.target.value);
                            setMessage('');
                        }}
                        multiline
                        minRows={2}
                        className={styles.feedbackInput}
                        fullWidth
                        placeholder={t('home.feedback.yourSuggestion')}
                    />
                    <Button
                        onClick={sendFeedback}
                        disabled={loading}
                        className={styles.sendFeedback}>
                        {loading ? (
                            <CircularProgress size={15} />
                        ) : (
                            t('home.feedback.sendFeedback')
                        )}
                    </Button>
                </Box>
            </ReusableModalWindow>
        );
    },
);
