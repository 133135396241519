import {ICompany} from '../company/types';
import {
    IAuthUserSetAction,
    IAuthUserLogoutSetAction,
    IAuthUserProfileSetAction,
    IAuthUserProfileAvatarSetAction,
    IAuthUserFavouritesUsersSetAction,
    ISetTempRegisteredPasswordAction,
    ISetAuthTourCurrentStepAction,
    ISetAuthUserProfileAction,
    ISetAuthCompanyAddressAction,
    ISetIsWelcomeVideoOpenAction,
    IAuthShowNewSessionModalAction,
    ISetAuthUserIsPremiumAction,
} from 'models';

export enum EAuthTypes {
    AUTH_LOGIN_ACTION_SAGA = 'AUTH/LOGIN/ACTION/SAGA',
    AUTH_LOGIN_ACTION_SET = 'AUTH/LOGIN/ACTION/SET',
    AUTH_REGISTER_ACTION_SAGA = 'AUTH/REGISTER/ACTION/SAGA',
    AUTH_REGISTER_ACTION_SET = 'AUTH/REGISTER/ACTION/SET',
    AUTH_LOGOUT_ACTION_SAGA = 'AUTH/LOGOUT/ACTION/SAGA',
    AUTH_LOGOUT_ACTION_SET = 'AUTH/LOGOUT/ACTION/SET',
    AUTH_FORGOT_PASSWORD = 'AUTH/FORGOT/PASSWORD',
    AUTH_RESET_PASSWORD = 'AUTH/RESET/PASSWORD',
    AUTH_UPDATE_PROFILE_SAGA = 'AUTH/UPDATE/PROFILE/SAGA',
    AUTH_SET_USER = 'AUTH/USER/SET',
    AUTH_GET_USER_SAGA = 'AUTH/GET/USER/SAGA',
    AUTH_SET_USER_AVATAR = 'AUTH/SET/USER/AVATAR',
    OAUTH_LOG_USER_SAGA = 'OAUTH/LOG/USER/SAGA',
    AUTH_RENEW_TOKEN_SAGA = 'AUTH_RENEW_TOKEN_SAGA',
    AUTH_TOUR_COMPLETED_SAGA = 'AUTH_TOUR_COMPLETED_SAGA',
    OAUTH_GENERATE_STATE_SAGA = 'OAUTH/GENERATE/STATE/SAGA',
    UPDATE_AUTH_USER_SAGA = 'UPDATE/AUTH/USER/SAGA',
    GET_AUTH_USER_FAVOURITES_SAGA = 'GET/AUTH/USER/FAVORITES/SAGA',
    SET_AUTH_USER_FAVOURITES = 'SET/AUTH/USER/FAVOURITES',
    DELETE_AUTH_USER_FAVOURITE_USER_SAGA = 'DELETE/AUTH/USER/FAVOURITE/USER/SAGA',
    GET_AUTH_USER_ABUSE_REPORTS_SAGA = 'GET/AUTH/USER/ABUSE/REPORT/SAGA',
    SET_AUTH_USER_REGISTERED_PASSWORD = 'SET/AUTH/USER/REGISTERED/PASSWORD',
    AUTH_SET_TOUR_CURRENT_STEP = 'AUTH/SET/TOUR/CURRENT/STEP',
    AUTH_USER_PATCH_PROFILE_SAGA = 'AUTH/USER/PATCH/PROFILE/SAGA',
    AUTH_USER_SET_PROFILE_ACTION = 'AUTH/USER/SET/PROFILE/ACTION',
    AUTH_SET_COMPANY_ADDRESS = 'AUTH/SET/COMPANY/ADDRESS',
    SET_IS_WELCOME_VIDEO_OPEN = 'SET/IS/WELCOME/VIDEO/OPEN',
    AUTH_RESEND_VERIFICATION_EMAIL_ACTION_SAGA = 'AUTH/RESEND/VERIFICATION/EMAIL/ACTION/SAGA',
    AUTH_SHOW_NEW_SESSION_MODAL_ACTION = 'AUTH/SHOW/NEW/SESSION/MODAL/ACTION',
    SET_AUTH_USER_IS_PREMIUM_ACTION = 'SET/AUTH/USER/IS/PREMIUM/ACTION',
}

export type TGender = 'MALE' | 'FEMALE' | 'OTHER' | 'RATHER_NOT_SAY';

export interface IUserData {
    avatar?: string;
    created?: Date;
    email?: string;
    first_name?: string;
    id?: number;
    last_name?: string;
    last_name_initial?: string;
    rating?: number;
    timezone?: string;
    tour_complete?: Date;
    profile_complete?: boolean;
    completed_sessions?: number;
    timezone_verified?: Date;
    gender?: TGender | null;
    profile_url?: string | null;
    profile?: IAuthUserProfile;
    datetime_preference?: {
        date: string;
        time: string;
    };
    company_membership?: LocalCompany | undefined;
    message?: {
        text: string;
        dismissible: boolean;
        action: string;
    };
    is_premium?: boolean;
}

export interface IUserFavourites {
    user_from: number;
    user_to: number;
    created_at: Date;
    user: {
        id: number;
        first_name: string;
        last_name_initial: string;
        avatar: string;
        rating: number;
        completed_sessions: number;
        profile_url: string;
        is_premium: boolean;
    };
}

export interface IUser {
    expiry?: string;
    token?: string;
    user?: IUserData;
    favourites?: IUserFavourites[] | [];
}

export interface LocalCompany extends ICompany {
    admin: boolean;
}

export interface IAuthUserProfile {
    avatar?: string;
    created?: Date;
    email?: string;
    first_name?: string;
    id?: number;
    last_name?: string;
    last_name_initial?: string;
    rating?: number;
    timezone?: string;
    tour_complete?: Date;
    profile_complete?: boolean;
    completed_sessions?: number;
    timezone_verified?: Date;
    gender?: TGender | null;
    profile_url?: string | null;
    dark_mode?: 'light' | 'dark' | 'system';
    sidebar_open?: boolean;
    language?: 'en' | 'ro' | 'es' | 'pt';
    show_new_session_modal?: boolean;
}

export interface IAuthUserState {
    data: IUser;
    isWelcomeVideoOpen: boolean;
    temp?:
        | {
              password: string | undefined;
          }
        | undefined;
    tourCurrentStep: number | undefined;
}

export type TAuthActionTypes =
    | IAuthUserSetAction
    | IAuthUserLogoutSetAction
    | IAuthUserProfileSetAction
    | IAuthUserProfileAvatarSetAction
    | IAuthUserFavouritesUsersSetAction
    | ISetTempRegisteredPasswordAction
    | ISetAuthTourCurrentStepAction
    | ISetAuthUserProfileAction
    | ISetAuthCompanyAddressAction
    | ISetIsWelcomeVideoOpenAction
    | IAuthShowNewSessionModalAction
    | ISetAuthUserIsPremiumAction;
