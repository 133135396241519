import {EMusicPlayerTypes, IMusicPlayer, TMusicPlayerActionTypes} from 'models';

const initialState: IMusicPlayer = {
    whiteSounds: undefined,
    data: {
        time: 0,
        volume: 30,
        whiteSound: undefined,
        isPlaying: {
            state: false,
            lastPlayedSongUrl: '',
            currentSong: '',
        },
    },
};

/**
 * @param {IMusicPlayer} [state=initialState]
 * @param {TMusicPlayerActionTypes} action
 * @return {*}  {IMusicPlayer}
 */
const musicPlayerReducer = (
    state: IMusicPlayer = initialState,
    action: TMusicPlayerActionTypes,
): IMusicPlayer => {
    switch (action.type) {
        case EMusicPlayerTypes.INCREASE_PLAYER_TIME_PLAYED: {
            return {
                ...state,
                data: {
                    ...state.data,
                    time: action.payload,
                },
            };
        }
        case EMusicPlayerTypes.SET_WHITE_SOUNDS: {
            return {
                ...state,
                whiteSounds: action.payload,
            };
        }

        case EMusicPlayerTypes.SET_VOLUME_VALUE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    volume: action.payload,
                },
            };
        }

        case EMusicPlayerTypes.SET_WHITE_SOUND: {
            return {
                ...state,
                data: {
                    ...state.data,
                    whiteSound: action.payload,
                },
            };
        }

        case EMusicPlayerTypes.SET_WHITE_SOUND_PLAYING: {
            return {
                ...state,
                data: {
                    ...state.data,
                    isPlaying: action.payload,
                },
            };
        }

        default:
            return state;
    }
};

export default musicPlayerReducer;
