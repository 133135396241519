import { lazy, LazyExoticComponent } from 'react';

/**
 * @param {() => any} componentImport
 * @return {*}  {Promise<{default: () => any}>}
 */
export const lazyRetry = function (
    // * a function to retry loading a chunk to avoid chunk load error for out of date code
    componentImport: () => any,
): Promise<{ default: () => any }> {
    return new Promise((resolve, reject) => {
        // * Check if the window has already been refreshed
        const hasRefreshed = JSON.parse(
            window.sessionStorage.getItem('retry-lazy-refreshed') || 'false',
        );
        // try to import the component
        componentImport()
            .then((component: any) => {
                window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
                resolve(component);
            })
            .catch((error: any) => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    window.sessionStorage.setItem(
                        'retry-lazy-refreshed',
                        'true',
                    ); // * We are now going to refresh
                    return window.location.reload(); // refresh the page
                }
                reject(error); // * Default error behavior as already tried refresh
            });
    });
};

export const userRouses = () => {
    let values: {
        path: string;
        component: LazyExoticComponent<() => any>;
        exact: boolean;
    }[] = [];

    values.push(
        {
            path: 'pomodoro',
            component: lazy(() =>
                lazyRetry(() => import('../pages/pomodoro')),
            ),
            exact: true,
        },
        {
            path: '/',
            component: lazy(() => lazyRetry(() => import('../pages/home'))),
            exact: true,
        },
        {
            path: 'white-sounds',
            component: lazy(() =>
                lazyRetry(() => import('../pages/whiteSounds')),
            ),
            exact: true,
        },
        {
            path: 'after-session/:sessionId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/afterSession')),
            ),
            exact: true,
        },
        {
            path: 'favourites',
            component: lazy(() =>
                lazyRetry(() => import('../pages/favourites')),
            ),
            exact: true,
        },
        {
            path: 'blocked-users',
            component: lazy(() =>
                lazyRetry(() => import('../pages/blockedUsers')),
            ),
            exact: true,
        },
        {
            path: 'video-call/rooms',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'video-call/rooms/lobby',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        {
            path: 'focus-session/:sessionId/call',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'public/room/:roomId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'public/lobby/:roomId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        {
            path: 'focus-session/:sessionId/lobby',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        {
            path: 'test-session/room',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'test-session/lobby',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        {
            path: 'meeting/:meetingUUID/lobby',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        {
            path: 'meeting/:meetingUUID',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: "subscriptions",
            component: lazy(() =>
                lazyRetry(() => import('../pages/subscription')),
            ),
            exact: true,
        },
        {
            path: "subscription-plans",
            component: lazy(() =>
                lazyRetry(() => import('../pages/subscriptionPlans')),
            ),
            exact: true,
        },
        {
            path: 'payment-status',
            component: lazy(() =>
                lazyRetry(() => import('../pages/paymentStatus')),
            ),
            exact: true,
        },
        {
            path: 'setup-status',
            component: lazy(() =>
                lazyRetry(() => import('../pages/setupStatus')),
            ),
            exact: true,
        },
        {
            path: 'do-action',
            component: lazy(() =>
                lazyRetry(() => import('../pages/doAction')),
            ),
            exact: true,
        },
        {
            path: 'become-an-organization',
            component: lazy(() =>
                lazyRetry(() => import('../pages/becomeAnOrganization')),
            ),
            exact: true,
        },
        {
            path: 'settings',
            component: lazy(() =>
                lazyRetry(() => import('../pages/account/settings')),
            ),
            exact: true,
        },
        {
            path: 'leaderboard',
            component: lazy(() =>
                lazyRetry(() => import('../pages/leaderBoard')),
            ),
            exact: true,
        },
        {
            path: 'news',
            component: lazy(() =>
                lazyRetry(() => import('../pages/latestNews')),
            ),
            exact: true,
        },
        {
            path: 'user/:slug',
            component: lazy(() =>
                lazyRetry(() => import('../pages/profile')),
            ),
            exact: true,
        },
        {
            path: 'groups',
            component: lazy(() => lazyRetry(() => import('../pages/groups'))),
            exact: true
        },
        {
            path: 'groups/:id',
            component: lazy(() => lazyRetry(() => import('../pages/groupDetails'))),
            exact: true
        }
    );


    return values;
};

export const companyRoutes = (
    companyAdmin: boolean,
) => {
    let values: {
        path: string;
        component: LazyExoticComponent<() => any>;
        exact: boolean;
    }[] = [];

    values.push(
        {
            path: 'dashboard',
            component: companyAdmin
                ? lazy(() =>
                    lazyRetry(
                        () =>
                            import(
                                '../pages/company/components/companyDashboard'
                            ),
                    ),
                )
                : lazy(() => lazyRetry(() => import('../pages/company'))),
            exact: true,
        },
        {
            path: 'rooms',
            component: lazy(() =>
                lazyRetry(
                    () => import('../pages/company/components/rooms'),
                ),
            ),
            exact: true,
        },
        {
            path: 'pomodoro',
            component: lazy(() =>
                lazyRetry(() => import('../pages/pomodoro')),
            ),
            exact: true,
        },
        {
            path: '/',
            component: lazy(() => lazyRetry(() => import('../pages/home'))),
            exact: true,
        },
        {
            path: 'white-sounds',
            component: lazy(() =>
                lazyRetry(() => import('../pages/whiteSounds')),
            ),
            exact: true,
        },
        {
            path: 'after-session/:sessionId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/afterSession')),
            ),
            exact: true,
        },
        {
            path: 'favourites',
            component: lazy(() =>
                lazyRetry(() => import('../pages/favourites')),
            ),
            exact: true,
        },
        {
            path: 'blocked-users',
            component: lazy(() =>
                lazyRetry(() => import('../pages/blockedUsers')),
            ),
            exact: true,
        },
        // * Company rooms
        {
            path: 'companies/:companyId/room/:roomId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'companies/:companyId/room/:roomId/breakout/:name',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'members',
            component: lazy(() =>
                lazyRetry(
                    () => import('../pages/company/components/members'),
                ),
            ),
            exact: true,
        },
        {
            path: 'company/members/:id',
            component: lazy(() =>
                lazyRetry(() => import('../pages/company')),
            ),
            exact: true,
        },
        {
            path: 'companies/:companyId/lobby/:roomId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        // * Private Rooms
        {
            path: 'private/room/:roomId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'private/lobby/:roomId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        // * Public
        {
            path: 'public/room/:roomId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'public/lobby/:roomId',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        {
            path: 'test-session/room',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'test-session/lobby',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        {
            path: 'focus-session/:sessionId/call',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'focus-session/:sessionId/lobby',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        {
            path: 'meeting/:meetingUUID/lobby',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms/components/lobby')),
            ),
            exact: true,
        },
        {
            path: 'meeting/:meetingUUID',
            component: lazy(() =>
                lazyRetry(() => import('../pages/rooms')),
            ),
            exact: true,
        },
        {
            path: 'meetings',
            component: lazy(() =>
                lazyRetry(() => import('../pages/meetings')),
            ),
            exact: true,
        },
        {
            path: 'settings',
            component: lazy(() =>
                lazyRetry(() => import('../pages/account/settings')),
            ),
            exact: true,
        },
        {
            path: "subscriptions",
            component: lazy(() =>
                lazyRetry(() => import('../pages/subscription')),
            ),
            exact: true,
        },
        {
            path: "subscription-plans",
            component: lazy(() =>
                lazyRetry(() => import('../pages/subscriptionPlans')),
            ),
            exact: true,
        },
        {
            path: 'payment-status',
            component: lazy(() =>
                lazyRetry(() => import('../pages/paymentStatus')),
            ),
            exact: true,
        },
        {
            path: 'leaderboard',
            component: lazy(() =>
                lazyRetry(() => import('../pages/leaderBoard')),
            ),
            exact: true,
        },
        {
            path: 'news',
            component: lazy(() =>
                lazyRetry(() => import('../pages/latestNews')),
            ),
            exact: true,
        },
        {
            path: 'setup-status',
            component: lazy(() =>
                lazyRetry(() => import('../pages/setupStatus')),
            ),
            exact: true,
        },
        {
            path: 'do-action',
            component: lazy(() =>
                lazyRetry(() => import('../pages/doAction')),
            ),
            exact: true,
        },
        {
            path: 'user/:slug',
            component: lazy(() =>
                lazyRetry(() => import('../pages/profile')),
            ),
            exact: true,
        },
        {
            path: 'groups',
            component: lazy(() => lazyRetry(() => import('../pages/groups'))),
            exact: true
        },
        {
            path: 'groups/:id',
            component: lazy(() => lazyRetry(() => import('../pages/groupDetails'))),
            exact: true
        }
    );

    return values;
};
