import {all, put, takeLatest} from 'redux-saga/effects';
import {AxiosResponse} from 'axios';
import * as Sentry from '@sentry/react';

import {EMusicPlayerTypes} from 'models';
import {WhiteSoundsService} from 'services/whiteSounds';
import {
    getWhiteSoundsSaga,
    increaseWhiteSoundPlayedSaga,
    setWhiteSoundsAction,
} from '../../actions/musicPlayer/index';

function* handleGetWhiteSounds({
    payload,
}: ReturnType<typeof getWhiteSoundsSaga>) {
    try {
        const {data}: AxiosResponse = yield WhiteSoundsService.getWhiteSounds();
        if (data) {
            yield put(setWhiteSoundsAction(data));
            payload.callbackOnSuccess && payload.callbackOnSuccess();
        }
    } catch (error: any) {
        console.log({error});
        payload.callbackOnError && payload.callbackOnError();
        Sentry.captureException(error);
    }
}

function* handleIncreaseWhiteSoundPlayed({
    payload,
}: ReturnType<typeof increaseWhiteSoundPlayedSaga>) {
    try {
        yield WhiteSoundsService.getWhiteSoundsPlayed({
            whiteSoundsId: payload?.whiteSoundId,
        });
    } catch (error: any) {
        console.log({error});
        Sentry.captureException(error);
    }
}

export function* WhiteSoundsSagas(): Generator<unknown, any, undefined> {
    return yield all([
        takeLatest(
            EMusicPlayerTypes.GET_WHITE_SOUNDS_SAGA,
            handleGetWhiteSounds,
        ),
        takeLatest(
            EMusicPlayerTypes.INCREASE_WHITE_SOUND_PLAYED_SAGA,
            handleIncreaseWhiteSoundPlayed,
        ),
    ]);
}
