import {IToast} from 'utils/interfaces';
import {IToastSetAction} from './actions';

export enum EToastTypes {
    SHOW_TOAST_ELEMENT = 'SHOW/TOAST/ELEMENT',
}

export interface IToastSate {
    data: IToast;
}

export type TToastActionTypes = IToastSetAction;
