import {EWSActionTypes, EWSTypes} from 'models';

export const setWSReconnectIntervalAction = (payload: {interval: number}) => ({
    type: EWSActionTypes.SET_WS_RECONNECT_INTERVAL,
    payload,
});

export const setWSReconnectAttemptsAction = (payload: {number: number}) => ({
    type: EWSActionTypes.SET_WS_RECONNECT_ATTEMPTS,
    payload,
});

export const setWSEventAction = (payload: {
    event: {
        type: EWSTypes;
        data: Record<string, unknown>;
    };
}) => ({
    type: EWSActionTypes.SET_WS_EVENT,
    payload,
});
