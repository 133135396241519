import {createTheme} from '@mui/material/styles';
import OpenSansRegular from '../assets/fonts/OpenSans/OpenSans-Regular.ttf';

import ProximaNovaReg from '../assets/fonts/ProximaNova/ProximaNova-Regular.ttf'; // 400
// @ts-ignore
import ProximaNovaThin from '../assets/fonts/ProximaNova/ProximaNova-Thin.otf'; // 100
// @ts-ignore
import ProximaNovaExtraBold from '../assets/fonts/ProximaNova/ProximaNova-Extrabold.otf'; // 800
// @ts-ignore
import ProximaNovaBlack from '../assets/fonts/ProximaNova/ProximaNova-Black.otf'; // 800
// @ts-ignore
import ProximaNovaBold from '../assets/fonts/ProximaNova/ProximaNova-Bold.otf'; // 800

// @ts-ignore
import BrandonGrotesqueThin from '../assets/fonts/Brandon-Grotesque/Brandon_thin.otf'; // 100
// @ts-ignore
import BrandonGrotesqueThinIt from '../assets/fonts/Brandon-Grotesque/Brandon_thin_it.otf'; // 100
// @ts-ignore
import BrandonGrotesqueLightIt from '../assets/fonts/Brandon-Grotesque/Brandon_light_it.otf'; // 300
// @ts-ignore
import BrandonGrotesqueLight from '../assets/fonts/Brandon-Grotesque/Brandon_light.otf'; // 300
// @ts-ignore
import BrandonGrotesqueIt from '../assets/fonts/Brandon-Grotesque/Brandon_reg_it.otf'; // 400
// @ts-ignore
import BrandonGrotesque from '../assets/fonts/Brandon-Grotesque/Brandon_reg.otf'; // 400
// @ts-ignore
import BrandonGrotesqueMedIt from '../assets/fonts/Brandon-Grotesque/Brandon_med_it.otf'; // 500
// @ts-ignore
import BrandonGrotesqueMed from '../assets/fonts/Brandon-Grotesque/Brandon_med.otf'; // 500
// @ts-ignore
import BrandonGrotesqueBoldIt from '../assets/fonts/Brandon-Grotesque/Brandon_bld_it.otf'; // 700
// @ts-ignore
import BrandonGrotesqueBold from '../assets/fonts/Brandon-Grotesque/Brandon_bld.otf'; // 700
// @ts-ignore
import BrandonGrotesqueBlackIt from '../assets/fonts/Brandon-Grotesque/Brandon_blk_it.otf'; // 900
// @ts-ignore
import BrandonGrotesqueBlack from '../assets/fonts/Brandon-Grotesque/Brandon_blk.otf'; // 900

const theme = createTheme({
    palette: {
        primary: {
            main: '#FF9A00',
        },
        secondary: {
            main: '#132356',
            light: '#FFCA78',
        },
        background: {
            paper: '#f7f7f7',
            default: '#ffffff',
        },
        text: {
            primary: '#59617b',
            secondary: 'rgba(204, 204, 204, 0.75)',
            disabled: 'rgba(0,0,0,0.85)',
        },
        info: {
            main: '#fff7ea',
            light: '#FFDCA6',
        },
        error: {
            main: '#F14437',
        },
        action: {
            disabledBackground: '#E0E0E0',
            disabled: '#fff',
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
            // xs: 0,
            // sm: 576,
            // md: 768,
            // lg: 992,
            // xl: 1200,
        },
    },
    typography: {
        fontFamily: 'Proxima Nova, OpenSans, Brandon Grotesque',
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: `
    			@font-face {
    				font-family: 'OpenSans';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 400;
    				src: local('OpenSans'), local('OpenSans-Regular'), url(${OpenSansRegular}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                // Proxima Nova
                @font-face {
    				font-family: 'Proxima Nova';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 100;
    				src:  url(${ProximaNovaThin}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Proxima Nova';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 400;
    				src: url(${ProximaNovaReg}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Proxima Nova';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 700;
    				src: url(${ProximaNovaBold}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Proxima Nova';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 800;
    				src:  url(${ProximaNovaExtraBold}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Proxima Nova';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 900;
    				src:  url(${ProximaNovaBlack}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                // Brandon Grotesque
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 100;
    				src: url(${BrandonGrotesqueThin}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: italic;
    				font-display: swap;
    				font-weight: 100;
    				src: url(${BrandonGrotesqueThinIt}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: italic;
    				font-display: swap;
    				font-weight: 300;
    				src: url(${BrandonGrotesqueLightIt}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 300;
    				src: url(${BrandonGrotesqueLight}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
    			@font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 400;
    				src: url(${BrandonGrotesque}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: italic;
    				font-display: swap;
    				font-weight: 400;
    				src: url(${BrandonGrotesqueIt}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 500;
    				src: url(${BrandonGrotesqueMed}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: italic;
    				font-display: swap;
    				font-weight: 500;
    				src: url(${BrandonGrotesqueMedIt}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 700;
    				src: url(${BrandonGrotesqueBold}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: italic;
    				font-display: swap;
    				font-weight: 700;
    				src: url(${BrandonGrotesqueBoldIt}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: normal;
    				font-display: swap;
    				font-weight: 900;
    				src: url(${BrandonGrotesqueBlack}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
                @font-face {
    				font-family: 'Brandon Grotesque';
    				font-style: italic;
    				font-display: swap;
    				font-weight: 900;
    				src: url(${BrandonGrotesqueBlackIt}) format('truetype');
    				unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
    			}
    		`,
        },
    },
});

export default theme;
