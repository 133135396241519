import {
    IAppTimezonesSetAction,
    IAppSetLastRequestFailed,
    IAppSetLoadingAction,
    IAppSetVisibilityAction,
} from 'models';
import {KnownVisibilityStates} from 'react-page-visibility';

export enum EAppTypes {
    APP_META_DATA_TIMEZONES_GET = 'APP/META/DATA/TIMEZONES/GET/SAGA',
    APP_META_DATA_TIMEZONES_SET = 'APP/META/DATA/TIMEZONES/SET/ACTION',
    APP_LAST_REQUEST_FAILED_SET_ACTION = 'APP/LAST/REQUEST/FAILED/SET/ACTION',
    APP_LOADING_ACTION = 'APP/LOADING/ACTION',
    APP_SET_VISIBILITY_ACTION = 'APP/SET/VISIBILITY/ACTION',
}

export interface IAppState {
    lastRequestsFailed: string[];
    data: {
        timezones: string[] | undefined;
    };
    loading: boolean;
    appVisibility: {
        isVisible: boolean;
        visibilityState: KnownVisibilityStates;
    };
}

export type TAppActionTypes =
    | IAppTimezonesSetAction
    | IAppSetLastRequestFailed
    | IAppSetLoadingAction
    | IAppSetVisibilityAction;
