// import { ReportHandler } from 'web-vitals';

// const reportWebVitals = (onPerfEntry?: ReportHandler) => {
//   if (onPerfEntry && onPerfEntry instanceof Function) {
//     import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
//       getCLS(onPerfEntry);
//       getFID(onPerfEntry);
//       getFCP(onPerfEntry);
//       getLCP(onPerfEntry);
//       getTTFB(onPerfEntry);
//     });
//   }
// };

// export default reportWebVitals;

import {Metric} from 'web-vitals';

import {onCLS, onFID, onLCP, onFCP, onINP, onTTFB} from 'web-vitals';

function reportHandler(metric?: Metric): void {
    if (!metric) return;

    const {id, delta, name, value} = metric;

    // Assumes the global `gtag()` function exists, see:
    // https://developers.google.com/analytics/devguides/collection/gtagjs
    (window as any).gtag('event', name, {
        value: delta,
        non_interaction: true,
        metric_id: id, // Needed to aggregate events.
        metric_value: value, // Optional.
        metric_delta: delta, // Optional.
        // OPTIONAL: any additional attribution params here.
        // See: https://web.dev/debug-performance-in-the-field/
        // dimension1: '...',
        // dimension2: '...',
        // ...
    });
}

onCLS(reportHandler);
onFID(reportHandler);
onLCP(reportHandler);
onFCP(reportHandler);
onINP(reportHandler);
onTTFB(reportHandler);

export default reportHandler;
