import {memo, useState, useMemo} from 'react';
import {
    Button,
    Typography,
    Box,
    useTheme,
    CircularProgress,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import {useLocation, useHistory} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {IGroupInvite} from 'models';
import {ReusableModalWindow} from 'components';
import styles from './styles.module.scss';
import {GroupsService} from 'services';
import {showToastAction, useAppDispatch} from 'store';

export const GroupInviteModal = memo(({data}: {data: IGroupInvite}) => {
    const theme = useTheme();
    const [loading, setLoading] = useState<'ACCEPT' | 'REJECT' | ''>('');
    const {t} = useTranslation();
    const dispatch = useAppDispatch();
    const history = useHistory();
    const location = useLocation();

    const searchParams = useMemo(
        () => new URLSearchParams(location.search),
        [location.search],
    );

    const onReject = async () => {
        try {
            setLoading('REJECT');
            await GroupsService.postGroupInvitesReject({
                id: searchParams.get('user_group_id') as string,
                invite_id: searchParams.get('invite_id') as string,
            });

            history.push('/groups');
            dispatch(
                showToastAction({
                    open: true,
                    horizontal: 'center',
                    subTitle: 'Group invite reject',
                    title: 'The invite to join the group has been rejected successfully',
                    vertical: 'top',
                }),
            );
        } catch (error) {
            Sentry.captureException(error);
            console.log({error});
            dispatch(
                showToastAction({
                    title: t('common.anErrorHasOccurred'),
                    subTitle: t('common.pleaseTryAgain'),
                    open: true,
                    vertical: 'top',
                    horizontal: 'center',
                    icon: 'error',
                }),
            );
        } finally {
            setLoading('');
        }
    };

    const onAccept = async () => {
        try {
            setLoading('ACCEPT');

            await GroupsService.postGroupInvitesAccept({
                id: searchParams.get('user_group_id') as string,
                invite_id: searchParams.get('invite_id') as string,
            });

            history.push('/groups');
            dispatch(
                showToastAction({
                    open: true,
                    horizontal: 'center',
                    subTitle: 'Group invite accepted',
                    title: 'The invite to join the group has been accepted successfully',
                    vertical: 'top',
                }),
            );
        } catch (error) {
            Sentry.captureException(error);
            console.log({error});
            dispatch(
                showToastAction({
                    title: t('common.anErrorHasOccurred'),
                    subTitle: t('common.pleaseTryAgain'),
                    open: true,
                    vertical: 'top',
                    horizontal: 'center',
                    icon: 'error',
                }),
            );
        } finally {
            setLoading('');
        }
    };

    return (
        <ReusableModalWindow
            open={true}
            setOpen={() => {
                history.push('/');
            }}
            height={'auto'}
            alignItems="flex-start">
            <Typography
                className={styles.title}
                sx={{
                    color:
                        theme.palette.mode === 'light'
                            ? 'rgba(0, 0, 0, 0.85)'
                            : '#fff7ea',
                }}>
                Group invitation
            </Typography>
            {data.accepted_at || data.rejected_at ? (
                <Typography
                    className={styles.subtitle}
                    variant="subtitle1"
                    component="sub">
                    The invite was already{' '}
                    {data.accepted_at ? (
                        <strong>accepted</strong>
                    ) : (
                        <strong>rejected</strong>
                    )}
                </Typography>
            ) : (
                <>
                    <Typography
                        className={styles.subtitle}
                        variant="subtitle1"
                        component="sub">
                        You've been invited to join a group
                    </Typography>
                    <Box className={styles.actionsContainer}>
                        <Button
                            variant={'contained'}
                            disabled={loading === 'ACCEPT'}
                            className={styles.accept}
                            onClick={onAccept}>
                            {loading === 'ACCEPT' ? (
                                <CircularProgress size={15} />
                            ) : (
                                t('common.accept').toUpperCase()
                            )}
                        </Button>
                        <Button
                            variant={'outlined'}
                            disabled={loading === 'REJECT'}
                            className={styles.decline}
                            onClick={onReject}>
                            {loading === 'REJECT' ? (
                                <CircularProgress size={15} />
                            ) : (
                                t('common.reject').toUpperCase()
                            )}
                        </Button>
                    </Box>
                </>
            )}
        </ReusableModalWindow>
    );
});
