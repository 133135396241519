import {
    InputAdornment,
    Paper,
    Box,
    Typography,
    CircularProgress,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import {useTheme} from '@mui/material/styles';
import {UseFormTrigger} from 'react-hook-form';

import './styles.module.scss';

export const AutoCompleteInput = ({
    value,
    setValue,
    options,
    defaultValue,
    error,
    multiple,
    placeholder,
    renderCustom,
    renderCustomPhone,
    helperText,
    sx,
    customKey,
    loading,
    disabled,
    trigger,
    label,
}: {
    value: any;
    setValue: any;
    options: any[];
    defaultValue: any;
    error: boolean;
    multiple: boolean;
    placeholder: string;
    renderCustom?: boolean;
    renderCustomPhone?: boolean;
    helperText?: string;
    sx?: Record<string, any>;
    customKey?: string;
    loading?: boolean;
    disabled?: boolean;
    trigger?: UseFormTrigger<any>;
    label?: string;
}) => {
    const theme = useTheme();

    return (
        <Autocomplete
            disabled={disabled}
            className="autoCompleteInput"
            freeSolo
            multiple={multiple}
            disableClearable
            fullWidth
            key={customKey ? customKey : Date.now()}
            sx={{...sx}}
            PaperComponent={({children}) => (
                <Paper
                    style={{
                        background:
                            theme.palette.mode === 'dark'
                                ? '#1b1e24'
                                : '#FFFFFF',
                    }}>
                    {children}
                </Paper>
            )}
            renderOption={(props, option: any) => {
                if (renderCustom) {
                    return (
                        <Box
                            key={option?.code}
                            component="li"
                            sx={{'& > img': {mr: 2, flexShrink: 0}}}
                            {...props}>
                            {option?.code ? (
                                <img
                                    loading="lazy"
                                    width="20"
                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                    alt="country"
                                />
                            ) : option?.avatar ? (
                                <img
                                    loading="lazy"
                                    width="25"
                                    height="25"
                                    style={{borderRadius: '50px'}}
                                    src={option.avatar}
                                    srcSet={option.avatar}
                                    alt="avatar"
                                />
                            ) : null}
                            {renderCustomPhone ? (
                                <Typography>{`+${option.phone}`}</Typography>
                            ) : (
                                <Typography sx={{textTransform: 'capitalize'}}>
                                    {option.label}
                                </Typography>
                            )}
                        </Box>
                    );
                }
                return null;
            }}
            options={options ?? []}
            onChange={(event, newValue) => {
                setValue(newValue);
                trigger && trigger('companyCountryOrState');
            }}
            value={loading ? '' : defaultValue}
            renderInput={params => (
                <TextField
                    {...params}
                    className="language_box"
                    variant="standard"
                    fullWidth
                    required
                    error={error}
                    helperText={helperText}
                    label={label}
                    placeholder={placeholder}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                        style: {
                            fontSize: renderCustom ? 17 : 'inherit',
                        },
                    }}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <InputAdornment position="end">
                                {loading ? (
                                    <CircularProgress size={15} />
                                ) : (
                                    <svg
                                        width="10"
                                        height="5"
                                        viewBox="0 0 10 5"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M0 0L5 5L10 0H0Z"
                                            fill="#CBCED6"
                                        />
                                    </svg>
                                )}
                            </InputAdornment>
                        ),
                    }}
                    value={value}
                    onChange={e => {
                        setValue(e.target.value);
                    }}
                />
            )}
        />
    );
};
