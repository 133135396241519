import {useEffect, useState} from 'react';
import SpeechRecognition, {
    useSpeechRecognition,
} from 'react-speech-recognition';

export const useVoiceRecognition = () => {
    const {
        // transcript,
        interimTranscript,
        finalTranscript,
        // resetTranscript,
        listening,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
    } = useSpeechRecognition();
    const [userVoiceRecognition, setUserVoiceRecognition] = useState<{
        speaking: boolean;
        words: string;
    }>({
        words: '',
        speaking: false,
    });

    useEffect(() => {
        SpeechRecognition.startListening({
            continuous: true,
            // language: 'en-GB',
        });

        return () => {
            SpeechRecognition.stopListening();
        };
    }, []);

    useEffect(() => {
        if (finalTranscript !== '') {
            setUserVoiceRecognition({
                words: finalTranscript,
                speaking: true,
            });
        }
        setTimeout(() => {
            setUserVoiceRecognition({
                words: finalTranscript,
                speaking: false,
            });
        }, 1000);
    }, [interimTranscript, finalTranscript]);

    return {
        listening,
        browserSupportsSpeechRecognition,
        isMicrophoneAvailable,
        userVoiceRecognition,
    };
};
