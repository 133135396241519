import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';

import auth from './auth';
import appReducer from './app';
import musicPlayerReducer from './musicPlayer';
import focusSessionsReducer from './focusSessions';
import wsReducer from './ws';
import todoReducer from './todo';
import userReducer from './user';
import toastReducer from './toast';
import callReducer from './call';
import companyReducer from './company';
import { EAuthTypes } from '../../models/auth/types';
import paymentsReducer from './payments';
import giftAccountsReducer from './giftAccounts';

const combineReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history as any),
        auth: auth,
        app: appReducer,
        musicPlayer: musicPlayerReducer,
        focusSessions: focusSessionsReducer,
        ws: wsReducer,
        todo: todoReducer,
        user: userReducer,
        toast: toastReducer,
        call: callReducer,
        company: companyReducer,
        payments: paymentsReducer,
        giftAccounts: giftAccountsReducer
    });

export const rootReducer =
    (history: History) =>
        (state: any, action: { type: string; payload?: any }) => {
            if (action.type === EAuthTypes.AUTH_LOGOUT_ACTION_SET) {
                state.app = {
                    data: undefined,
                    lastRequestFailed: undefined,
                    loading: false,
                    appVisibility: undefined,
                    language: state.app.language,
                };
                state.auth = undefined;
                state.call = undefined;
                state.company = undefined;
                state.focusSessions = undefined;
                state.musicPlayer = undefined;
                state.router = undefined;
                state.todo = undefined;
                state.ws = undefined;
            }

            return combineReducer(history)(state, action);
        };

export * from './app';
export * from './auth';
export * from './musicPlayer';
export * from './focusSessions';
export * from './ws';
export * from './todo';
export * from './user';
export * from './toast';
export * from './call';
export * from './company';
export * from './giftAccounts';
