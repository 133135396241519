import { EGiftAccountsTypes, IGiftAccountsState, TGiftAccountsActionTypes } from 'models';

const initialState: IGiftAccountsState = {
    data: {
        from_me: [],
        to_me: []
    },
};

/**
 * @param {IGiftAccountsState} [state=initialState]
 * @param {TGiftAccountsActionTypes} action
 * @return {*}  {IGiftAccountsState}
 */
const giftAccountsReducer = (
    state: IGiftAccountsState = initialState,
    action: TGiftAccountsActionTypes,
): IGiftAccountsState => {
    switch (action.type) {
        case EGiftAccountsTypes.SET_GIFT_ACCOUNTS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    from_me: action.payload.giftAccounts.from_me,
                    to_me: action.payload.giftAccounts.to_me
                },
            };
        }


        default:
            return state;
    }
};

export default giftAccountsReducer;
