import * as moment from 'moment-timezone';

export const newDateWithTimezone = ({
    currentDate,
    timezone,
}: {
    currentDate: Date;
    timezone: string | undefined;
}) => {
    const time = moment.tz(
        new Date(currentDate),
        timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
    );

    const date = new Date(
        new Date(time.format()).toLocaleString('en', {
            timeZone:
                timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
        }),
    );

    return {iso8601: time.format(), date};
};
