import {AxiosResponse} from 'axios';

import tokenInstance from 'utils/axiosInstance/tokenInstance';

export const getWhiteSounds = () => {
    return tokenInstance().get(`white-sounds/`);
};

export const getWhiteSoundsPlayed = ({
    whiteSoundsId,
}: {
    whiteSoundsId: number;
}): Promise<AxiosResponse<any, any>> => {
    return tokenInstance().post(`white-sounds/${whiteSoundsId}/played`);
};

export const WhiteSoundsService = {
    getWhiteSounds,
    getWhiteSoundsPlayed,
};
