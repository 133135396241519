export const getDifference = (date1: any, date2: any) => {
    const diffMs = date2 - date1; // milliseconds between now & Christmas
    const diffDays = Math.floor(diffMs / 86400000); // days
    const diffHrs = Math.floor((diffMs % 86400000) / 3600000); // hours
    const diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000);
    const diffSeconds = (date2.getTime() - date1.getTime()) / 1000;

    return {
        diffMs,
        diffHrs,
        diffMins,
        diffDays,
        diffSeconds,
    };
};
