import {TStore} from 'models';

export const selectWSReconnectInterval = (state: TStore) =>
    state?.ws?.data?.reconnectInterval;

export const selectWSReconnectAttempts = (state: TStore) =>
    state?.ws?.data?.reconnectAttempts;

export const selectWSEvents = (state: TStore) => state?.ws?.events;

export const selectWSLastEvent = (state: TStore) => state?.ws?.lastEvent;
