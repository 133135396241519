import {ICompany, ECompanyTypes, ICompanyAddress} from 'models';

export const setCompanyAction = (payload: ICompany) => ({
    type: ECompanyTypes.SET_COMPANY,
    payload,
});

export const setCompanyAddressAction = (payload: ICompanyAddress) => ({
    type: ECompanyTypes.SET_COMPANY_ADDRESS,
    payload,
});
