import {useCallback, memo, SetStateAction, Dispatch} from 'react';
import {Box, Button, Typography, Link as MuiLink} from '@mui/material';
import {
    WhatsappShareButton,
    TwitterShareButton,
    FacebookMessengerShareButton,
} from 'react-share';
import {createSelector} from 'reselect';
import {useTheme} from '@mui/material/styles';
import {Link} from 'react-router-dom';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {useTranslation} from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';

import {ReusableModalWindow} from 'components/modal';
import {ReactComponent as CoWorkers} from 'assets/svgs/co-workers.svg';
import {ReactComponent as Copy} from 'assets/svgs/copy.svg';
import {ReactComponent as FacebookMessenger} from 'assets/svgs/fb-messenger.svg';
import {ReactComponent as Instagram} from 'assets/svgs/instagram.svg';
import {ReactComponent as Twitter} from 'assets/svgs/twitter.svg';
import {ReactComponent as Whatsapp} from 'assets/svgs/whatsapp.svg';
import {
    useAppSelector,
    selectAuthUserProfileUrl,
    useAppDispatch,
    showToastAction,
} from 'store';

const stateSelectorHandle = createSelector(
    selectAuthUserProfileUrl,
    authUserProfileUrl => {
        return {authUserProfileUrl};
    },
);

export const Invite = memo(
    ({
        openInviteModal,
        setOpenInviteModal,
    }: {
        openInviteModal: boolean;
        setOpenInviteModal: Dispatch<SetStateAction<boolean>>;
    }) => {
        const theme = useTheme();
        const stateSelector = useCallback(stateSelectorHandle, []);
        const {authUserProfileUrl} = useAppSelector(stateSelector);
        const dispatch = useAppDispatch();
        const {t} = useTranslation();
        const mobile = useMediaQuery('(max-width:567px)');
        const message = `
        ${t('invite.about')}
        ${`https://app${
            process.env.REACT_APP_STAGE === 'development' ||
            process.env.REACT_APP_STAGE === 'local'
                ? '.dev'
                : process.env.REACT_APP_STAGE === 'stage'
                ? '.stage'
                : ''
        }.focus101.com/user/${authUserProfileUrl}`}`;

        return (
            <ReusableModalWindow
                open={openInviteModal}
                setOpen={() => setOpenInviteModal(false)}
                height={mobile ? '85%' : 'auto'}
                alignItems="center">
                <Box
                    sx={{
                        my: {xs: 0, sm: 4},
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        height: '100%',
                        width: '100%',
                    }}>
                    <CoWorkers
                        style={{
                            minWidth: 175,
                            minHeight: 120,
                            width: mobile ? 175 : 'auto',
                            height: mobile ? 120 : 'auto',
                        }}
                    />
                    <Typography
                        sx={{
                            textAlign: 'center',
                            my: 2,
                            fontFamily: 'Proxima Nova',
                            fontStyle: 'normal',
                            fontWeight: 700,
                            fontSize: {xs: '20px', sm: '28px'},
                            lineHeight: {xs: '25px', sm: '28px'},
                            color:
                                theme.palette.mode === 'light'
                                    ? 'rgba(0, 0, 0, 0.85)'
                                    : '#fff7ea',
                        }}>
                        {t('invite.invite')}
                    </Typography>
                    <Typography
                        sx={{
                            textAlign: 'center',
                            fontFamily: 'Brandon Grotesque',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: {xs: '16px', sm: '18px'},
                            lineHeight: {xs: '19px', sm: '24px'},
                            color: 'text.primary',
                        }}>
                        {t('invite.share')}
                    </Typography>
                    <Box
                        sx={{
                            my: 2,
                            p: 2,
                            backgroundColor:
                                theme.palette.mode === 'dark'
                                    ? '#392200'
                                    : '#FFFBF5',
                        }}>
                        <Typography
                            sx={{
                                textAlign: 'left',
                                fontFamily: 'Brandon Grotesque',
                                fontStyle: 'normal',
                                fontWeight: 400,
                                fontSize: {xs: '16px', sm: '18px'},
                                lineHeight: {xs: '19px', sm: '24px'},
                            }}>
                            {t('invite.about')}
                            <br />
                            <Link
                                style={{
                                    color: theme.palette.primary.main,
                                    textDecoration: 'none',
                                }}
                                to={`/user/${authUserProfileUrl}`}>{`${`https://app${
                                process.env.REACT_APP_STAGE === 'development' ||
                                process.env.REACT_APP_STAGE === 'local'
                                    ? '.dev'
                                    : process.env.REACT_APP_STAGE === 'stage'
                                    ? '.stage'
                                    : ''
                            }.focus101.com`}/user/${authUserProfileUrl}`}</Link>
                        </Typography>
                        <Box
                            sx={{
                                display: 'block',
                                textAlign: {xs: 'center', sm: 'right'},
                            }}>
                            <CopyToClipboard
                                text={`${message}`}
                                onCopy={() =>
                                    dispatch(
                                        showToastAction({
                                            open: true,
                                            vertical: 'top',
                                            horizontal: 'center',
                                            title: t(
                                                'call.linkCopiedToClipboard',
                                            ),
                                            subTitle: t('invite.shareWith'),
                                        }),
                                    )
                                }>
                                <Button
                                    variant={'contained'}
                                    startIcon={<Copy />}
                                    sx={{
                                        background: '#FFEACA',
                                        mt: 1,
                                        borderRadius: '20px',
                                        boxShadow: 'unset',
                                        color: '#FF9A00',
                                        fontFamily: 'Proxima Nova',
                                        textTransform: 'uppercase',
                                        width: '176px',
                                        height: '33px',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        fontSize: '12px',
                                        lineHeight: '15px',
                                    }}>
                                    {t('invite.copyToClipboard')}
                                </Button>
                            </CopyToClipboard>
                        </Box>
                    </Box>
                    <Typography
                        sx={{
                            fontFamily: 'Brandon Grotesque',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            fontSize: '20px',
                            lineHeight: '24px',
                            textAlign: 'center',
                            color: 'rgba(89, 97, 123, 0.69)',
                        }}>
                        {t('invite.orVia')}
                    </Typography>
                    <Box
                        sx={{
                            mt: 2,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                        }}>
                        <FacebookMessengerShareButton
                            appId={`${process.env.REACT_APP_FACEBOOK_AUTH_CLIENT_ID}`}
                            title={`${t('invite.about')}`}
                            windowWidth={1000}
                            windowHeight={1000}
                            url={`${`https://app${
                                process.env.REACT_APP_STAGE === 'development' ||
                                process.env.REACT_APP_STAGE === 'local'
                                    ? '.dev'
                                    : process.env.REACT_APP_STAGE === 'stage'
                                    ? '.stage'
                                    : ''
                            }.focus101.com`}`}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <FacebookMessenger
                                    width={mobile ? 30 : 40}
                                    height={mobile ? 30 : 40}
                                />
                            </Box>
                        </FacebookMessengerShareButton>
                        <MuiLink
                            href="https://www.instagram.com/"
                            target={'_blank'}
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Instagram
                                width={mobile ? 30 : 40}
                                height={mobile ? 30 : 40}
                            />
                        </MuiLink>
                        <WhatsappShareButton
                            title={`${t('invite.about')}`}
                            windowWidth={1000}
                            windowHeight={1000}
                            url={`${`https://app${
                                process.env.REACT_APP_STAGE === 'development' ||
                                process.env.REACT_APP_STAGE === 'local'
                                    ? '.dev'
                                    : process.env.REACT_APP_STAGE === 'stage'
                                    ? '.stage'
                                    : ''
                            }.focus101.com`}`}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <Whatsapp
                                    width={mobile ? 30 : 40}
                                    height={mobile ? 30 : 40}
                                />
                            </Box>
                        </WhatsappShareButton>
                        <TwitterShareButton
                            title={`${t('invite.about')}`}
                            windowWidth={1000}
                            windowHeight={1000}
                            url={`${`https://app${
                                process.env.REACT_APP_STAGE === 'development' ||
                                process.env.REACT_APP_STAGE === 'local'
                                    ? '.dev'
                                    : process.env.REACT_APP_STAGE === 'stage'
                                    ? '.stage'
                                    : ''
                            }.focus101.com`}`}>
                            <Box
                                sx={{
                                    background: 'rgb(65, 161, 242)',
                                    borderRadius: '50px',
                                    width: {xs: '30px', sm: '40px'},
                                    height: {xs: '30px', sm: '40px'},
                                    textAlign: 'center',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}>
                                <Twitter
                                    width={mobile ? 15 : 22}
                                    height={mobile ? 15 : 22}
                                />
                            </Box>
                        </TwitterShareButton>
                    </Box>
                </Box>
            </ReusableModalWindow>
        );
    },
);
