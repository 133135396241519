export const diff = (date1: Date, date2: Date) => {
    // const date1 = new Date('7/13/2010');
    // const date2 = new Date('12/15/2010');
    // @ts-ignore
    const diffTime: number = Math.abs(date2 - date1);
    const diffDays: number = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    return {
        milliseconds: diffTime,
        days: diffDays,
    };
};
